import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import styles from './ProductListCounter.module.scss';

type ProductListCounterProps = {
    readonly count: number;
};

const ProductListCounter = ({ count }: ProductListCounterProps): JSX.Element | null => {
    if (!count) {
        return null;
    }

    return (
        <div className={styles.productListCounter}>
            <FormattedMessage
                defaultMessage="{countElement} {count, plural, one {Erlebnis} other {Erlebnisse}}"
                values={{
                    count,
                    countElement: (
                        <span
                            className={classNames(styles.count, 'theme-text-headline')}
                            data-testid="product-list-count"
                        >
                            {count}
                        </span>
                    ),
                }}
            />
        </div>
    );
};

export { ProductListCounter };
