export enum GooglePlacesApiStatus {
    Ok = 'OK',
    NotFound = 'NOT_FOUND',
    InvalidRequest = 'INVALID_REQUEST',
    MaxRouteLengthExceeded = 'MAX_ROUTE_LENGTH_EXCEEDED',
    MaxQaypointsExceeded = 'MAX_WAYPOINTS_EXCEEDED',
    OverDailyLimit = 'OVER_DAILY_LIMIT',
    OverQueryLimit = 'OVER_QUERY_LIMIT',
    RequestDenied = 'REQUEST_DENIED',
    UnknownError = 'UNKNOWN_ERROR',
    ZeroResults = 'ZERO_RESULTS',
    TechError = 'TECH_ERROR',
}

export enum GooglePlacesAddressType {
    AdministrativeAreaLevel1 = 'administrative_area_level_1',
    AdministrativeAreaLevel2 = 'administrative_area_level_2',
    AdministrativeAreaLevel3 = 'administrative_area_level_3',
    AdministrativeAreaLevel4 = 'administrative_area_level_4',
    AdministrativeAreaLevel5 = 'administrative_area_level_5',
    Airport = 'airport',
    ColloquialArea = 'colloquial_area',
    Country = 'country',
    Establishment = 'establishment',
    Intersection = 'intersection',
    Locality = 'locality',
    NaturalFeature = 'natural_feature',
    Neighborhood = 'neighborhood',
    Park = 'park',
    PointOfInterest = 'point_of_interest',
    Political = 'political',
    PostalCode = 'postal_code',
    Premise = 'premise',
    Route = 'route',
    StreetAddress = 'street_address',
    Sublocality = 'sublocality',
    SublocalityLevel1 = 'sublocality_level_1',
    SublocalityLevel2 = 'sublocality_level_2',
    SublocalityLevel3 = 'sublocality_level_3',
    SublocalityLevel4 = 'sublocality_level_4',
    SublocalityLevel5 = 'sublocality_level_5',
    Subpremise = 'subpremise',
    Ward = 'ward',
}
