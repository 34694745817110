import { type PropsWithChildren, type ReactNode } from 'react';

const NoResultsBoundary = ({
    children,
    fallback,
    hasNoResults,
}: PropsWithChildren<{
    readonly fallback: ReactNode;
    readonly hasNoResults: boolean;
}>): JSX.Element => <>{hasNoResults ? fallback : children}</>;

export { NoResultsBoundary };
