import { useEffect, useState } from 'react';

import { Tabs, Wysiwyg } from '@jsmdg/yoshi';
import { type Tab } from '../../../shared/types/pageContent';

type TabGroupProps = {
    readonly tabs: Tab[];
    readonly selectedTab?: string;
};

const SELECTED_TAB_QUERY_PARAM = 'selectedTab';

const TabGroup = ({ selectedTab, tabs }: TabGroupProps): JSX.Element => {
    const mappedTabs = tabs.map(tab => ({
        caption: tab.headline,
        content: <Wysiwyg content={tab.body} />,
    }));

    let initialIndex = 0;

    if (selectedTab) {
        initialIndex = mappedTabs.findIndex(
            tab => tab.caption.toLocaleLowerCase() === selectedTab.toLocaleLowerCase(),
        );
    }

    const [selectedIndex, setSelectedIndex] = useState(Math.max(0, initialIndex));

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const url = new URL(window.location.toString());
            url.searchParams.set(
                SELECTED_TAB_QUERY_PARAM,
                mappedTabs[selectedIndex].caption.toLocaleLowerCase(),
            );
            window.history.replaceState({}, '', url.toString());
        }
    }, [mappedTabs, selectedIndex]);

    return <Tabs tabs={mappedTabs} selectedIndex={selectedIndex} onClick={setSelectedIndex} />;
};

export { TabGroup };
