export enum ContentType {
    ImageAndText = 'image_and_text',
    Image = 'image',
    TabGroup = 'tab_group',
    Wysiwyg = 'wysiwyg',
    ImageSlider = 'image_slider',
    PageSlider = 'page_slider',
    LinkBoxWidget = 'link_box_widget',
    ProductListQuery = 'product_list_query',
    PageTileWidget = 'page_tile_widget',
    CollabsibleGroup = 'collapsible_group',
    DiscountCodeWidget = 'discount_code_widget',
    CardGroup = 'card_group',
    ButtonBar = 'button_bar',
    Bestseller = 'bestseller',
    LastSeen = 'last_seen',
    NewsletterUnsubscribeForm = 'newsletter_unsubscribe_form',
    NewsletterSubscribeForm = 'newsletter_subscribe_form',
    NewsletterOptIn = 'newsletter_opt_in',
    ExperienceFinder = 'experience_finder',
    ContactForm = 'contact_form',
}
