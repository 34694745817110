import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

import { trackEecPromotionImpression } from '@jsmdg/tracking';
import { type BaseImage } from '../../shared/types/pageContent';
import { CreativeName, PromotionType } from '../enums/trackingPromotions';
import { useIntersection } from './useIntersection';

export function useImageSliderImpressionTracking(
    galleryImages: BaseImage[],
    verticalPosition: number,
    trackingPageType: string,
): [
    (element: HTMLAnchorElement | HTMLDivElement | null) => void,
    Dispatch<SetStateAction<number>>,
] {
    const positionTemplate = `vertical${verticalPosition}_horizontal`;
    const [isInViewPort, setImageSliderElement] = useIntersection({
        threshold: 0.5,
    });
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [trackedImages, setTrackedImages] = useState<{
        [key: string]: boolean;
    }>({});

    const image = galleryImages[currentImageIndex];

    const name = image?.link?.title || 'no name';
    const id = image?.link?.trackingName || image?.src;
    const position = `${positionTemplate}${currentImageIndex + 1}`;

    useEffect(() => {
        const promotionTrackingEventData = {
            creativeName: CreativeName.ImageSlider,
            creativeSlot: position,
            promotionName: name,
            promotionId: id,
            locationId: image?.link?.url || '',
            positionHorizontal: `${currentImageIndex + 1}`,
            positionVertical: `${verticalPosition}`,
            promotionType: PromotionType.Static,
        };

        if (image && isInViewPort && !trackedImages[currentImageIndex]) {
            setTrackedImages({ ...trackedImages, [currentImageIndex]: true });
            trackEecPromotionImpression(
                [
                    {
                        id,
                        name,
                        creative: `ImageSlider_${trackingPageType}`,
                        position,
                    },
                ],
                promotionTrackingEventData,
            );
        }
    }, [
        currentImageIndex,
        galleryImages,
        id,
        image,
        isInViewPort,
        name,
        position,
        positionTemplate,
        trackedImages,
        trackingPageType,
        verticalPosition,
    ]);

    return [setImageSliderElement, setCurrentImageIndex];
}
