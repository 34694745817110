import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { type GA4FilterListType } from '@jsmdg/tracking';
import { type MultiSelectOption } from '@jsmdg/yoshi/dist/types/selectOption';
import { AttributeName, TRAVELNIGHTS_THRESHOLD } from '../../../../shared/enums/attributeName';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type FacetValue } from '../../../../shared/types/facetValue';
import { type Filter, type RangeFilter } from '../../../../shared/types/search';
import { getAdjustedItemCountByLimit } from '../../../helper/getAdjustedItemCountByLimit';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import { MultiSelectDesktop } from '../MultiSelect/MultiSelectDesktop/MultiSelectDesktop';
import { MultiSelectMobile } from '../MultiSelect/MultiSelectMobile/MultiSelectMobile';

const messages = defineMessages({
    label: { defaultMessage: 'Übernachtung' },
    noNight: { defaultMessage: 'Keine' },
    oneNight: { defaultMessage: '1 Übernachtung' },
    twoNights: { defaultMessage: '2 Übernachtungen' },
    threeNights: { defaultMessage: '3 Übernachtungen' },
    moreThanThreeNights: { defaultMessage: 'Mehr als 3 Übernachtungen' },
});

const valueTranslationMapping: Record<string, { defaultMessage: string }> = {
    '0': messages.noNight,
    '1': messages.oneNight,
    '2': messages.twoNights,
    '3': messages.threeNights,
};

type TravelNightsFilterProps = {
    readonly facet: FacetFilter;
    readonly selectedOptions?: RangeFilter[];
    readonly isMobileFilter?: boolean;
    readonly paginationLimit?: number;
    readonly onSubmit: (
        type: SearchReducerActionType,
        value: RangeFilter[] | null,
        name: string,
    ) => void;
    readonly filter: Filter;
    readonly listType?: GA4FilterListType;
};

const TravelNightsFilter = ({
    facet,
    filter,
    isMobileFilter,
    listType,
    onSubmit,
    paginationLimit,
    selectedOptions,
}: TravelNightsFilterProps): JSX.Element => {
    const intl = useIntl();
    const { attribute, facetStats, values } = facet;

    const getRangeValue = useCallback(
        (value: number): string => {
            if (value <= TRAVELNIGHTS_THRESHOLD || !facetStats) {
                return `${value}-${value}`;
            }

            return `${TRAVELNIGHTS_THRESHOLD + 1}-${facetStats.max}`;
        },
        [facetStats],
    );

    const transformSelectedOption = useCallback(
        (selectedOption: RangeFilter): string => getRangeValue(selectedOption.min || 0),
        [getRangeValue],
    );

    const transformedSelectedOptions = selectedOptions?.map(transformSelectedOption);

    const travelNightOptions = useMemo(() => {
        const isOptionSelected = (value: string): boolean => {
            return selectedOptions?.some(option => option === value) || false;
        };

        const getSelectedOptionLabel = (selectedOption: RangeFilter): string => {
            if (
                selectedOption.min === selectedOption.max &&
                selectedOption.min &&
                selectedOption.min < Object.keys(valueTranslationMapping).length
            ) {
                return intl.formatMessage(valueTranslationMapping[`${selectedOption.min}`]);
            }

            return intl.formatMessage(messages.moreThanThreeNights);
        };

        const multiSelectOptions: MultiSelectOption[] = values
            .sort((a, b) => Number(a.name) - Number(b.name))
            .reduce((preparedOptions: MultiSelectOption[], facetValue: FacetValue) => {
                const numericValue = Number.parseInt(facetValue.name, 10);
                const rangeValue = getRangeValue(numericValue);
                const isSelected = isOptionSelected(rangeValue);
                const numProducts = getAdjustedItemCountByLimit(facetValue.count, paginationLimit);

                if (numericValue <= TRAVELNIGHTS_THRESHOLD) {
                    return [
                        ...preparedOptions,
                        {
                            label: intl.formatMessage(valueTranslationMapping[facetValue.name]),
                            secondaryText: numProducts ? numProducts.toString() : undefined,
                            value: rangeValue,
                            isSelected,
                        },
                    ];
                }

                const travelNightsGreaterThanThreshold = {
                    label: intl.formatMessage(messages.moreThanThreeNights),
                    value: rangeValue,
                    isSelected,
                    secondaryText: facetValue.count ? facetValue.count.toString() : undefined,
                };

                const travelNightsGreaterThanThresholdAccumulatorIndex = preparedOptions.findIndex(
                    option => option.value === rangeValue,
                );

                if (travelNightsGreaterThanThresholdAccumulatorIndex > -1) {
                    const accumulatedValue =
                        Number(travelNightsGreaterThanThreshold.secondaryText || '0') +
                        Number(
                            preparedOptions[travelNightsGreaterThanThresholdAccumulatorIndex]
                                .secondaryText || '0',
                        );
                    return [
                        ...preparedOptions.slice(
                            0,
                            travelNightsGreaterThanThresholdAccumulatorIndex,
                        ),
                        {
                            ...travelNightsGreaterThanThreshold,
                            secondaryText: accumulatedValue
                                ? accumulatedValue.toString()
                                : undefined,
                        },
                        ...preparedOptions.slice(
                            travelNightsGreaterThanThresholdAccumulatorIndex + 1,
                        ),
                    ];
                }

                return [...preparedOptions, travelNightsGreaterThanThreshold];
            }, []);

        selectedOptions?.forEach(selectedOption => {
            const transformedSelectedOption = transformSelectedOption(selectedOption);
            const indexOfSelectedOption = values.findIndex(
                facetValue =>
                    getRangeValue(Number.parseInt(facetValue.name, 10)) ===
                    transformedSelectedOption,
            );

            if (indexOfSelectedOption === -1) {
                multiSelectOptions.push({
                    label: getSelectedOptionLabel(selectedOption),
                    value: transformedSelectedOption,
                    isSelected: true,
                });
            }
        });

        return multiSelectOptions;
    }, [getRangeValue, intl, paginationLimit, selectedOptions, transformSelectedOption, values]);

    const handleOnSubmit = (submittedOptions: string[]): void => {
        const submittedOptionsValues = submittedOptions.map(option => {
            const [min, max] = option.split('-').map(Number);

            return {
                min,
                max: max ?? min,
            };
        });

        onSubmit(
            SearchReducerActionType.Filter,
            submittedOptionsValues.length ? submittedOptionsValues : null,
            attribute,
        );
    };

    if (isMobileFilter) {
        return (
            <MultiSelectMobile
                options={travelNightOptions}
                onSubmit={handleOnSubmit}
                label={intl.formatMessage(messages.label)}
                selectedOptions={transformedSelectedOptions}
                filter={filter}
                attribute={AttributeName.TravelNights}
                listType={listType}
            />
        );
    }

    return (
        <MultiSelectDesktop
            label={intl.formatMessage(messages.label)}
            options={travelNightOptions}
            onSubmit={handleOnSubmit}
            selectedOptions={transformedSelectedOptions}
            filter={filter}
            attribute={AttributeName.TravelNights}
            listType={listType}
        />
    );
};

export { TravelNightsFilter };
