import classNames from 'classnames';

import { Button, ButtonColor, Image, PureLink, RenderType, Wysiwyg } from '@jsmdg/yoshi';
import { ImagePosition } from '../../../shared/enums/imagePosition';
import { type BaseImage, type BaseLink } from '../../../shared/types/pageContent';

type ImageAndTextProps = {
    readonly image: BaseImage;
    readonly content: string;
    readonly imagePosition: ImagePosition;
    readonly lazyLoad: boolean;
    readonly target: string;
    readonly linkLabel?: string;
    readonly link?: BaseLink;
};

const ImageAndText = ({
    content,
    image,
    imagePosition,
    lazyLoad,
    link,
    linkLabel,
    target,
}: ImageAndTextProps): JSX.Element => {
    const ImageComponent = (
        <Image
            className="w-100"
            src={image.src}
            alt={image.alt}
            lazyLoad={lazyLoad}
            srcSet={image.srcset}
            sizes={image.sizes}
        />
    );

    return (
        <div className="grid">
            <div
                className={classNames('g-col-12 g-col-sm-6', {
                    'order-sm-1': imagePosition === ImagePosition.Right,
                })}
            >
                {!!link && (
                    <PureLink href={link.url} target={target} className="d-flex" internal>
                        {ImageComponent}
                    </PureLink>
                )}
                {!link && ImageComponent}
            </div>
            <div className="g-col-12 g-col-sm-6 mt-3x mt-sm-0">
                <Wysiwyg content={content} />
                {link && (
                    <div className="grid">
                        <div
                            className={classNames('g-col-12', {
                                'ml-sm-auto': imagePosition === ImagePosition.Left,
                                'mr-sm-auto': imagePosition === ImagePosition.Right,
                            })}
                        >
                            <Button
                                target={target}
                                href={link.url}
                                color={ButtonColor.Complementary}
                                as={RenderType.Link}
                                className="w-100"
                            >
                                {linkLabel}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export { ImageAndText };
