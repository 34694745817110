import classNames from 'classnames';
import { type ImageSliderImage } from 'src/shared/types/pageContent';

import { BreakpointsPx, getImagesByDimension } from '@jsmdg/imgix';
import { Image, type SelectOption } from '@jsmdg/yoshi';
import { type CampaignCreative } from '../../../shared/types/campaignCreative';
import { ExperienceFinder } from './ExperienceFinder';
import { HeroStageBanner } from './HeroStageBanner/HeroStageBanner';
import styles from './HeroStage.module.scss';

const mobileImageDimensions = {
    [BreakpointsPx.xs]: { width: 435, height: 364 },
    [BreakpointsPx.xl]: { width: 800, height: 400 },
};

const desktopImageDimensions = {
    [BreakpointsPx.xs]: { width: 800, height: 400 },
    [BreakpointsPx.xl]: { width: 1_200, height: 490 },
};

type HeroStageProps = {
    readonly galleryImages: ImageSliderImage[];
    readonly categories: SelectOption[];
    readonly heroStageCreatives: CampaignCreative[];
    readonly hasNavigationCreative: boolean;
    readonly verticalPosition: number;
};

const HeroStage = ({
    categories,
    galleryImages,
    hasNavigationCreative,
    heroStageCreatives,
    verticalPosition,
}: HeroStageProps) => {
    const hasMultipleImages = galleryImages.length > 1;

    return (
        <>
            {!!heroStageCreatives.length &&
                heroStageCreatives.map((creative, index) => {
                    return (
                        <div
                            className={classNames(styles.heroStage, 'mb-0-5x')}
                            key={creative.placeholderId}
                            data-testid={`hero-stage-banner-${index}`}
                        >
                            <HeroStageBanner
                                creative={creative}
                                hasNavigationCreative={hasNavigationCreative}
                                verticalPosition={verticalPosition}
                            />
                        </div>
                    );
                })}
            {galleryImages.map(galleryImage => {
                const { headline, isMobile } = galleryImage;

                const image = getImagesByDimension(
                    galleryImage.src,
                    isMobile && hasMultipleImages ? mobileImageDimensions : desktopImageDimensions,
                );

                return (
                    <div
                        className={classNames(
                            styles.heroWrapper,
                            'position-relative justify-content-center mb-5x mb-sm-0',
                            {
                                'd-flex d-sm-none': isMobile && hasMultipleImages,
                                'd-none d-sm-flex': !isMobile && hasMultipleImages,
                            },
                        )}
                        data-testid={isMobile ? 'hero-stage-mobile' : 'hero-stage-desktop'}
                        key={headline}
                    >
                        <Image
                            alt={isMobile ? 'Hero stage mobile' : 'Hero stage desktop'}
                            className={classNames(styles.image, ' w-100')}
                            src={image.src}
                            srcSet={image.srcset}
                            sizes={image.sizes}
                            lazyLoad={false}
                            key={image.src}
                        />

                        <div className={styles.curvedOverlay} />
                        <div
                            className={classNames(styles.overlay, 'position-absolute px-4x w-100')}
                        >
                            <ExperienceFinder headline={headline} categories={categories} />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export { HeroStage };
