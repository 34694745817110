import { useEffect, useState } from 'react';

import { type Filter } from '../../../shared/types/search';

const useActiveFiltersCount = (
    filter: Filter,
    isInitialPrice: boolean,
    isInitialLocation: boolean,
    isInMapViewModal = false,
): number => {
    const [activeFiltersCount, setActiveFiltersCount] = useState(0);

    useEffect(() => {
        const filterCount =
            [!isInitialPrice, !isInitialLocation, filter.topCountryCodes].filter(Boolean).length +
            Object.values(filter.productAttributes || {}).filter(
                selected => Array.isArray(selected) && selected.length > 0,
            ).length;

        const count = isInMapViewModal ? filterCount - 1 : filterCount;

        setActiveFiltersCount(count);
    }, [filter, isInMapViewModal, isInitialLocation, isInitialPrice]);

    return activeFiltersCount;
};

export { useActiveFiltersCount };
