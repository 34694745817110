import { FormattedMessage } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Input } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../../../shared/types/fragmentContext';
import styles from './InputPriceControls.module.scss';

type InputPriceControlsProps = {
    readonly inputsValues: Array<number | string>;
    readonly onInputUpdate: (e: React.ChangeEvent) => void;
};

const InputPriceControls = ({
    inputsValues,
    onInputUpdate,
}: InputPriceControlsProps): JSX.Element => {
    const { tenantConfig } = useFragmentContext<FragmentContext>();

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className={styles.inputControlWrapper}>
                <Input
                    type="text"
                    inputMode="numeric"
                    name="minValue"
                    value={inputsValues[0]}
                    onChange={onInputUpdate}
                    onBlur={onInputUpdate}
                    rightComponent={
                        <FormattedMessage
                            defaultMessage="{symbol}"
                            values={{ symbol: tenantConfig.currency.symbol }}
                        />
                    }
                    dataTestId="min-price-input"
                />
            </div>
            <>–</>
            <div className={styles.inputControlWrapper}>
                <Input
                    type="text"
                    inputMode="numeric"
                    name="maxValue"
                    value={inputsValues[1]}
                    onChange={onInputUpdate}
                    onBlur={onInputUpdate}
                    className={styles.inputControl}
                    rightComponent={
                        <FormattedMessage
                            defaultMessage="{symbol}"
                            values={{ symbol: tenantConfig.currency.symbol }}
                        />
                    }
                    dataTestId="max-price-input"
                />
            </div>
        </div>
    );
};

export { InputPriceControls };
