/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-dynamic-delete, complexity */
import { isNumber } from 'lodash';

import { getSortingByTargetGroup } from '../../shared/helpers/getSortingByTargetGroup';
import {
    type LocationFilter,
    type RangeFilter,
    type Search,
    type Sorting as SortingType,
} from '../../shared/types/search';
import { type SearchReducerValue } from '../types/searchReducer';

const isRangeFilter = (value?: SearchReducerValue): value is RangeFilter =>
    !!value && typeof value === 'object' && ('min' in value || 'max' in value);

const isProductAttributesValue = (
    value?: SearchReducerValue,
): value is string[] | RangeFilter[] => {
    if (!value || !Array.isArray(value)) {
        return false;
    }

    // check for string[]
    if (!value.some(checkValue => typeof checkValue !== 'string')) {
        return true;
    }

    // check for RangeFilter[]
    return !value.some(checkValue => !isRangeFilter(checkValue));
};

const isLocationFilter = (value?: SearchReducerValue): value is LocationFilter =>
    !!value &&
    typeof value === 'object' &&
    'lat' in value &&
    'long' in value &&
    'distance' in value;

const isSortingType = (value?: SearchReducerValue): value is SortingType =>
    !!value && typeof value === 'object' && 'field' in value && 'order' in value;

const isSearchType = (value?: SearchReducerValue): value is Search =>
    !!value &&
    typeof value === 'object' &&
    ('searchTerm' in value ||
        'sorting' in value ||
        'pagination' in value ||
        'offset' in value ||
        'filter' in value);

export type SearchReducerState = Search & { type?: SearchReducerActionType };

export type SearchReducerAction = {
    type: SearchReducerActionType;
    value?: SearchReducerValue;
    name?: string;
    boundary?: { lat1: number; lng1: number; lat2: number; lng2: number };
};

export enum SearchReducerActionType {
    Price = 'price',
    PriceMin = 'priceMin',
    PriceMax = 'priceMax',
    Location = 'location',
    Distance = 'distance',
    GeoCoordinates = 'geoCoordinates',
    LocationFilterReset = 'locationFilterReset',
    SearchReset = 'searchReset',
    Reset = 'Reset',
    ClearError = 'clearError',
    Sorting = 'sorting',
    Filter = 'filter',
    Pagination = 'pagination',
    MapBoundary = 'mapBoundary',
}

export function searchReducer(
    state: SearchReducerState,
    action: SearchReducerAction,
): SearchReducerState {
    switch (action.type) {
        case SearchReducerActionType.Price: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...(isRangeFilter(action.value)
                        ? {
                              price: {
                                  min: action.value.min,
                                  max: action.value.max,
                              },
                          }
                        : {}),
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.PriceMin: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    price: {
                        ...state.filter?.price,
                        ...(typeof action.value === 'number' ? { min: action.value } : {}),
                    },
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.PriceMax: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    price: {
                        ...state.filter?.price,
                        ...(typeof action.value === 'number' ? { max: action.value } : {}),
                    },
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.MapBoundary: {
            if (!isLocationFilter(action.value)) {
                return state;
            }

            const { lat, long, name, distance } = action.value;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    location: {
                        lat,
                        long,
                        name,
                        distance,
                    },
                    boundary: action.boundary,
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.Location: {
            if (!isLocationFilter(action.value)) {
                return state;
            }

            const { lat, long, name, distance } = action.value;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    location: {
                        lat,
                        long,
                        name,
                        distance,
                    },
                    boundary: null,
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.Distance: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    location: {
                        ...state.filter!.location!,
                        ...(typeof action.value === 'number' ? { distance: action.value } : {}),
                    },
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.GeoCoordinates: {
            return {
                ...state,
                type: action.type,
            };
        }

        case SearchReducerActionType.Filter: {
            const productAttributes = state.filter?.productAttributes;
            const isUnselectedToggle =
                Array.isArray(action.value) &&
                action.value.length === 1 &&
                typeof action.value[0] === 'undefined';
            const isEmptyArray = Array.isArray(action.value) && action.value.length === 0;
            const removeFilter =
                productAttributes && (!action.value || isUnselectedToggle || isEmptyArray);

            if (removeFilter && typeof action.name === 'string') {
                delete productAttributes[action.name];
            }

            return {
                ...state,
                filter: {
                    ...state.filter,
                    productAttributes: {
                        ...productAttributes,
                        ...(!removeFilter &&
                        typeof action.name === 'string' &&
                        isProductAttributesValue(action.value)
                            ? { [action.name]: action.value }
                            : {}),
                    },
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.Sorting: {
            return {
                ...state,
                ...(isSortingType(action.value) ? { sorting: action.value } : {}),
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.LocationFilterReset: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    location: null,
                    boundary: null,
                },
                sorting: getSortingByTargetGroup(undefined, state.filter?.targetGroup?.name),
                type: action.type,
            };
        }

        case SearchReducerActionType.SearchReset: {
            return {
                ...state,
                ...(isSearchType(action.value) ? action.value : {}),
                type: action.type,
            };
        }

        // use this to recover from a previous error
        case SearchReducerActionType.ClearError: {
            return {
                ...state,
                type: action.type,
            };
        }

        case SearchReducerActionType.Pagination: {
            if (action.value && isNumber(action.value)) {
                return {
                    ...state,
                    pagination: {
                        ...state.pagination!,
                        currentPage: action.value,
                    },
                    type: action.type,
                };
            }

            return {
                ...state,
                type: action.type,
            };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}
