/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable unicorn/no-object-as-default-parameter */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    type BrowserLocalStorageCacheItem,
    type BrowserLocalStorageOptions,
} from '@algolia/cache-browser-local-storage';

import { isStorageAvailable } from '@jsmdg/browser-storage';
import { StorageType } from '@jsmdg/browser-storage/dist/services/browser-storage-item';

export type Cache = {
    get: <TValue>(
        key: Record<string, any> | string,
        defaultValue: () => Promise<TValue>,
        events?: CacheEvents<TValue>,
    ) => Promise<TValue>;

    set: <TValue>(key: Record<string, any> | string, value: TValue) => Promise<TValue>;

    delete: (key: Record<string, any> | string) => Promise<void>;

    /**
     * Clears the cache.
     */
    clear: () => Promise<void>;
};

export type CacheEvents<TValue> = {
    /**
     * The callback when the given `key` is missing from the cache.
     */
    miss: (value: TValue) => Promise<any>;
};

export function createBrowserLocalStorageCache(options: BrowserLocalStorageOptions): Cache {
    let storage: Storage | undefined;
    // We've changed the namespace to avoid conflicts with v4, as this version is a huge breaking change
    const namespaceKey = `algolia-client-js-${options.key}`;

    function getStorage(): Storage | undefined {
        if (storage === undefined) {
            storage = options.localStorage;
        }

        if (
            typeof window !== 'undefined' &&
            storage === undefined &&
            isStorageAvailable(StorageType.LOCAL_STORAGE)
        ) {
            storage = window.localStorage;
        }

        return storage;
    }

    function removeOldestEntry(namespace: Record<string, BrowserLocalStorageCacheItem>): void {
        const keys = Object.keys(namespace);
        if (keys.length === 0) return;

        let oldestTimestamp = Number.POSITIVE_INFINITY;
        let oldestKey: string | null = null;

        for (const key in namespace) {
            if (Object.hasOwn(namespace, key)) {
                const currentTimestamp = namespace[key].timestamp;

                if (currentTimestamp < oldestTimestamp) {
                    oldestTimestamp = currentTimestamp;
                    oldestKey = key;
                }
            }
        }

        if (oldestKey !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [oldestKey]: removed, ...rest } = namespace;

            try {
                setNamespace(rest);
            } catch {
                removeOldestEntry(rest);
            }
        }
    }

    function getNamespace<TValue>(): Record<string, TValue> {
        return JSON.parse(getStorage()?.getItem(namespaceKey) || '{}');
    }

    function setNamespace(namespace: Record<string, any>): void {
        getStorage()?.setItem(namespaceKey, JSON.stringify(namespace));
    }

    function removeOutdatedCacheItems(): void {
        const timeToLive = options.timeToLive ? options.timeToLive * 1_000 : null;
        const namespace = getNamespace<BrowserLocalStorageCacheItem>();

        const filteredNamespaceWithoutOldFormattedCacheItems = Object.fromEntries(
            Object.entries(namespace).filter(([, cacheItem]) => {
                return cacheItem.timestamp !== undefined;
            }),
        );

        setNamespace(filteredNamespaceWithoutOldFormattedCacheItems);

        if (!timeToLive) {
            return;
        }

        const filteredNamespaceWithoutExpiredItems = Object.fromEntries(
            Object.entries(filteredNamespaceWithoutOldFormattedCacheItems).filter(
                ([, cacheItem]) => {
                    const currentTimestamp = Date.now();
                    const isExpired = cacheItem.timestamp + timeToLive < currentTimestamp;

                    return !isExpired;
                },
            ),
        );

        setNamespace(filteredNamespaceWithoutExpiredItems);
    }

    return {
        async get<TValue>(
            key: Record<string, any> | string,
            defaultValue: () => Promise<TValue>,
            events: CacheEvents<TValue> = {
                miss: async () => {},
            },
        ): Promise<TValue> {
            try {
                removeOutdatedCacheItems();

                const foundValue =
                    await getNamespace<Promise<BrowserLocalStorageCacheItem>>()[
                        JSON.stringify(key)
                    ];

                let value;
                if (!foundValue) {
                    value = await defaultValue();
                    events.miss(value);
                }

                return foundValue ? foundValue.value : value;
            } catch {
                return null as TValue;
            }
        },

        async set<TValue>(key: Record<string, any> | string, value: TValue): Promise<TValue> {
            const namespace = getNamespace<BrowserLocalStorageCacheItem>();
            namespace[JSON.stringify(key)] = {
                timestamp: Date.now(),
                value,
            };
            try {
                setNamespace(namespace);
            } catch {
                removeOldestEntry(namespace);
            }

            return value;
        },

        async delete(key: Record<string, any> | string): Promise<void> {
            try {
                const namespace = getNamespace();

                delete namespace[JSON.stringify(key)];

                setNamespace(namespace);
            } catch {}
        },

        async clear(): Promise<void> {
            try {
                getStorage()?.removeItem(namespaceKey);
            } catch {}
        },
    };
}
