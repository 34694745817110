import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';

import { type GA4FilterListType } from '@jsmdg/tracking';
import { AttributeName } from '../../../../shared/enums/attributeName';
import { DurationOption } from '../../../../shared/enums/durationOption';
import { type Filter, type RangeFilter } from '../../../../shared/types/search';
import { getAdjustedItemCountByLimit } from '../../../helper/getAdjustedItemCountByLimit';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import { durationOptionMap, messagesDuration } from '../filterMessages';
import { MultiSelectDesktop } from '../MultiSelect/MultiSelectDesktop/MultiSelectDesktop';
import { MultiSelectMobile } from '../MultiSelect/MultiSelectMobile/MultiSelectMobile';

type DurationOptionType = { name: string; count: number };

type DurationFilterProps = {
    readonly attribute: string;
    readonly values: DurationOptionType[];
    readonly selectedOptions?: RangeFilter[];
    readonly isMobileFilter?: boolean;
    readonly paginationLimit?: number;
    readonly onSubmit: (
        type: SearchReducerActionType,
        value: RangeFilter[] | null,
        name: string,
    ) => void;
    readonly filter: Filter;
    readonly listType?: GA4FilterListType;
};

const splitDurationValue = (duration: string): number[] => duration.split('_').map(Number);

const DurationFilter = ({
    attribute,
    filter,
    isMobileFilter,
    listType,
    onSubmit,
    paginationLimit,
    selectedOptions,
    values,
}: DurationFilterProps): JSX.Element => {
    const intl = useIntl();

    const isOptionSelected = (min: number, max: number): boolean =>
        selectedOptions?.some(option =>
            isEqual(option, {
                min,
                max,
            }),
        ) || false;

    const getProductCountForRange = (minSeconds: number, maxSeconds: number): number => {
        const count = values.reduce((totalCount, value) => {
            const currentSeconds = Number(value.name);

            if (currentSeconds >= minSeconds && (!maxSeconds || currentSeconds <= maxSeconds)) {
                return totalCount + value.count;
            }

            return totalCount;
        }, 0);

        return getAdjustedItemCountByLimit(count, paginationLimit);
    };

    const handleOnSubmit = (submittedOptions: string[]): void => {
        const submittedOptionsValues = submittedOptions.map(option => {
            const [min, max] = splitDurationValue(option);

            return {
                min,
                max,
            };
        });
        onSubmit(
            SearchReducerActionType.Filter,
            submittedOptionsValues.length ? submittedOptionsValues : null,
            attribute,
        );
    };

    const options = Object.values(DurationOption)
        .map(value => {
            const [min, max] = splitDurationValue(value);
            const productCount = getProductCountForRange(min, max);

            return {
                value,
                label: intl.formatMessage(durationOptionMap[value]),
                isSelected: isOptionSelected(min, max),
                secondaryText: productCount ? productCount.toString() : undefined,
            };
        })
        .filter(option => option.secondaryText);

    const transformedSelectedOptions = selectedOptions?.map(selectedOption =>
        [selectedOption.min, selectedOption.max].filter(option => option !== undefined).join('_'),
    );

    if (isMobileFilter) {
        return (
            <MultiSelectMobile
                options={options}
                onSubmit={handleOnSubmit}
                label={
                    attribute === AttributeName.ActivityDuration
                        ? intl.formatMessage(messagesDuration.mainLabelActivityDuration)
                        : intl.formatMessage(messagesDuration.mainLabelTotalDuration)
                }
                selectedOptions={transformedSelectedOptions}
                filter={filter}
                attribute={
                    attribute === AttributeName.ActivityDuration
                        ? AttributeName.ActivityDuration
                        : AttributeName.TotalDuration
                }
                listType={listType}
            />
        );
    }

    return (
        <MultiSelectDesktop
            label={
                attribute === AttributeName.ActivityDuration
                    ? intl.formatMessage(messagesDuration.mainLabelActivityDuration)
                    : intl.formatMessage(messagesDuration.mainLabelTotalDuration)
            }
            options={options}
            onSubmit={handleOnSubmit}
            selectedOptions={transformedSelectedOptions}
            filter={filter}
            attribute={
                attribute === AttributeName.ActivityDuration
                    ? AttributeName.ActivityDuration
                    : AttributeName.TotalDuration
            }
            listType={listType}
        />
    );
};

export { DurationFilter };
