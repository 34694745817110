import classNames from 'classnames';

import { Image } from '@jsmdg/yoshi';
import { type BaseImage } from '../../../../shared/types/pageContent';
import styles from './CardImage.module.scss';

type CardImageProps = {
    readonly image: BaseImage;
    readonly className: string;
    readonly lazyLoad: boolean;
    readonly alignTop?: boolean;
    readonly imageClassName?: string;
};

const CardImage = ({
    alignTop = false,
    className,
    image,
    imageClassName,
    lazyLoad,
}: CardImageProps): JSX.Element => {
    return (
        <div className={classNames(className, styles.imageWrapper)}>
            <Image
                className={classNames(styles.image, imageClassName, {
                    [styles.alignTop]: alignTop,
                })}
                src={image.src}
                alt={image.alt}
                lazyLoad={lazyLoad}
                srcSet={image.srcset}
                sizes={image.sizes}
            />
        </div>
    );
};

export { CardImage };
