import { Breakpoint, useBreakpoint } from '@jsmdg/yoshi';

const useHighestMatchingBreakpoint = (): Breakpoint => {
    const isXLarge = useBreakpoint(Breakpoint.XL);
    const isLarge = useBreakpoint(Breakpoint.LG);
    const isMedium = useBreakpoint(Breakpoint.MD);
    const isSmall = useBreakpoint(Breakpoint.SM);
    const isXSmall = useBreakpoint(Breakpoint.XS);
    const isXXSmall = useBreakpoint(Breakpoint['2XS']);

    if (isXLarge) return Breakpoint.XL;
    if (isLarge) return Breakpoint.LG;
    if (isMedium) return Breakpoint.MD;
    if (isSmall) return Breakpoint.SM;
    if (isXSmall) return Breakpoint.XS;
    if (isXXSmall) return Breakpoint['2XS'];

    return Breakpoint['3XS'];
};

export { useHighestMatchingBreakpoint };
