import { JsonLd } from 'react-schemaorg';
import { type SearchAction, type WebSite, type WithContext } from 'schema-dts';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FragmentContext } from '../../../shared/types/fragmentContext';

interface PotentialAction extends SearchAction {
    'query-input': string;
}
interface WebsiteMarkupWithQueryInput extends WebSite {
    potentialAction: PotentialAction;
}

export const WebsiteMarkup = (): JSX.Element | null => {
    const { tenantConfig } = useFragmentContext<FragmentContext>();

    if (typeof window === 'undefined') {
        return null;
    }

    const item: WithContext<WebsiteMarkupWithQueryInput> = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: tenantConfig.websiteMarkup.name,
        alternateName: tenantConfig.websiteMarkup.alternativeName,
        url: window.location.origin,
        potentialAction: {
            '@type': 'SearchAction',
            target: `${window.location.origin}/search?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
        },
    };

    return <JsonLd item={item} />;
};
