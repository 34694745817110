import axios from 'axios';

import { type Search } from '../../shared/types/search';
import { type SearchResult } from '../../shared/types/searchResult';

export const fetchProducts = async (search: Search): Promise<SearchResult> => {
    const result = await axios.post<SearchResult>('/api/products', {
        search,
    });

    return result.data;
};
