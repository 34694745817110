import classNames from 'classnames';

import { ResponsiveFrame, Skeleton } from '@jsmdg/yoshi';
import { SkeletonAnimation, SkeletonVariant } from '@jsmdg/yoshi/dist/enums/skeleton';
import styles from './SkeletonTile.module.scss';

const SkeletonTile = (): JSX.Element => {
    return (
        <div className="w-100 h-100 d-flex flex-column mx-1x">
            <ResponsiveFrame width={255} height={165}>
                <div className="w-100 h-100">
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="100%"
                        height="100%"
                    />
                </div>
            </ResponsiveFrame>
            <Skeleton
                animation={SkeletonAnimation.Wave}
                variant={SkeletonVariant.Text}
                className="w-100 mt-2x"
            />
            <Skeleton
                animation={SkeletonAnimation.Wave}
                variant={SkeletonVariant.Text}
                className="w-50 mt-1x"
            />
            <div className="d-flex justify-content-between mt-1x mb-2x">
                <Skeleton
                    animation={SkeletonAnimation.Wave}
                    variant={SkeletonVariant.Text}
                    className="w-50"
                />
                <Skeleton
                    animation={SkeletonAnimation.Wave}
                    variant={SkeletonVariant.Text}
                    className={styles.w30}
                />
            </div>
            <div className={classNames(styles.productSliderSkeletonBorder, 'pb-0')} />
        </div>
    );
};

export { SkeletonTile };
