export enum CategoryId {
    Fly = 'fly',
    Trip = 'trip',
    Car = 'car',
    Dinner = 'dinner',
    IndoorActivities = 'indoorActivities',
    OutdoorActivities = 'outdoorActivities',
    RegionActivities = 'regionActivities',
    Animals = 'animals',
    Art = 'art',
    Health = 'health',
    WaterSport = 'waterSport',
    Adventure = 'adventure',
}
