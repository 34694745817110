export const getGeoPosition = async (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(resolve, reject, {
                timeout: 10_000, // 10 seconds
                maximumAge: 3_600_000, // one hour
            });
            return;
        }

        reject(new Error('geolocation not available'));
    });
};
