import { ObjectType } from '../enums/objectType';
import { SortingField } from '../enums/sorting';
import { type IndexNameProps } from '../types/indexNameProps';

const SortSeparator = 'sorted_by';

// @TODO bring these changes back when we introduce again
// algolia english indices
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getLocaleFromString = (locale: string): string => {
    return 'de';
    // return locale.split('-')[0].toLowerCase();
};

export const getAlgoliaIndex = ({
    environment,
    locale,
    tenant,
    objectType,
    sorting,
}: IndexNameProps): string => {
    const primaryIndex = `${environment}_${tenant.toLocaleLowerCase()}_${getLocaleFromString(
        locale,
    )}_${objectType || ObjectType.Product}`;

    // distance sorting does not need an own index
    if (sorting?.field === SortingField.Distance) {
        return primaryIndex;
    }

    const sortReplicaSuffix = [sorting?.field, sorting?.order].filter(Boolean).join('_');
    return [primaryIndex, sortReplicaSuffix].filter(Boolean).join(`_${SortSeparator}_`);
};
