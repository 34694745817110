import { SkeletonTile } from '../SkeletonTile';

const slidesPerView = 4;

const ProductSliderFallback = () => {
    return (
        <div className="d-flex">
            {[...Array.from({ length: slidesPerView }).keys()].map(id => (
                <SkeletonTile key={`skeleton-${id}`} />
            ))}
        </div>
    );
};

export { ProductSliderFallback };
