import { Slider } from '@jsmdg/yoshi';
import { type PageType as Type } from '../../../shared/enums/pageType';
import { type ImageSliderImage } from '../../../shared/types/pageContent';
import { useImageSlider } from './useImageSlider';

type ImageSliderProps = {
    readonly galleryImages: ImageSliderImage[];
    readonly autoSlide: boolean;
    readonly verticalPosition: number;
    readonly hasNavigationCreative: boolean;
    readonly pageType: Type;
    readonly loop?: boolean;
};

const ImageSlider = ({
    autoSlide,
    galleryImages,
    hasNavigationCreative,
    loop = true,
    pageType,
    verticalPosition,
}: ImageSliderProps): JSX.Element => {
    const {
        autoPlayOptions,
        classes,
        handleSlideClickNext,
        handleSlideClickPrevious,
        handleSlideNext,
        handleSlidePrevious,
        heroStageType,
        setImageSliderElement,
        sliderConfig,
        slides,
    } = useImageSlider({
        galleryImages,
        verticalPosition,
        hasNavigationCreative,
        pageType,
        autoSlide,
    });

    return (
        <div ref={setImageSliderElement} className={classes.sliderWrapper}>
            <Slider
                slides={slides}
                classes={classes.slider}
                config={sliderConfig}
                autoPlay={autoPlayOptions}
                handleShowNext={handleSlideNext}
                handleShowPrevious={handleSlidePrevious}
                handleSlideClickNext={handleSlideClickNext}
                handleSlideClickPrevious={handleSlideClickPrevious}
                loop={loop}
                heroStageType={heroStageType}
            />
        </div>
    );
};

export { ImageSlider };
