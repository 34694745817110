import { type Dispatch, useEffect, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import isMatch from 'lodash/isMatch';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4FilterListType } from '@jsmdg/tracking';
import { Breakpoint, GridSwitcher, type GridView, useBreakpoint } from '@jsmdg/yoshi';
import { SearchStatus } from '../../../shared/enums/searchStatus';
import { getFacetFilters } from '../../../shared/helpers/algoliaHelpers';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Filter as FilterType, type Search } from '../../../shared/types/search';
import { type AlgoliaProduct } from '../../../shared/types/searchResponse';
import { getAlgoliaScopedResults } from '../../helper/getAlgoliaScopedResults';
import { useAlgoliaContext } from '../../hooks/useAlgoliaContext';
import { useAlgoliaProducts } from '../../hooks/useAlgoliaProducts';
import {
    type SearchReducerAction,
    SearchReducerActionType,
    type SearchReducerState,
} from '../../reducers/searchReducer';
import { type InitialPageFilterType } from '../../types';
import { type SearchReducerValue } from '../../types/searchReducer';
import { Filter } from '../Filter';
import { LocationFilter } from '../Filter/Location';
import { Sorting } from '../Filter/Sorting';
import { MapContainer } from '../MapContainer/MapContainer';
import { ProductListCounter } from '../ProductListCounter';
import styles from './ProductList.module.scss';

type ProductListFiltersProps = {
    readonly initialSearch?: Search;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly initialFilter?: FilterType;
    readonly hasUiFilters?: boolean;
    readonly showLocationFilter?: boolean;
    readonly isFailedAlgoliaRequest?: boolean;
    readonly searchState: SearchReducerState;
    readonly dispatchSearch: Dispatch<SearchReducerAction>;
    readonly geoLocationError?: GeolocationPositionError;
    readonly onGridSwitch: (view: GridView) => void;
    readonly gridView: GridView;
    readonly productIndexId: string;
    readonly productIndexName: string;
};

const ProductListFilters = ({
    dispatchSearch,
    geoLocationError,
    gridView,
    hasUiFilters,
    initialFilter,
    initialPageFilter,
    initialSearch,
    isFailedAlgoliaRequest = false,
    onGridSwitch,
    productIndexId,
    productIndexName,
    searchState,
    showLocationFilter,
}: ProductListFiltersProps): JSX.Element => {
    const { algoliaConfig } = useFragmentContext<FragmentContext>();
    const { status } = useAlgoliaContext();
    const { results, scopedResults: algoliaScopedResults } = useInstantSearch();

    const productResults = getAlgoliaScopedResults<AlgoliaProduct>({
        algoliaScopedResults,
        indexId: productIndexId,
        indexName: productIndexName,
        serverState: algoliaConfig.serverState,
    });

    const [searchResults, setSearchResults] = useState(productResults);

    const { limit, nbItems, products } = useAlgoliaProducts(
        searchResults,
        initialSearch?.pagination,
        showLocationFilter,
    );

    const { filter = {}, pagination, sorting } = searchState;
    const locationFilterIsActive = showLocationFilter && !!filter.location?.name;
    const listType = GA4FilterListType[gridView];
    const isDesktop = useBreakpoint(Breakpoint.SM);
    const showMobileLocationFilter = hasUiFilters && showLocationFilter && !isDesktop;
    const filterChanged = !isEqual(filter, initialFilter);
    const attributes = getFacetFilters(results?.hits, searchResults, pagination?.limit);

    const onReset = (): void => {
        dispatchSearch({
            type: SearchReducerActionType.SearchReset,
            value: {
                ...initialSearch,
                filter: initialFilter,
            },
        });
    };

    const onFilterChange = (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ): void => {
        dispatchSearch({ type, value, name });
    };

    useEffect(() => {
        if (!productResults?._state) return;

        if (
            status === SearchStatus.Resolved &&
            !isMatch({ products: searchResults }, { products: productResults })
        ) {
            setSearchResults(productResults);
        }
    }, [productResults, searchResults, status]);

    return (
        <>
            {showMobileLocationFilter && !isFailedAlgoliaRequest && (
                <div className="d-block d-sm-none">
                    <LocationFilter
                        locationName={filter.location?.name || ''}
                        onSubmit={(type: SearchReducerActionType, value?: SearchReducerValue) =>
                            onFilterChange(type, value)
                        }
                        geoLocationError={geoLocationError}
                        isOuter
                        listType={GA4FilterListType[gridView]}
                    />
                </div>
            )}
            {showLocationFilter && !isFailedAlgoliaRequest && (
                <div className="d-block d-sm-none">
                    <MapContainer />
                </div>
            )}

            {hasUiFilters && (
                <div
                    className={classNames(
                        styles.listControls,
                        'd-flex align-items-center justify-content-between position-relative d-sm-block',
                        { 'flex-row-reverse': !isFailedAlgoliaRequest },
                    )}
                >
                    {!isFailedAlgoliaRequest && (
                        <Filter
                            filter={filter}
                            sorting={sorting}
                            initialPageFilter={initialPageFilter}
                            itemCount={nbItems}
                            paginationLimit={limit}
                            onFilterChange={onFilterChange}
                            onSortingChange={onFilterChange}
                            onReset={onReset}
                            hasFilterChanged={filterChanged}
                            showLocationFilter={!!showLocationFilter}
                            locationFilterIsActive={!!locationFilterIsActive}
                            geoLocationError={geoLocationError}
                            facets={attributes}
                            gridView={gridView}
                        />
                    )}
                    {!!products.length && !!nbItems && (
                        <div className="d-flex justify-content-between align-items-center mb-sm-2-5x">
                            <ProductListCounter count={nbItems} />
                            <>
                                {!isFailedAlgoliaRequest && (
                                    <div className="d-none d-sm-flex ml-auto mr-0">
                                        <Sorting
                                            selectedSorting={sorting}
                                            onSubmit={onFilterChange}
                                            includeDistanceSorting={!!locationFilterIsActive}
                                            targetGroup={filter.targetGroup?.name}
                                            isDesktop
                                            listType={listType}
                                        />
                                    </div>
                                )}

                                <GridSwitcher
                                    defaultView={gridView}
                                    onSwitch={onGridSwitch}
                                    className="d-none d-md-flex ml-2x"
                                />
                            </>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export { ProductListFilters };
