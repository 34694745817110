export enum FacetType {
    Numeric = 'numeric',
    MultiSelect = 'multi-select',
    String = 'string',
    Select = 'select',
    Checkbox = 'checkbox',
    Duration = 'duration',
    Quantity = 'quantity',
    Boolean = 'boolean',
}
