import { CheckCircleIcon } from '@jsmdg/yoshi';
import styles from '../SuccessMessage/SuccessMessage.module.scss';

type SuccessMessageProps = {
    readonly title: string;
    readonly message?: string;
};

const SuccessInfo = ({ message, title }: SuccessMessageProps): JSX.Element => (
    <div className="d-flex justify-content-center">
        <div className="d-flex flex-column align-items-center">
            <CheckCircleIcon className={styles.icon} size={90} />
            <div className="theme-text-headline">{title}</div>
            {Boolean(message) && <div className="theme-text-body-text text-center">{message}</div>}
        </div>
    </div>
);

export { SuccessInfo };
