import { type MouseEventHandler } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { type GA4EventName, type GA4FeatureCategory, pushToDataLayer } from '@jsmdg/tracking';
import { ReadMore, Wysiwyg } from '@jsmdg/yoshi';

const messages = defineMessages({
    seeMore: {
        defaultMessage: 'Mehr Lesen',
    },
    seeLess: {
        defaultMessage: 'Weniger Lesen',
    },
});

type ProductDescriptionProps = {
    readonly value: string;
};

const trackClick = (): void => {
    pushToDataLayer({
        event: 'JS_Event',
        eventCategory: 'ProductList',
        eventAction: 'TextClick',
        eventLabel: 'More information',
        eventData: {
            eventName: 'click_textlink' as GA4EventName,
            feature_category: 'Productlist' as GA4FeatureCategory,
            click_element: 'More information',
            click_text: 'Mehr lesen',
        },
    });
};

const handleExpandProductDescriptionClick: MouseEventHandler<HTMLDivElement> &
    ((isExpanded: boolean) => void) = isExpanded => {
    if (isExpanded) {
        trackClick();
    }
};

const ProductDescription = ({ value }: ProductDescriptionProps): JSX.Element => {
    const intl = useIntl();

    return (
        <>
            <ReadMore
                expandText={intl.formatMessage(messages.seeMore)}
                collapseText={intl.formatMessage(messages.seeLess)}
                rows={2}
                className="d-block d-sm-none"
                onClick={handleExpandProductDescriptionClick}
            >
                <Wysiwyg content={value} paddingBottom={false} />
            </ReadMore>
            <div className="d-none d-sm-block">
                <Wysiwyg content={value} />
            </div>
        </>
    );
};

export { ProductDescription };
