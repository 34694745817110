const regularConfig = {
    size: 48,
    sizeMobile: 48,
    useOffset: false,
    showBanner: true,
    showFeedback: true,
    showDiscount: true,
    showSelectionText: true,
    showTitle: false,
    isFullWidth: false,
    renderMarkup: false,
};

export enum PageType {
    Home = 'HOME',
    ProductListingPage = 'PRODUCT_LISTING_PAGE',
    SearchPage = 'SEARCH_PAGE',
    Redemption = 'REDEMPTION',
    Other = 'OTHER',
}

export const pageConfig: { [key in PageType]: typeof regularConfig } = {
    [PageType.Home]: {
        ...regularConfig,
        renderMarkup: true,
    },
    [PageType.ProductListingPage]: {
        ...regularConfig,
        showTitle: true,
    },
    [PageType.SearchPage]: regularConfig,
    [PageType.Redemption]: {
        size: 20,
        sizeMobile: 10,
        useOffset: true,
        showBanner: false,
        showFeedback: false,
        showDiscount: false,
        showSelectionText: false,
        showTitle: true,
        isFullWidth: true,
        renderMarkup: false,
    },
    [PageType.Other]: regularConfig,
};
