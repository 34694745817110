import { type ReactElement } from 'react';
import classNames from 'classnames';

import { PureButton } from '@jsmdg/yoshi';
import poweredByGoogleImg from './powered_by_google_on_white.png';
import styles from './SuggestionList.module.scss';

type Suggestion = {
    title: string;
    placeId: string;
    subTitle: string;
};

type SuggestionListProps = {
    suggestions: Suggestion[] | null;
    selected: number;
    setSelected: (index: number) => void;
    suggestionClick: (placeId: string, suggestedLocationName: string) => void;
    className?: string;
} & React.ComponentPropsWithoutRef<'div'>;

const SuggestionList: React.FC<SuggestionListProps> = ({
    className,
    selected,
    setSelected,
    suggestionClick,
    suggestions,
}): ReactElement => {
    const handleOnMouseEnter = (index: number): void => {
        setSelected(index);
    };

    const handleOnMouseLeave = (): void => {
        setSelected(selected);
    };

    const list = suggestions?.map((suggestion, index) => {
        return (
            <PureButton
                key={suggestion.placeId}
                onClick={() =>
                    suggestionClick(
                        suggestion.placeId,
                        `${suggestion.title}` +
                            `${suggestion.subTitle ? `, ${suggestion.subTitle}` : ``}`,
                    )
                }
                onMouseEnter={() => handleOnMouseEnter(index)}
                onMouseLeave={() => handleOnMouseLeave()}
                className={classNames(styles.item, {
                    [styles.suggestionEntrySelected]: selected === index,
                    [styles.suggestionEntry]: selected !== index,
                })}
                tabIndex={index}
            >
                <span className={classNames(styles.title, 'mr-0-5x')}>{suggestion.title}</span>
                {suggestion.subTitle && (
                    <span className={styles.subtitle}>{suggestion.subTitle}</span>
                )}
            </PureButton>
        );
    });

    return (
        <div className={classNames(styles.container, 'position-absolute mt-0-5x', className)}>
            <div className="d-flex flex-column" data-testid="location-suggestion-list">
                {list}
            </div>
            <div className={styles.google}>
                <img
                    src={poweredByGoogleImg}
                    alt="powered by google"
                    className={classNames(styles.googleImage, 'd-block position-absolute')}
                />
            </div>
        </div>
    );
};

export { SuggestionList };
