import { type TenantEnum } from '@jsmdg/tenancy';
import { type CampaignCreative } from '../../shared/types/campaignCreative';
import { type Product } from '../../shared/types/searchResponse';
import { ProductListItemType } from '../enums/productListItemType';
import { type ProductListItem } from '../types/productListItem';
import { useCreatives } from './useCreatives';
import { useCuratedPlpSlider } from './useCuratedPlpSlider';

type UseProductListItems = {
    productListItems: ProductListItem[];
    productTileCreatives: CampaignCreative[];
    productListCreatives: CampaignCreative[];
    selectionBlockCreatives: CampaignCreative[];
    navigationCreatives: CampaignCreative[];
    nbBanners: number;
};

const bannerPositions = {
    withoutSelectionBlock: [1, 21, 47],
    withSelectionBlock: [1, 22, 48],
    selectionBlock: 15,
};

export const useProductListItems = (
    items: Product[],
    isListView: boolean,
    pageId?: string,
    tenant?: TenantEnum,
    isMapView?: boolean,
): UseProductListItems => {
    const {
        productTileCreatives,
        productListCreatives,
        selectionBlockCreatives,
        navigationCreatives,
    } = useCreatives();

    const curatedPlpSliderData = useCuratedPlpSlider(tenant, pageId);

    let nbBanners;

    const blockCreativesLength = selectionBlockCreatives.length ? 1 : 0;
    const tileCreativesLength = productTileCreatives.length
        ? bannerPositions.withSelectionBlock.length
        : 0;
    const listCreativesLength = productListCreatives.length
        ? bannerPositions.withSelectionBlock.length
        : 0;

    if (isListView) {
        nbBanners = listCreativesLength + blockCreativesLength + tileCreativesLength;
    } else {
        nbBanners = tileCreativesLength + blockCreativesLength;
    }

    const productBannerPositions = selectionBlockCreatives.length
        ? bannerPositions.withSelectionBlock
        : bannerPositions.withoutSelectionBlock;
    const productSelectionBlockPosition = bannerPositions.selectionBlock;

    let productListItems = items.reduce((allItems: ProductListItem[], curItem: Product) => {
        const newBanners = [];
        if (!isMapView) {
            if (productTileCreatives?.length && productBannerPositions.includes(allItems.length)) {
                newBanners.push({
                    key: `product-tile-banner-${allItems.length}`,
                    type: ProductListItemType.ProductTileBanner,
                });
            }

            if (
                selectionBlockCreatives.length &&
                productSelectionBlockPosition === allItems.length
            ) {
                newBanners.push({
                    key: `selection-block-${allItems.length + newBanners.length}`,
                    type: ProductListItemType.BannerSelectionBlock,
                });
            }

            if (
                isListView &&
                productListCreatives?.length &&
                productBannerPositions.includes(allItems.length)
            ) {
                newBanners.push({
                    key: `product-list-banner-${allItems.length}`,
                    type: ProductListItemType.ProductListBanner,
                });
            }
        }

        return [
            ...allItems,
            ...newBanners,
            {
                key: curItem.productId,
                type: ProductListItemType.Product,
                product: curItem,
            },
        ];
    }, []);

    if (curatedPlpSliderData && productListItems[10] && isListView)
        productListItems.splice(9, 0, curatedPlpSliderData);

    if (isMapView) {
        productListItems = productListItems.filter(productItem => productItem.product?.locations);
    }

    return {
        productListItems,
        productTileCreatives,
        productListCreatives,
        selectionBlockCreatives,
        navigationCreatives,
        nbBanners,
    };
};
