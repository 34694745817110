import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTimeoutSubscription } from '@jsmdg/yoshi';

/**
 * Calculates the 'height' property for the given headlines inside the CardGroup.
 * Ensures that headlines from cards rendered in the same flex row have the same height.
 *
 * @param headlineRefs HTMLElement[] References to the headlines to be considered.
 * @returns {any[]} style objects containing the calculated height property.
 */
export function useHeadlineStyles(headlineRefs) {
    const initialStyles = useMemo(
        () => Array.from({ length: headlineRefs.length }).fill(null),
        [headlineRefs],
    );
    const [headlineStyles, setHeadlineStyles] = useState(initialStyles);

    const updateHeadlineStyles = useCallback(() => {
        // Reset height values first to ensure proper calculation
        setHeadlineStyles(initialStyles);

        // reduce refs to BoundingClientRect, since we only need top and height
        const headlineRects = headlineRefs.map(
            el => el?.getBoundingClientRect() ?? { height: 0, top: 0 },
        );

        // Creates a map containing the headlines' top position (same for cards in the same flex row) as keys,
        // and the maximum headline height for this row
        const maxHeightPerRow = headlineRects.reduce(
            (result, { height, top }) => ({
                ...result,
                [top]: Math.max(height || 0, result[top] || 0),
            }),
            {},
        );

        // Sets the height of each headline to the maximum height in its flex row
        setHeadlineStyles(
            headlineRects.map(({ top }) => ({
                height: `${maxHeightPerRow[top]}px`,
            })),
        );
    }, [headlineRefs, initialStyles]);

    useEffect(updateHeadlineStyles, [updateHeadlineStyles]);

    // debounce calculating headline styles on resize to prevent from too many updates
    const { cancelTimeout, timeoutIdRef } = useTimeoutSubscription();
    const debounce = useCallback(
        (callback, wait) => {
            return () => {
                const delayCallback = () => {
                    timeoutIdRef.current = null;
                    callback.call();
                };

                cancelTimeout();
                timeoutIdRef.current = setTimeout(delayCallback, wait);
            };
        },
        [cancelTimeout, timeoutIdRef],
    );

    // Re-calculate height values if window size changes, e.g. on orientation change
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const debouncedUpdate = debounce(updateHeadlineStyles, 100);
            window.addEventListener('resize', debouncedUpdate);

            return () => {
                window.removeEventListener('resize', debouncedUpdate);
                cancelTimeout();
            };
        }

        return () => {};
    }, [cancelTimeout, debounce, updateHeadlineStyles]);

    return headlineStyles;
}
