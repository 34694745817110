import { useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { DropdownAlignment, FilterDropdownSwitch, Switch } from '@jsmdg/yoshi';

const messages = defineMessages({
    submitLabel: {
        defaultMessage: 'Fertig',
    },
    resetLabel: {
        defaultMessage: 'Zurücksetzen',
    },
});

type ToggleProps = {
    readonly label: string;
    readonly isActive: boolean;
    readonly isMobileFilter?: boolean;
    readonly onSubmit: (value: boolean) => void;
};

const Toggle = ({ isActive, isMobileFilter, label, onSubmit }: ToggleProps): JSX.Element => {
    const intl = useIntl();
    const initialSelectedActive = useRef(isActive);
    const [selectedActive, setSelectedActive] = useState(isActive);

    const handleOnClick = (): void => {
        setSelectedActive(!selectedActive);
    };

    const handleOnSubmit = (): void => {
        initialSelectedActive.current = selectedActive;
        onSubmit(selectedActive);
    };

    const handleOnSubmitMobile = (): void => {
        setSelectedActive(!selectedActive);
        onSubmit(!selectedActive);
    };

    const handleOnReset = (): void => {
        if (selectedActive) {
            onSubmit(false);
        }

        initialSelectedActive.current = false;
        setSelectedActive(false);
    };

    const handleOnClickOutside = (): void => {
        setSelectedActive(initialSelectedActive.current);
        handleOnSubmit();
    };

    if (isMobileFilter) {
        return (
            <div className="d-flex justify-content-between mt-2x mb-0-5x pr-1x">
                <div>{label}</div>
                <Switch isActive={selectedActive} onClick={handleOnSubmitMobile} />
            </div>
        );
    }

    return (
        <FilterDropdownSwitch
            switchTitle=""
            label={label}
            isActive={selectedActive}
            onClick={handleOnClick}
            resetLabel={intl.formatMessage(messages.resetLabel)}
            submitLabel={intl.formatMessage(messages.submitLabel)}
            numSelected={selectedActive ? 1 : 0}
            showNumSelected
            onSubmit={handleOnSubmit}
            onReset={handleOnReset}
            onClickOutside={handleOnClickOutside}
            hasChanged={initialSelectedActive.current !== selectedActive}
            alignment={DropdownAlignment.Auto}
            className="mr-1x mb-1x"
        />
    );
};

export { Toggle };
