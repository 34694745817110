import {
    GA4EventName,
    GA4FeatureCategory,
    PromotionType,
    trackAnalyticsEvent,
    trackEecPromotionClick,
    trackEecPromotionImpression,
} from '@jsmdg/tracking';
import { Banner, Wysiwyg } from '@jsmdg/yoshi';
import { CreativePlaceholderId } from '../../../../shared/enums/creativePlaceholderId';
import { type CampaignCreative } from '../../../../shared/types/campaignCreative';
import { CreativeName } from '../../../enums/trackingPromotions';
import { isGifImageUrl } from '../../../helper/isGifImageUrl';
import { useImpressionTracking } from '../../../hooks';

type HeroStageBannerProps = {
    readonly creative: CampaignCreative;
    readonly hasNavigationCreative: boolean;
    readonly verticalPosition: number;
};

const getModalInfo = (creative: CampaignCreative) => {
    const { body, iconColor, title } = creative?.asterisk || {};
    return body
        ? {
              headline: <Wysiwyg content={title || ''} />,
              infoContent: <Wysiwyg content={body} />,
              infoIconColor: iconColor || '',
              a11yCloseText: title || '',
          }
        : undefined;
};

const HeroStageBanner = ({
    creative,
    hasNavigationCreative,
    verticalPosition,
}: HeroStageBannerProps) => {
    const isDesktopCreative = creative.placeholderId === CreativePlaceholderId.HeroStageDesktop;
    const isGifImage = isGifImageUrl(creative.image.url);

    const adaptedVerticalPosition = hasNavigationCreative ? verticalPosition - 1 : verticalPosition;

    const position = `vertical${adaptedVerticalPosition}_horizontal1`;

    const promotionTrackingPayload = {
        id: creative.trackingName,
        name: creative.campaignName,
        creative: creative.placeholderId,
        position,
    };

    const promotionTrackingEventData = {
        creativeName: CreativeName.HeroBanner,
        creativeSlot: position,
        promotionName: creative.campaignName,
        promotionId: creative.trackingName,
        locationId: creative.url || '',
        promotionType: PromotionType.Dynamic,
        promotionCountdown: `${!!creative.countdown}`,
    };

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecPromotionImpression([promotionTrackingPayload], promotionTrackingEventData),
    );

    return (
        <Banner
            imageUrl={creative.image.url}
            alt={creative.image.alt}
            link={creative.url}
            modalInfos={getModalInfo(creative)}
            fullWidth
            srcSet={isGifImage ? '' : creative.imgixImage?.srcset}
            sizes={isGifImage ? '' : creative.imgixImage?.sizes}
            width={isGifImage ? undefined : creative.imgixImage?.width}
            height={isGifImage ? undefined : creative.imgixImage?.height}
            hasAspectRatio
            className={isDesktopCreative ? 'd-none d-xs-block w-100' : 'd-xs-none w-100'}
            onClick={() => {
                trackEecPromotionClick(promotionTrackingPayload, promotionTrackingEventData);
            }}
            onInfoClick={() =>
                trackAnalyticsEvent({
                    eventData: {
                        eventName: GA4EventName.ClickButton,
                        feature_category: GA4FeatureCategory.PromotionDynamic,
                        creative_name: 'Hero-banner',
                        click_element: 'Info icon',
                    },
                })
            }
            ref={setImpressionTrackedElement}
        />
    );
};

export { HeroStageBanner };
