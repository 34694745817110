import { useEffect, useState } from 'react';

import { RadioButton, RadioButtonGroup, type SelectOption } from '@jsmdg/yoshi';
import { SortingField } from '../../../../shared/enums/sorting';
import styles from '../shared/SelectFilter.module.scss';

type MobileSortingProps = {
    readonly options: SelectOption[];
    readonly selected?: string;
    readonly includeDistanceSorting: boolean;
    readonly onSortingClick: (option: string) => void;
};

const MobileSorting = ({
    includeDistanceSorting,
    onSortingClick,
    options,
    selected,
}: MobileSortingProps): JSX.Element => {
    const [showChild, setShowChild] = useState(includeDistanceSorting);

    useEffect(() => {
        setShowChild(includeDistanceSorting);
    }, [includeDistanceSorting, options]);

    const radioOption = (sortOption: SelectOption, className: string): JSX.Element => (
        <RadioButton
            value={sortOption.value}
            classNames={{ wrapperClassName: className }}
            key={sortOption.value}
        >
            <span className={sortOption.value === selected ? 'fw-semibold' : ''}>
                {sortOption.label}
            </span>
        </RadioButton>
    );

    return (
        <RadioButtonGroup
            name="sortingcxcycyc"
            className="mt-2x mb-1-5x"
            value={selected}
            onChange={onSortingClick}
            vertical
        >
            {options.map(sortOption => {
                const field = sortOption.value.split('_')[0];
                let className = '';

                if (field === SortingField.Distance) {
                    className = showChild ? styles.fadeIn : styles.fadeOut;
                }

                return radioOption(sortOption, className);
            })}
        </RadioButtonGroup>
    );
};

export { MobileSorting };
