import { TargetGroup } from './targetGroup';

export enum ScoringFactor {
    MaleShare = 'maleShare',
    FemaleShare = 'femaleShare',
    CoupleShare = 'coupleShare',
}

export const ScoringFactorMap = {
    [TargetGroup.Male]: ScoringFactor.MaleShare,
    [TargetGroup.Female]: ScoringFactor.FemaleShare,
    [TargetGroup.Couple]: ScoringFactor.CoupleShare,
};
