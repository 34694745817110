/* eslint-disable canonical/filename-match-regex */
import { defineMessages, type IntlShape, useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { TenantEnum } from '@jsmdg/tenancy';
import {
    BallonIcon,
    HalfClockIcon,
    OliveLeafIcon,
    USPBanner,
    type USPBannerProps,
} from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import styles from './USPSlider.module.scss';

const messages = {
    MD: defineMessages({
        titleMD: { defaultMessage: 'Warum bei uns bestellen?' },
        subtitleMD: {
            defaultMessage: 'Mit uns schenkst Du gemeinsame Zeit, die in Erinnerung bleibt.',
        },
        slider1HeadlineMD: {
            defaultMessage: 'Mehr als 8.000 ausgewählte Erlebnisse',
        },
        slider1SublineMD: {
            defaultMessage:
                'Von Wellness bis Action, von Kultur bis Kulinarik: Für jede und jeden ist das Richtige dabei.',
        },
        slider2HeadlineMD: { defaultMessage: '20 Jahre Erlebnisgeschenk-Experte' },
        slider2SublineMD: {
            defaultMessage:
                'Seit 2003 sorgen wir jeden Tag für unvergessliche Erlebnisse – gerne auch für Deins.',
        },
        slider3HeadlineMD: {
            defaultMessage: 'Flexible Gutscheine für Zeit, Ort und Erlebnis',
        },
        slider3SublineMD: {
            defaultMessage:
                'Dein Geschenk lässt der beschenkten Person alle Freiheiten, denn sie entscheidet selbst, was sie wann und wo erleben möchte.',
        },
    }),
    JS: defineMessages({
        titleJS: { defaultMessage: 'Warum mit uns erleben?' },
        subtitleJS: {
            defaultMessage: 'Wir sind dein Experte für außergewöhnliche Erlebnisse!',
        },
        slider1HeadlineJS: {
            defaultMessage: 'Beste Auswahl an einzigartigen Erlebnissen',
        },
        slider1SublineJS: {
            defaultMessage:
                'In unserer großen Vielfalt von Entspannung bis Nervenkitzel findest du garantiert das Erlebnis, das perfekt zu dir passt.',
        },
        slider2HeadlineJS: {
            defaultMessage: 'Der Marktführer mit über 1 Million Kunden',
        },
        slider2SublineJS: {
            defaultMessage:
                'Unsere Erlebnisse faszinieren unsere Kunden seit Jahrzehnten. Tauche auch du ein und entdecke, was sie so außergewöhnlich macht.',
        },
        slider3HeadlineJS: { defaultMessage: 'Flexible Gutscheine für Zeit, Ort und Erlebnis' },
        slider3SublineJS: {
            defaultMessage:
                'In jedem Erlebnisgutschein stecken unglaublich viele Möglichkeiten. Welches Erlebnis, wann und wo in die Tat umgesetzt wird, kann die beschenkte Person frei wählen.',
        },
    }),
};

const getUSPConfig = (isMydays: boolean, tenant: TenantEnum, intl: IntlShape): USPBannerProps => {
    return {
        title: intl.formatMessage(isMydays ? messages.MD.titleMD : messages.JS.titleJS),
        subtitle: intl.formatMessage(isMydays ? messages.MD.subtitleMD : messages.JS.subtitleJS),
        items: [
            {
                icon: <BallonIcon size={50} className={styles.icon} />,
                headline: intl.formatMessage(
                    isMydays ? messages.MD.slider1HeadlineMD : messages.JS.slider1HeadlineJS,
                ),
                subline: intl.formatMessage(
                    isMydays ? messages.MD.slider1SublineMD : messages.JS.slider1SublineJS,
                ),
            },
            {
                icon: <OliveLeafIcon size={50} className={styles.icon} />,
                headline: intl.formatMessage(
                    isMydays ? messages.MD.slider2HeadlineMD : messages.JS.slider2HeadlineJS,
                ),
                subline: intl.formatMessage(
                    isMydays ? messages.MD.slider2SublineMD : messages.JS.slider2SublineJS,
                ),
            },
            {
                icon: <HalfClockIcon size={50} className={styles.icon} />,
                headline: intl.formatMessage(
                    isMydays ? messages.MD.slider3HeadlineMD : messages.JS.slider3HeadlineJS,
                ),
                subline: intl.formatMessage(
                    isMydays ? messages.MD.slider3SublineMD : messages.JS.slider3SublineJS,
                ),
            },
        ],
        containerCls: tenant === TenantEnum.JS_AT ? 'mt-4x w-100' : 'mb-4x w-100',
    };
};

const USPSlider = (): JSX.Element => {
    const { isMydays, tenant } = useFragmentContext<FragmentContext>();
    const intl = useIntl();
    const uspConfig = getUSPConfig(isMydays, tenant, intl);

    return <USPBanner {...uspConfig} />;
};

export { USPSlider };
