import { FormattedMessage } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Nullable } from '../../types';

type FloatingTogglePriceMessageProps = {
    readonly priceData: {
        price: Array<Nullable<number>>;
        isDefaultMin: boolean;
        isDefaultMax: boolean;
    };
};

const FloatingTogglePriceMessage = ({
    priceData: { isDefaultMax, isDefaultMin, price },
}: FloatingTogglePriceMessageProps): JSX.Element | null => {
    const [min, max] = price;
    const { tenantConfig } = useFragmentContext<FragmentContext>();

    if (!isDefaultMin && !isDefaultMax) {
        return (
            <FormattedMessage
                defaultMessage="{min}{currencySymbol} - {max}{currencySymbol}"
                values={{
                    min,
                    max,
                    currencySymbol: tenantConfig.currency.symbol,
                }}
            />
        );
    }

    if (!isDefaultMin) {
        return (
            <FormattedMessage
                defaultMessage="Ab {min}{currencySymbol}"
                values={{
                    min,
                    currencySymbol: tenantConfig.currency.symbol,
                }}
            />
        );
    }

    if (!isDefaultMax) {
        return (
            <FormattedMessage
                defaultMessage="Bis {max}{currencySymbol}"
                values={{
                    max,
                    currencySymbol: tenantConfig.currency.symbol,
                }}
            />
        );
    }

    return null;
};

export { FloatingTogglePriceMessage };
