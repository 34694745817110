import { type IntlShape } from 'react-intl';

import { type Price } from '../../shared/types/searchResponse';

const renderPrice = (renderablePrice: Price | undefined, intl: IntlShape): string | undefined => {
    if (!renderablePrice) {
        return undefined;
    }

    return intl.formatNumber(Number(renderablePrice.gross.amount), {
        style: 'currency',
        currency: renderablePrice.gross.currencyCode,
    });
};

export { renderPrice };
