import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    previewPageMessage: {
        defaultMessage:
            'Die Seite ist noch nicht live - Das ist eine Preview-Seite. Alle gelb markierten Produkte sind ebenfalls noch nicht live.',
    },
    jepRedemptionMessage: {
        defaultMessage: 'Jetzt Gültigkeit prüfen',
    },
});
