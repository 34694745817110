import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { GA4EventName, type GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import {
    Breakpoint,
    FilterDropdownBase,
    NumericRange,
    Transition,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { type Filter } from '../../../../shared/types/search';
import { getActiveFilters } from '../../../hooks/getActiveFilters';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import styles from './DistanceFilter.module.scss';

const DEFAULT_DISTANCE = 100; // will this change by any chance?
const DISTANCE_BOUNDARY_MIN = 1;
const DISTANCE_BOUNDARY_MAX = 500;

const messages = defineMessages({
    submitLabel: {
        defaultMessage: 'Fertig',
    },
    resetLabel: {
        defaultMessage: 'Zurücksetzen',
    },
});

type DistanceFilterProps = {
    readonly distanceValue?: number;
    readonly shouldReset?: boolean;
    readonly show: boolean;
    readonly onSubmit: (type: SearchReducerActionType, value: number) => void;
    readonly filter: Filter;
    readonly listType?: GA4FilterListType;
};

const DistanceFilter = ({
    distanceValue,
    filter,
    listType,
    onSubmit,
    shouldReset = false,
    show,
}: DistanceFilterProps): JSX.Element => {
    const [distance, setDistance] = useState(distanceValue || DEFAULT_DISTANCE);
    const [hasChanged, setHasChanged] = useState(false);
    const [showChild, setShowChild] = useState(false);
    const isDesktop = useBreakpoint(Breakpoint.SM);
    const intl = useIntl();
    const activeFilters = getActiveFilters(filter);

    useEffect(() => {
        if (shouldReset || distanceValue === null) {
            setDistance(DEFAULT_DISTANCE);
        } else {
            setDistance(distanceValue || DEFAULT_DISTANCE);
        }
    }, [distanceValue, shouldReset]);

    useEffect(() => {
        setHasChanged(distanceValue !== distance);
    }, [distance, distanceValue]);

    useEffect(() => {
        setShowChild(show);
    }, [show]);

    const onDistanceSubmit = (value: number): void => {
        trackFilterInteraction(
            'SetRadius',
            `${value}km`,
            {
                eventName: GA4EventName.SetFilter,
                filter_type: 'Distance',
                filter_value: `${value}km`,
                list_type: listType,
            },
            activeFilters,
        );
        onSubmit(SearchReducerActionType.Distance, value);
    };

    const onDistanceSubmitDesktop = (): void => {
        /* for desktop we need to fire an additional event when the distance changes
               to have a connection between location and radius */
        onDistanceSubmit(distance);
    };

    const handleOnClickOutside = (): void => {
        if (hasChanged) {
            onDistanceSubmitDesktop();
        }
    };

    const handleReset = (): void => {
        trackFilterInteraction(
            'SetRadius',
            'ResetRadius',
            {
                eventName: GA4EventName.ResetFilter,
                filter_type: 'Distance',
                list_type: listType,
            },
            activeFilters,
        );
        setDistance(DEFAULT_DISTANCE);
        onSubmit(SearchReducerActionType.Distance, DEFAULT_DISTANCE);
    };

    if (!isDesktop) {
        return (
            <Transition show={showChild} fadeInStyle={styles.fadeIn} fadeOutStyle={styles.fadeOut}>
                <div className={classNames(styles.distanceWrapper, 'mt-3x px-2x')}>
                    <NumericRange
                        onSubmit={value => onDistanceSubmit(value as number)}
                        values={[distance || DEFAULT_DISTANCE]}
                        onChange={((value: number) => setDistance(value)) as () => void}
                        options={[DISTANCE_BOUNDARY_MIN, DISTANCE_BOUNDARY_MAX]}
                        label={
                            <FormattedMessage defaultMessage="Umkreis">
                                {txt => <span className="fw-semibold mr-2x">{txt}</span>}
                            </FormattedMessage>
                        }
                        step={1}
                        displayValue={
                            <span className={styles.distanceLabel}>
                                &nbsp;
                                <FormattedMessage
                                    defaultMessage="{distance}km"
                                    values={{ distance }}
                                />
                            </span>
                        }
                    />
                </div>
            </Transition>
        );
    }

    return (
        <div
            className={classNames(styles.distanceFilterDesktop, styles.toPosition)}
            data-testid="distance-filter-desktop"
        >
            <FilterDropdownBase
                label={`${distance} km`}
                resetLabel={intl.formatMessage(messages.resetLabel)}
                submitLabel={intl.formatMessage(messages.submitLabel)}
                numSelected={distanceValue !== DEFAULT_DISTANCE ? 1 : 0}
                showNumSelected={false}
                onSubmit={onDistanceSubmitDesktop}
                onReset={handleReset}
                onClickOutside={handleOnClickOutside}
                hasChanged={hasChanged}
                className={styles.dropdown}
            >
                <NumericRange
                    values={[distance || DEFAULT_DISTANCE]}
                    onChange={
                        ((sliderValue: number) => {
                            setDistance(sliderValue);
                            setHasChanged(true);
                        }) as () => void
                    }
                    options={[DISTANCE_BOUNDARY_MIN, DISTANCE_BOUNDARY_MAX]}
                    step={1}
                    displayValue={
                        <FormattedMessage
                            defaultMessage="Umkreis: {distance}km"
                            values={{ distance }}
                        />
                    }
                />
            </FilterDropdownBase>
        </div>
    );
};

export { DistanceFilter };
