import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { GA4EventName, type GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { Button, ButtonVariant, FilterIcon } from '@jsmdg/yoshi';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type Filter } from '../../../../shared/types/search';
import { type SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { ProductFacet } from './ProductFacet';
import styles from './ProductFacets.module.scss';

const SHOW_MORE_THRESHOLD = 7;

type ProductFacetsProps = {
    readonly facets: FacetFilter[];
    readonly filter: Filter;
    readonly onFilterChange: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly paginationLimit?: number;
    readonly listType?: GA4FilterListType;
};

const ProductFacets = ({
    facets,
    filter,
    listType,
    onFilterChange,
    paginationLimit,
}: ProductFacetsProps): JSX.Element => {
    const numFilters = facets.length;
    const activeFilters = filter.productAttributes ? Object.keys(filter.productAttributes) : [];

    // check if some filters after the threshold are in use
    const getActiveAfterThreshold = (): boolean => {
        if (!activeFilters.length) {
            return false;
        }

        return !!facets
            .slice(SHOW_MORE_THRESHOLD - 1)
            .some(facet => activeFilters.includes(facet.attribute));
    };

    const hasActiveFiltersAfterThreshold = getActiveAfterThreshold();

    const [showMoreButton, setShowMoreButton] = useState(
        numFilters > SHOW_MORE_THRESHOLD && !hasActiveFiltersAfterThreshold,
    );

    const handleMoreButtonClick = (): void => {
        setShowMoreButton(false);
        trackFilterInteraction('ButtonClick', 'Mehr Filter', {
            eventName: GA4EventName.ClickButton,
            click_element: 'Expanding more filters',
            click_text: 'Mehr Filter',
        });
    };

    useEffect(() => {
        setShowMoreButton(numFilters > SHOW_MORE_THRESHOLD && !hasActiveFiltersAfterThreshold);
    }, [hasActiveFiltersAfterThreshold, numFilters]);

    const numVisibleFilters = showMoreButton ? SHOW_MORE_THRESHOLD : numFilters;

    return (
        <>
            {facets
                .filter((_, index) => index < numVisibleFilters)
                .map(facet => {
                    const selectedOptions = filter.productAttributes?.[facet.attribute];

                    return (
                        <ProductFacet
                            facet={facet}
                            selectedOptions={selectedOptions}
                            onFilterChange={onFilterChange}
                            key={facet.attribute}
                            paginationLimit={paginationLimit}
                            filter={filter}
                            listType={listType}
                        />
                    );
                })}
            {showMoreButton && (
                <Button
                    className={styles.showMore}
                    onClick={handleMoreButtonClick}
                    variant={ButtonVariant.Ghost}
                >
                    <FilterIcon className="my-0 mr-0-5x ml-0" />
                    <FormattedMessage defaultMessage="Mehr Filter" />
                </Button>
            )}
        </>
    );
};

export { ProductFacets };
