export enum AlgoliaCustomDataKey {
    DefaultPageFacets = 'default_page_facets',
    PerPageFacets = 'per_page_facets',
}

export const getAlgoliaCustomData = (
    key: AlgoliaCustomDataKey,
    userData?: Array<Record<AlgoliaCustomDataKey, unknown>>,
): string[] | undefined => {
    if (!userData?.length) {
        return undefined;
    }

    const match = userData.find(customData => key in customData);

    return match ? (match[key] as string[]) : undefined;
};
