import { type ChangeEvent, type FormEvent, useEffect, useReducer, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type AxiosError } from 'axios';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { ButtonColor, ButtonLoader, Input, ValidationStates, Wysiwyg } from '@jsmdg/yoshi';
import { DiscountCodeStates } from '../../../shared/enums/discountCodeStates';
import { ActionType, ButtonState } from '../../enums/discountCode';
import { discountStateReducer, initialState } from '../../reducers/discountCodeRedcucer';
import { checkDiscountCode } from '../../services/checkDiscountCode';
import { SuccessMessage } from '../SuccessMessage';
import styles from './DiscountCodeBox.module.scss';

const messages = defineMessages({
    invalid: {
        defaultMessage: 'Dieser Rabattcode ist leider ungültig.',
    },
    expired: {
        defaultMessage: 'Es tut uns Leid! Dieser Rabattcode ist bereits abgelaufen.',
    },
    limitReached: {
        defaultMessage:
            'Es tut uns Leid! Die maximale Anzahl an Einlösungen für diesen Rabatt wurde bereits erreicht.',
    },
    validFuture: {
        defaultMessage:
            'Es tut uns Leid! Der Rabattcode kann derzeit nicht angewendet werden. Bitte versuche es später noch einmal.',
    },
    technicalError: {
        defaultMessage:
            'Aus technischen Gründen können aktuell leider keine Rabatte hinzugefügt werden. Bitte versuche es später noch einmal.',
    },
    emptyInputError: {
        defaultMessage: 'Bitte Rabattcode eingeben.',
    },
});

type DiscountCodeBoxProps = {
    readonly placeholder?: string;
    readonly buttonText?: string;
    readonly description?: string;
    readonly successMessage?: string;
    readonly code?: string;
};

const DiscountCodeBox = ({
    buttonText = '',
    code = '',
    description = '',
    placeholder = '',
    successMessage = '',
}: DiscountCodeBoxProps): JSX.Element => {
    const intl = useIntl();

    const [state, dispatch] = useReducer(discountStateReducer, initialState);
    const [discountCode, setDiscountCode] = useState(code);

    const [selection, setSelection] = useState({ start: 0, end: 0 });
    const discountCodeInput = useRef<HTMLInputElement>(null);

    const getPayload = (data: DiscountCodeStates): string => {
        const msgMap = {
            [DiscountCodeStates.Valid]: {},
            [DiscountCodeStates.Invalid]: messages.invalid,
            [DiscountCodeStates.Expired]: messages.expired,
            [DiscountCodeStates.LimitReached]: messages.limitReached,
            [DiscountCodeStates.ValidFuture]: messages.validFuture,
        };
        return intl.formatMessage(msgMap[data] || messages.invalid);
    };

    const onRedeemCode = async (): Promise<void> => {
        if (!discountCode) {
            dispatch({
                type: ActionType.Error,
                payload: intl.formatMessage(messages.emptyInputError),
            });
            return;
        }

        dispatch({ type: ActionType.Loading });
        try {
            const { data } = await checkDiscountCode(discountCode);
            if (data === DiscountCodeStates.Valid) {
                // discount code exists and is valid
                Cookies.set('campaign_discount', discountCode);
                dispatch({ type: ActionType.CodeRedeemed });
            } else {
                // discount code exists but cannot be redeemed
                dispatch({ type: ActionType.Error, payload: getPayload(data) });
            }
        } catch (error) {
            // discount code either does not exist or code cannot be checked at all
            const errorStatus = (error as AxiosError).response?.status;
            dispatch({
                type: ActionType.Error,
                payload:
                    errorStatus === 404
                        ? intl.formatMessage(messages.invalid)
                        : intl.formatMessage(messages.technicalError),
            });
        }
    };

    // maintains the text cursor position when input is changed, otherwise it would be set to the end of the input by default
    useEffect(() => {
        const { end, start } = selection;
        discountCodeInput.current?.setSelectionRange(start, end);
    }, [selection]);

    const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        await onRedeemCode();
    };

    const onInput = (event: ChangeEvent<HTMLInputElement>): void => {
        const start = discountCodeInput.current?.selectionStart;
        const end = discountCodeInput.current?.selectionEnd;
        setSelection({ start: start || 0, end: end || 0 });
        setDiscountCode(
            event.target?.value
                .split('')
                .map(char => (char !== 'ß' ? char.toUpperCase() : char))
                .join(''),
        );
    };

    return (
        <div key="discount-code-box" className={classNames(styles.discountCodeBox, 'w-100 p-3x')}>
            {state.codeRedeemed ? (
                <SuccessMessage content={successMessage} />
            ) : (
                <div className="grid">
                    <div className="g-col-12 g-col-sm-5 align-self-center">
                        <form onSubmit={onSubmit}>
                            <Input
                                className="w-100"
                                ref={discountCodeInput}
                                placeholder={placeholder}
                                value={discountCode}
                                onInput={onInput}
                                validationState={state.validationState}
                                helperText={state.errorText}
                                showHelperText={state.validationState === ValidationStates.Error}
                            />
                            <ButtonLoader
                                className="w-100 my-2x mb-sm-0"
                                color={ButtonColor.Complementary}
                                isLoading={state.buttonState === ButtonState.Loading}
                                onClick={onRedeemCode}
                            >
                                <span>{buttonText}</span>
                            </ButtonLoader>
                        </form>
                    </div>
                    <div className="g-col-12 g-col-sm-7">
                        <Wysiwyg
                            className={classNames(styles.description, 'mt-sm-3x')}
                            content={description}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export { DiscountCodeBox };
