export enum CreativePlaceholderId {
    ProductTileDesktopLarge = 'product-tile-desktop-large',
    ProductTileDesktopSmall = 'product-tile-desktop-small',
    ProductTileTablet = 'product-tile-tablet',
    ProductTileMobile = 'product-tile-mobile',
    ProductListDesktop = 'product-list-desktop',
    NavigationBannerDesktop = 'navigation-banner-desktop',
    SelectionBlockDesktop = 'selection-block-desktop',
    HeroStageDesktop = 'hero-stage-desktop',
    HeroStageMobile = 'hero-stage-mobile',
}
