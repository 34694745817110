import { Suspense } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';

import { type PageType } from '../../../shared/enums/pageType';
import { type SearchResult } from '../../../shared/types/searchResult';
import { TrackingListName } from '../../enums/trackingListName';
import { ProductSliderFallback } from '../ProductSlider/ProductSliderFallBack';

const ProductSlider = loadable(async () => import('../ProductSlider/ProductSlider'));

type BestsellerSliderProps = {
    readonly initialSearchResult: SearchResult;
    readonly moreResultsLink: string;
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly verticalPosition?: number;
};

const messages = defineMessages({
    bestsellerProducts: { defaultMessage: 'Beliebte Erlebnisse' },
    bestsellerShowMore: { defaultMessage: 'Mehr entdecken' },
});

const BestsellerSlider = ({
    initialSearchResult,
    lazyLoad,
    moreResultsLink,
    pageType,
    verticalPosition,
}: BestsellerSliderProps): JSX.Element | null => {
    const intl = useIntl();

    const products = initialSearchResult.items;

    if (!products.length) {
        return null;
    }

    return (
        <Suspense fallback={<ProductSliderFallback />}>
            <ProductSlider
                products={products}
                title={intl.formatMessage(messages.bestsellerProducts)}
                urlTitle={intl.formatMessage(messages.bestsellerShowMore)}
                url={moreResultsLink}
                trackingCategory="ProductList"
                trackingListName={TrackingListName.Bestseller}
                dataTestId="bestseller-slider"
                lazyLoad={lazyLoad}
                pageType={pageType}
                verticalPosition={verticalPosition}
            />
        </Suspense>
    );
};

export { BestsellerSlider };
