import { defineMessages } from 'react-intl';

import { DurationOption } from '../../../shared/enums/durationOption';

export const messagesTravelNights = defineMessages({
    mainLabelTravelNights: {
        defaultMessage: 'Übernachtungen',
    },
    oneNight: {
        defaultMessage: '1 Übernachtung',
    },
    twoNights: {
        defaultMessage: '2 Übernachtungen',
    },
    moreThanTwoNights: {
        defaultMessage: 'Ab 3 Übernachtungen',
    },
});

export const messagesDuration = defineMessages({
    mainLabelTotalDuration: {
        defaultMessage: 'Gesamterlebnisdauer',
    },
    mainLabelActivityDuration: {
        defaultMessage: 'Aktivitätsdauer',
    },
    lessHalfHour: {
        defaultMessage: '0-30 min',
    },
    lessHour: {
        defaultMessage: '30-60 min',
    },
    lessTwoHours: {
        defaultMessage: '1-2 h',
    },
    lessThreeHours: {
        defaultMessage: '2-3 h',
    },
    lessFourHours: {
        defaultMessage: '3-4 h',
    },
    lessFiveHours: {
        defaultMessage: '4-5 h',
    },
    lessSixHours: {
        defaultMessage: '5-6 h',
    },
    lessSevenHours: {
        defaultMessage: '6-7 h',
    },
    moreSevenHours: {
        defaultMessage: '>7 h',
    },
});

export const durationOptionMap = {
    [DurationOption.LessHalfHour]: messagesDuration.lessHalfHour,
    [DurationOption.LessHour]: messagesDuration.lessHour,
    [DurationOption.LessTwoHours]: messagesDuration.lessTwoHours,
    [DurationOption.LessThreeHours]: messagesDuration.lessThreeHours,
    [DurationOption.LessFourHours]: messagesDuration.lessFourHours,
    [DurationOption.LessFiveHours]: messagesDuration.lessFiveHours,
    [DurationOption.LessSixHours]: messagesDuration.lessSixHours,
    [DurationOption.LessSevenHours]: messagesDuration.lessSevenHours,
    [DurationOption.MoreSevenHours]: messagesDuration.moreSevenHours,
};
