import { useEffect } from 'react';

import { trackProductListItems } from '@jsmdg/tracking';
import { type Product } from '../../shared/types/searchResponse';

export const useTrackProductListItems = (products: Product[]): void => {
    useEffect(() => {
        trackProductListItems(window.location.origin, products);
    }, [products]);
};
