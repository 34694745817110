import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';

export const trackClickOnPin = (productTitle: string): void => {
    trackAnalyticsEvent({
        eventData: {
            eventName: GA4EventName.ClickButton,
            feature_category: GA4FeatureCategory.ProductList,
            click_element: 'pin on map',
            product_title: productTitle,
        },
    });
};

export const trackToggleList = (isSidePanelOpen: boolean): void => {
    if (isSidePanelOpen) {
        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.ClickButton,
                feature_category: GA4FeatureCategory.ProductList,
                click_element: 'close list in map view',
            },
        });
    } else {
        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.ClickButton,
                feature_category: GA4FeatureCategory.ProductList,
                click_element: 'expand list in map view',
            },
        });
    }
};

export const trackCloseMapViewClick = (clickText: string): void => {
    let clickElement = 'list view via button back from map';

    if (clickText === 'X') {
        clickElement = 'list view via button X on map';
    } else if (clickText === 'Liste') {
        clickElement = 'list view via floating button on map';
    }

    trackAnalyticsEvent({
        eventData: {
            eventName: GA4EventName.CloseMap,
            feature_category: GA4FeatureCategory.ProductList,
            click_element: clickElement,
            click_text: clickText,
        },
    });
};

export const trackOpenMapView = (clickElement: string, clickText: string): void => {
    trackAnalyticsEvent({
        eventData: {
            eventName: GA4EventName.OpenMap,
            feature_category: GA4FeatureCategory.ProductList,
            click_element: `map view via ${clickElement}`,
            click_text: clickText,
        },
    });
};
