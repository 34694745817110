import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { LiveLocationIcon, TooltipButton, TooltipPlacement } from '@jsmdg/yoshi';
import { storageAvailable } from '../../../../../helper/storageHelper';
import { SearchReducerActionType } from '../../../../../reducers/searchReducer';
import styles from './CurrentLocationButton.module.scss';

type CurrentLocationButtonProps = {
    readonly onSubmit: (type: SearchReducerActionType) => void;
    readonly location?: string;
};

const messages = defineMessages({
    currentLocation: {
        defaultMessage: 'Dein Standort',
    },
});

const shouldShowLocationButton = (location: string): boolean => {
    if (location) return false;
    if (typeof window !== 'undefined' && storageAvailable('sessionStorage')) {
        return window.sessionStorage.getItem('locationApiAccepted') !== 'false';
    }

    return true;
};

const CurrentLocationButton = ({
    location = '',
    onSubmit,
}: CurrentLocationButtonProps): JSX.Element | null => {
    const intl = useIntl();
    const getCurrentLocation = (): void => {
        onSubmit(SearchReducerActionType.GeoCoordinates);
    };

    const showLocationButton = shouldShowLocationButton(location);

    if (!showLocationButton) {
        return null;
    }

    return (
        <TooltipButton
            onClick={getCurrentLocation}
            className={classNames('d-flex h-100 w-100 align-items-center justify-content-center')}
            tooltipPlacement={TooltipPlacement.Top}
            tooltip={intl.formatMessage(messages.currentLocation)}
            data-testid="locate-me-button"
        >
            <span
                className={classNames(
                    'd-xs-flex align-items-center justify-content-center pt-0-5x pt-xs-0 pl-1x',
                    styles.button,
                )}
            >
                <span className="screenReaderOnly">
                    {intl.formatMessage(messages.currentLocation)}
                </span>
                <LiveLocationIcon className={styles.icon} />
            </span>
        </TooltipButton>
    );
};

export { CurrentLocationButton };
