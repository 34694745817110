import { type GA4ClickEvent } from '@jsmdg/tracking/dist/events';
import { type SlidingDirections } from '@jsmdg/yoshi';
import { TrackingCategory } from '../enums/trackingCategory';
import { trackSlide } from './trackSlide';

type HandleSlidingDirectionsProps = {
    direction: SlidingDirections;
    trackingCategory?: string;
    eventData?: GA4ClickEvent;
};

export const handleSlidingDirections = async ({
    direction,
    trackingCategory,
    eventData,
}: HandleSlidingDirectionsProps): Promise<void> => {
    await window.yieldToMainThread();
    const category = trackingCategory || TrackingCategory.InternalPromotion;
    trackSlide({ direction, category, eventData });
};
