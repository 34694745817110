import { StrictMode, useEffect } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackCategoryTree } from '@jsmdg/tracking';
import {
    Breakpoint,
    NotificationSnackbar,
    SnackbarContextProvider,
    SnackbarPosition,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { ContentType } from '../../../shared/enums/contentType';
import { PageType } from '../../../shared/enums/pageType';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import {
    type PageContent as PageContentType,
    type ProductSelection,
} from '../../../shared/types/pageContent';
import { type PageRoot } from '../../../shared/types/pageRoot';
import { AlgoliaIndexId } from '../../enums/algoliaIndexId';
import { useAlgolia } from '../../hooks/useAlgolia';
import { PageContent } from './PageContent';
import { PageHeader } from './PageHeader';
import { PageWrapper } from './PageWrapper';
import { SearchPage } from './SearchPage';
import styles from './Page.module.scss';

const getPageType = (type: string, searchTerm?: string, numProductListings?: number): PageType => {
    if (type === PageType.Home) {
        return PageType.Home;
    }

    if (type === PageType.Redemption) {
        return PageType.Redemption;
    }

    if (searchTerm) {
        return PageType.SearchPage;
    }

    if (numProductListings) {
        return PageType.ProductListingPage;
    }

    return PageType.Other;
};

const Page = (props: PageRoot): JSX.Element => {
    const upToXSBreakpoint = useBreakpoint(Breakpoint.XS);
    const { type } = useFragmentContext<FragmentContext>();

    const {
        document,
        inPreview = false,
        initialSearch = {},
        numberOfProductLists,
        pageId = '',
    } = props;

    const { searchTerm, sorting } = initialSearch;
    const { client, indexName } = useAlgolia(sorting);

    const productListContent = document?.contents.find(
        (content: PageContentType): content is ProductSelection =>
            content.type === ContentType.ProductListQuery,
    );

    const pageType = getPageType(type, searchTerm, numberOfProductLists);
    const isSearchPage = pageType === PageType.SearchPage;

    useEffect(() => {
        trackCategoryTree(document?.trackingPath, document?.trackingName);
    }, [document?.trackingPath, document?.trackingName]);

    return (
        <StrictMode>
            <SnackbarContextProvider>
                <NotificationSnackbar
                    className={upToXSBreakpoint ? 'container' : 'px-2x'}
                    position={SnackbarPosition.Bottom}
                />
                <PageWrapper client={client}>
                    <div className={styles.page} data-root="algolia-search">
                        <PageHeader
                            inPreview={inPreview}
                            pageType={pageType}
                            indexId={AlgoliaIndexId.SearchPage}
                            productListContent={productListContent}
                            headline={document?.headline}
                            locationAware={document?.locationAware}
                            indexName={indexName}
                        />
                        {isSearchPage && (
                            <SearchPage
                                initialSearch={initialSearch}
                                indexId={AlgoliaIndexId.SearchPage}
                            />
                        )}

                        <PageContent
                            document={document}
                            pageType={pageType}
                            pageId={pageId}
                            numProductListings={numberOfProductLists}
                        />
                    </div>
                </PageWrapper>
            </SnackbarContextProvider>
        </StrictMode>
    );
};

export { Page };
