import { Suspense } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';

import { type PageType } from '../../../shared/enums/pageType';
import { TrackingListName } from '../../enums/trackingListName';
import { useLastSeenProducts } from '../../hooks/useLastSeenProducts';
import { ProductSliderFallback } from '../ProductSlider/ProductSliderFallBack';

const ProductSlider = loadable(async () => import('../ProductSlider/ProductSlider'), {
    fallback: <ProductSliderFallback />,
});

const messages = defineMessages({
    lastSeenProducts: { defaultMessage: 'Zuletzt gesehen' },
});

type LastSeenProductSliderProps = {
    readonly lazyLoad: boolean;
    readonly pageType: PageType;
    readonly productIds?: string[];
    readonly verticalPosition?: number;
};

const LastSeenProductSlider = ({
    lazyLoad,
    pageType,
    productIds = [],
    verticalPosition,
}: LastSeenProductSliderProps): JSX.Element | null => {
    const intl = useIntl();
    const { products } = useLastSeenProducts(productIds);

    if (!productIds.length) {
        return null;
    }

    return (
        <Suspense fallback={<ProductSliderFallback />}>
            <ProductSlider
                products={products}
                title={intl.formatMessage(messages.lastSeenProducts)}
                trackingCategory="ProductList"
                trackingListName={TrackingListName.LastSeen}
                dataTestId="last-seen-slider"
                lazyLoad={lazyLoad}
                pageType={pageType}
                verticalPosition={verticalPosition}
            />
        </Suspense>
    );
};

export { LastSeenProductSlider };
