export enum ButtonState {
    Enabled = 'enabled',
    Loading = 'loading',
}

export enum ActionType {
    Loading = 'loading',
    CodeRedeemed = 'codeRedeemed',
    Error = 'error',
}
