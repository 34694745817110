import { ValidationStates } from '@jsmdg/yoshi';
import { ActionType, ButtonState } from '../enums/discountCode';
import { type Action, type State } from '../types/discountCode';

export const initialState = {
    buttonState: ButtonState.Enabled,
    validationState: ValidationStates.Success,
    errorText: null,
    codeRedeemed: false,
};

export const discountStateReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.Loading:
            return {
                ...state,
                buttonState: ButtonState.Loading,
                validationState: ValidationStates.Success,
            };
        case ActionType.CodeRedeemed:
            return {
                ...state,
                codeRedeemed: true,
                buttonState: ButtonState.Enabled,
                validationState: ValidationStates.Success,
                errorText: '',
            };
        case ActionType.Error:
            return {
                ...state,
                buttonState: ButtonState.Enabled,
                validationState: ValidationStates.Error,
                errorText: action.payload,
            };
        default:
            return state;
    }
};
