import axios from 'axios';
import { BroadcastChannel } from 'broadcast-channel';

async function addProductToCart(productId, quantity) {
    const { data: cart } = await axios.post('/api/cart/add-product', { productId, quantity });

    const syncCartBroadcastChannel = new BroadcastChannel('sync-cart-broadcast-channel', {
        webWorkerSupport: false,
    });

    // eslint-disable-next-line unicorn/require-post-message-target-origin
    await syncCartBroadcastChannel.postMessage({ cartVersion: cart.cartVersion });
    await syncCartBroadcastChannel.close();
}

export { addProductToCart };
