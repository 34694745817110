import styles from './Headline.module.scss';

type HeadlineProps = {
    readonly title: string;
};

const Headline = ({ title }: HeadlineProps): JSX.Element => {
    return <h2 className={styles.headline}>{title}</h2>;
};

export { Headline };
