export const dispatchEvent = (eventId: string, detail?: unknown): void => {
    // disable dispatch events, because we use server side rendering
    if (typeof document === 'undefined') {
        return;
    }

    const customEvent = new CustomEvent(eventId, { detail });

    document.dispatchEvent(customEvent);
};
