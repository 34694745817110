export enum AttributeName {
    ActivityDuration = 'aktivitaetsdauer',
    TotalDuration = 'gesamterlebnisdauer',
    TravelNights = 'uebernachtung',
    Participants = 'anzahlTeilnehmer',
    MinimumAge = 'mindestalter',
    Rating = 'anzahlSterne',
    Height = 'hoehe',
    Length = 'laenge',
    Depth = 'tiefe',
}

export const TRAVELNIGHTS_THRESHOLD = 3;
export const PARTICIPANTS_THRESHOLD = 2;
