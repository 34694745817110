import { CheckCircleIcon, Wysiwyg } from '@jsmdg/yoshi';
import styles from './SuccessMessage.module.scss';

type SuccessMessageProps = {
    readonly content: string;
};

const SuccessMessage = ({ content }: SuccessMessageProps): JSX.Element => (
    <div className="grid">
        <div className="g-col-12 g-col-xs-2 d-flex justify-content-center">
            <CheckCircleIcon size={100} className={styles.icon} />
        </div>
        <div className="g-col-12 g-col-xs-10 d-flex align-items-center">
            <Wysiwyg className="text-center text-sm-left" content={content} />
        </div>
    </div>
);

export { SuccessMessage };
