import { type MouseEvent } from 'react';

import {
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
    trackEecPromotionClick,
    trackEecPromotionImpression,
} from '@jsmdg/tracking';
import { CategoryPill, RenderType } from '@jsmdg/yoshi';
import { type PageType as Type } from '../../../shared/enums/pageType';
import { type TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { trackingTypeByPage } from '../../enums/trackingPageTypes';
import { CreativeName, PromotionType } from '../../enums/trackingPromotions';
import { enhanceUrlWithSearchParams } from '../../helper/enhanceUrlWithSearchParams';
import { useImpressionTracking } from '../../hooks';
import styles from './CategorySlider.module.scss';

type CategorySlideProps = {
    readonly linkUrl: string;
    readonly title: string;
    readonly trackingName?: string;
    readonly position: string;
    readonly trackingPath?: string[];
    readonly positionHorizontal: string;
    readonly positionVertical: string;
    readonly pageType: Type;
    readonly trackingPrefix: TrackingCreativePrefix;
};

const CategorySlide = ({
    linkUrl,
    pageType,
    position,
    positionHorizontal,
    positionVertical,
    title,
    trackingName,
    trackingPath,
    trackingPrefix,
}: CategorySlideProps): JSX.Element => {
    const name = title || 'no name';
    const id = trackingName || 'no id';

    const promotionTrackingPayload = {
        id,
        name,
        creative: `${trackingPrefix}_${trackingTypeByPage[pageType]}`,
        position,
    };

    const promotionTrackingEventData = {
        creativeName: CreativeName.PageTile,
        creativeSlot: position,
        promotionName: name,
        promotionId: id,
        locationId: linkUrl || '',
        positionHorizontal,
        positionVertical,
        promotionType: PromotionType.Static,
    };

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecPromotionImpression([promotionTrackingPayload], promotionTrackingEventData),
    );

    const fullTrackingPath = [...(trackingPath || []), trackingName].join(' > ');

    const onLinkClick = (event?: MouseEvent): void => {
        event?.preventDefault();
        trackEecPromotionClick(promotionTrackingPayload, promotionTrackingEventData);

        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.PageSliderNavigation,
                click_element: fullTrackingPath,
                click_text: title,
                position_horizontal: positionHorizontal,
                position_vertical: positionVertical,
                click_url: window.location.origin + linkUrl,
            },
        });

        window.location.href = enhanceUrlWithSearchParams(linkUrl);
    };

    return (
        <div ref={setImpressionTrackedElement} className={styles.categorySlideWrapper}>
            <CategoryPill key={title} as={RenderType.Link} href={linkUrl} onClick={onLinkClick}>
                {title}
            </CategoryPill>
        </div>
    );
};

export { CategorySlide };
