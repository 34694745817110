import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackNoResultPage } from '@jsmdg/tracking';
import { Image, Link, LinkVariant } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import JS from './noSearchResultJS.svg';
import MD from './noSearchResultMD.svg';
import styles from './NoSearchTermResult.module.scss';

const messages = defineMessages({
    customerService: {
        defaultMessage: 'Kundenservice',
    },
    redemptionLink: {
        defaultMessage: 'hier entlang',
    },
    titleJS: {
        defaultMessage: 'Suchtipps:',
    },
    titleMD: {
        defaultMessage: 'So klappt’s bestimmt:',
    },
    firstTipJS: {
        defaultMessage: 'Prüfe deine Eingabe auf Tippfehler',
    },
    firstTipMD: {
        defaultMessage: 'Gib einen Oberbegriff ein bzw. ein alternatives Wort',
    },
    secondTipJS: {
        defaultMessage: 'Suche nach einem allgemeineren oder ähnlichen Suchbegriff',
    },
    secondTipMD: {
        defaultMessage: 'Formuliere allgemeiner, wonach du suchst',
    },
    thirdTip: {
        defaultMessage: 'Gib weniger Worte bei deiner Suche an',
    },
});

enum LinkText {
    Support = 'Support',
    Redemption = 'Redemption',
}

type NoResultProps = {
    readonly searchTerm?: string;
};

const NoSearchTermResult = ({ searchTerm }: NoResultProps): JSX.Element => {
    const { isMydays, tenantConfig }: FragmentContext = useFragmentContext();
    const intl = useIntl();

    const { noSearchResults } = tenantConfig;

    const onTextClick = (label: LinkText): void => {
        const isSupport = label === LinkText.Support;

        trackNoResultPage(
            `Search no results ${isSupport ? 'customer care' : 'redemption'}`,
            isSupport ? 'Kundenservice' : 'hier entlang',
            isSupport
                ? `${window.location.origin}${tenantConfig.urls.contact}`
                : tenantConfig.urls.redemption,
        );
    };

    return (
        <div className="mt-2x mt-3x">
            <h1 className="fw-semibold">
                <FormattedMessage
                    defaultMessage="Leider haben wir zu {search} keine Ergebnisse gefunden."
                    values={{
                        search: (
                            <span>
                                <span>
                                    <FormattedMessage defaultMessage="&ldquo;" />
                                </span>
                                <span className={classNames(styles.searchTerm, 'fw-semibold')}>
                                    {searchTerm}
                                </span>
                                <span>
                                    <FormattedMessage defaultMessage="&ldquo;" />
                                </span>
                            </span>
                        ),
                    }}
                />
            </h1>
            <div className={styles.noresultContent}>
                {isMydays ? (
                    <Image
                        src={MD}
                        className="d-none d-sm-block mr-sm-4x"
                        lazyLoad={false}
                        alt=""
                    />
                ) : (
                    <Image src={JS} className="d-none d-sm-block" lazyLoad={false} alt="" />
                )}
                <div className={styles.noresultText}>
                    <div className="theme-text-headline">
                        {intl.formatMessage(messages[noSearchResults.title.messageId])}
                    </div>
                    <ul className="mt-1-5x">
                        <li>{intl.formatMessage(messages[noSearchResults.firstTip.messageId])}</li>
                        <li>{intl.formatMessage(messages[noSearchResults.secondTip.messageId])}</li>
                        <li>{intl.formatMessage(messages[noSearchResults.thirdTip.messageId])}</li>
                    </ul>
                    <p className="fw-regular mt-3x">
                        <FormattedMessage
                            defaultMessage="Bei Fragen wende dich bitte an unseren {Kundenservice},
                                    wenn du einen Gutschein einlösen möchtest geht es {RedemptionLink}."
                            values={{
                                Kundenservice: (
                                    <Link
                                        href={tenantConfig.urls.contact}
                                        target="_blank"
                                        className="fw-semibold"
                                        onClick={() => onTextClick(LinkText.Support)}
                                        variant={LinkVariant.Brand}
                                        internal
                                    >
                                        {intl.formatMessage(messages.customerService)}
                                    </Link>
                                ),
                                RedemptionLink: (
                                    <Link
                                        href={tenantConfig.urls.redemption}
                                        target="_blank"
                                        className="fw-semibold"
                                        onClick={() => onTextClick(LinkText.Redemption)}
                                        variant={LinkVariant.Brand}
                                    >
                                        {intl.formatMessage(messages.redemptionLink)}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

export { NoSearchTermResult };
