import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FeatureCategory, trackUserFeedbackEvent } from '@jsmdg/tracking';
import {
    Breakpoint,
    Button,
    ButtonColor,
    ButtonType,
    Feedback,
    type FeedbackRating,
    Link,
    LinkVariant,
    Textarea,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { TrackingFeedbackRating } from '../../enums/trackingFeedbackRating';
import { SuccessInfo } from '../SuccessInfo';
import styles from './FeedbackForm.module.scss';

const messages = defineMessages({
    userFeedback: {
        defaultMessage: 'Schreib uns dein Feedback',
    },
    successTitle: {
        defaultMessage: 'Vielen Dank für dein Feedback!',
    },
});

const trackSmileysMapping: { [key: number]: TrackingFeedbackRating } = {
    1: TrackingFeedbackRating.VeryUnhappy,
    2: TrackingFeedbackRating.Unhappy,
    3: TrackingFeedbackRating.Neutral,
    4: TrackingFeedbackRating.Happy,
    5: TrackingFeedbackRating.VeryHappy,
};

type FeedbackFormProps = {
    readonly pageId: string;
    readonly pageTitle: string;
};

const FeedbackForm = ({ pageId, pageTitle }: FeedbackFormProps): JSX.Element => {
    const intl = useIntl();
    const { errorLogger, submitFeedbackForm, tenantConfig } = useFragmentContext<FragmentContext>();
    const successRef = useRef<HTMLInputElement>(null);
    const [formIsValid, setFormIsValid] = useState(false);
    const [showSuccessInfo, setShowSuccessInfo] = useState(false);
    const [selectedRating, setSelectedRating] = useState<FeedbackRating>(0);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const isGtMobile = useBreakpoint(Breakpoint.XS);
    const isGtTablet = useBreakpoint(Breakpoint.SM);
    const iconSize = isGtMobile ? 45 : 38;

    const classes = {
        column: 'd-flex flex-column flex-lg-row',
        textAlign: 'text-center text-lg-start',
    };

    const handleRatingSelect = (rating: FeedbackRating): void => {
        if (rating === selectedRating) {
            return;
        }

        setSelectedRating(rating);

        trackUserFeedbackEvent({
            eventAction: 'Click',
            eventLabel: trackSmileysMapping[rating],
            nonInteraction: false,
            eventData: {
                eventName: GA4EventName.ClickButton,
                feature_category: GA4FeatureCategory.ProductList,
                click_element: trackSmileysMapping[rating],
            },
        });

        submitFeedbackForm({
            pageId,
            pageTitle,
            rating,
            // eslint-disable-next-line promise/prefer-await-to-callbacks
        }).catch(error =>
            errorLogger(error as Error, 'Error while submitting FeedbackForm rating.'),
        );
    };

    const handleFeedbackTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setFeedbackMessage(event.target.value);
    };

    const submitFeedback = (): void => {
        // Always show success -> errors are not important in this case
        setShowSuccessInfo(true);

        // Track sending the form
        trackUserFeedbackEvent({
            eventAction: 'ButtonClick',
            eventLabel: 'SendMessage',
            nonInteraction: false,
        });

        submitFeedbackForm({
            pageId,
            pageTitle,
            rating: selectedRating,
            message: feedbackMessage,
        })
            // eslint-disable-next-line promise/prefer-await-to-then
            .then(() => {
                trackUserFeedbackEvent({
                    eventAction: 'ShowFeedbackOption',
                    eventLabel: 'Confirmation',
                    nonInteraction: true,
                });

                return true;
            })
            // eslint-disable-next-line promise/prefer-await-to-callbacks
            .catch(error => errorLogger(error as Error, 'Error while submitting FeedbackForm.'));
    };

    useEffect(() => {
        if (selectedRating > 0 && feedbackMessage.length > 2) {
            setFormIsValid(true);
        } else {
            setFormIsValid(false);
        }
    }, [feedbackMessage, selectedRating]);

    useEffect(() => {
        trackUserFeedbackEvent({
            eventAction: 'ShowFeedbackOption',
            eventLabel: 'Static',
            nonInteraction: true,
        });
    }, []);

    useEffect(() => {
        if (showSuccessInfo && !isGtTablet && successRef.current) {
            const offset = successRef.current.getBoundingClientRect().top + window.scrollY - 120;
            window.scrollTo({
                top: offset,
                behavior: 'smooth',
            });
        }
    }, [isGtTablet, showSuccessInfo]);

    if (showSuccessInfo) {
        return (
            <div className={styles.feedbackWrapper} ref={successRef}>
                <SuccessInfo title={intl.formatMessage(messages.successTitle)} />
            </div>
        );
    }

    return (
        <div className={styles.feedbackWrapper}>
            <div className={classes.column}>
                <div
                    className={classNames(
                        styles.feedbackRow,
                        classes.textAlign,
                        'theme-text-headline d-lg-flex align-items-lg-center pr-lg-4x',
                    )}
                >
                    <FormattedMessage defaultMessage="Wie zufrieden bist du mit diesen Suchergebnissen?" />
                </div>
                <div
                    className={classNames(
                        styles.feedbackRow,
                        'd-flex justify-content-center justify-content-lg-start mt-2x mt-lg-0',
                    )}
                >
                    <Feedback
                        handleOnClick={handleRatingSelect}
                        selectedRating={selectedRating}
                        iconSize={iconSize}
                    />
                </div>
            </div>
            <div className={classNames(classes.column, 'mt-4x', 'mt-xs-6x')}>
                <div className={styles.feedbackRow}>
                    <div className={classNames(classes.textAlign, 'theme-text-headline')}>
                        <FormattedMessage defaultMessage="Können wir etwas besser machen?" />
                    </div>
                    <div className={classNames(classes.textAlign, 'mt-1x mb-2x pr-lg-4x')}>
                        <FormattedMessage defaultMessage="Gibt es zum Beispiel Filter oder etwas anderes, das du vermisst?" />
                    </div>
                </div>
                <div
                    className={classNames(
                        styles.feedbackRow,
                        'd-flex flex-column align-items-center d-lg-block pb-3x',
                    )}
                >
                    <Textarea
                        name="userFeedbackText"
                        className={styles.feedbackText}
                        placeholder={intl.formatMessage(messages.userFeedback)}
                        rows={4}
                        onChange={handleFeedbackTextChange}
                        showHelperText={false}
                    />
                </div>
            </div>
            <div className={classNames(classes.column, 'flex-lg-row-reverse')}>
                <div
                    className={classNames(
                        styles.feedbackRow,
                        'd-flex flex-column align-items-center mb-3x align-items-lg-end mb-lg-0',
                    )}
                >
                    <Button
                        type={ButtonType.Submit}
                        color={ButtonColor.Complementary}
                        className="w-100 w-xs-auto"
                        disabled={!formIsValid}
                        onClick={submitFeedback}
                    >
                        <span>
                            <FormattedMessage defaultMessage="Nachricht senden" />
                        </span>
                    </Button>
                </div>
                <div className={styles.feedbackRow}>
                    <div
                        className={classNames(
                            'theme-text-caption-text',
                            'pr-lg-4x',
                            classes.textAlign,
                        )}
                    >
                        <FormattedMessage
                            defaultMessage="Weitere Informationen dazu, wie wir deine Daten verwenden und verarbeiten, findest du in unserer {link}."
                            values={{
                                link: (
                                    <Link
                                        href={tenantConfig.urls.privacyPolicy}
                                        variant={LinkVariant.Brand}
                                        internal
                                    >
                                        <FormattedMessage defaultMessage="Datenschutzerklärung" />
                                    </Link>
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// eslint-disable-next-line import/no-default-export
export default FeedbackForm;
