import React, { useMemo, useState } from 'react';

import { HoverContext } from './mapViewProductHoverContext';

const HoverProvider = ({ children }: { readonly children: React.ReactNode }): JSX.Element => {
    const [hoveredProductIdState, setHoveredProductIdState] = useState('');

    const contextValue = useMemo(() => {
        const setHoveredProductId = (productId: string): void => {
            setHoveredProductIdState(productId);
        };

        return {
            hoveredProductIdState,
            setHoveredProductId,
        };
    }, [hoveredProductIdState]);

    return <HoverContext.Provider value={contextValue}>{children}</HoverContext.Provider>;
};

export { HoverProvider };
