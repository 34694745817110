import { type MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4ItemListName, GA4ItemListType, type Rating } from '@jsmdg/tracking';
import { Breakpoint, ButtonColor, ProductTile, useBreakpoint } from '@jsmdg/yoshi';
import { PageType } from '../../../shared/enums/pageType';
import { ProductAttribute } from '../../../shared/enums/productAttribute';
import { type ProductType as ProductTypes } from '../../../shared/enums/productType';
import { SellOption } from '../../../shared/enums/sellOption';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Participants, type Price, type Product } from '../../../shared/types/searchResponse';
import { TrackingListName } from '../../enums/trackingListName';
import { getProductTileMessages } from '../../helper/getProductTileMessages';
import { renderPrice } from '../../helper/renderPrice';
import { useOnProductTileClickAndImpression } from '../../hooks/useOnProductTileClickAndImpression';
import { useProductTileData } from '../../hooks/useProductTileData';
import { useWishListToggle } from '../../hooks/useWishListToggle';

const messages = getProductTileMessages();

type ProductTileWrapperProps = {
    readonly product: Product;
    readonly position: number;
    readonly lazyLoad: boolean;
    readonly trackingListName?: TrackingListName;
    readonly isPortraitTile?: boolean;
    readonly isListView?: boolean;
    readonly queryId?: string;
    readonly indexName?: string;
    readonly hasLocationFilter?: boolean;
    readonly closestLocationDistance?: number;
    readonly onAddToCartButtonClick?: (
        props: {
            productId: string;
            manufacturerSku: string;
            trackingPath: string[];
            price: Price;
            originalPrice?: Price;
            title: string;
            productType: ProductTypes;
            discountBadgeId?: string;
            url: string;
            rating?: Rating;
            trackingName: string;
            locationCount?: number;
            participants?: Participants;
            locationIsSelected?: boolean;
            closestLocationDistance?: number;
        },
        event: MouseEvent<HTMLButtonElement>,
    ) => Promise<void>;
};

const getTrackingListName = (listName: TrackingListName | undefined): GA4ItemListName => {
    switch (listName) {
        case TrackingListName.Bestseller:
            return GA4ItemListName.Bestseller;
        case TrackingListName.LastSeen:
            return GA4ItemListName.LastSeen;
        default:
            return GA4ItemListName.Category;
    }
};

const ProductTileWrapper = ({
    closestLocationDistance,
    hasLocationFilter,
    indexName,
    isListView,
    isPortraitTile = false,
    lazyLoad,
    onAddToCartButtonClick,
    position,
    product,
    queryId,
    trackingListName,
}: ProductTileWrapperProps): JSX.Element => {
    const intl = useIntl();

    const isDesktop = useBreakpoint(Breakpoint.XS);

    const { customerState, errorLogger, initialWishlist, isShoppingFlow } =
        useFragmentContext<FragmentContext>();

    const {
        discountBadge,
        getLocationLabel,
        getParticipantsLabel,
        hasClosestLocation,
        images,
        locale,
        onCloseServices,
        onServicesActionButtonClick,
        onToggleServices,
        originalPrice,
        pageType,
        price,
        url,
    } = useProductTileData(
        product,
        intl,
        {
            isListView,
            trackingListName,
            hasLocationFilter,
            indexName,
            queryId,
        },
        messages,
    );

    const { onProductTileClick, setImpressionTrackedElement } = useOnProductTileClickAndImpression(
        product,
        position,
        intl,
        {
            isListView,
            indexName,
            queryId,
            trackingListName,
            hasLocationFilter,
        },
    );

    const wishlistMessages = {
        a11yCloseErrorMessage: intl.formatMessage(messages.a11yCloseErrorMessage),
        tooManyWishlistArticlesError: intl.formatMessage(messages.tooManyWishlistArticlesError),
    };

    const { isOnWishlist, onWishlistButtonClick } = useWishListToggle(
        product,
        wishlistMessages,
        {
            customerState,
            errorLogger,
            initialWishlist,
        },
        getTrackingListName(trackingListName),
        isListView ? GA4ItemListType.ListView : GA4ItemListType.TileView,
        hasLocationFilter,
    );

    const a11yWishlistButton = isOnWishlist
        ? intl.formatMessage(messages.screenReaderTextForWishlistRemove)
        : intl.formatMessage(messages.screenReaderTextForWishlistAdd);

    const {
        closestLocation,
        inPreview,
        isTestProduct,
        locations,
        manufacturerSku,
        participants,
        productId,
        productType,
        rating,
        sellOption,
        services,
        title,
        trackingName,
        trackingPath,
    } = product;

    const isAddToCartButtonAvailable =
        (onAddToCartButtonClick && !inPreview && sellOption !== SellOption.TicketOnly) || null;

    const getAddToCartButtonData = () => {
        const sharedConfig = {
            isMobileOnly: true,
            isDisabled: inPreview,
            color: ButtonColor.Complementary,
        };

        if (isShoppingFlow) {
            return isAddToCartButtonAvailable
                ? {
                      label: intl.formatMessage(
                          pageType === PageType.Redemption
                              ? messages.bookAppointment
                              : messages.addToCartButtonMessage,
                      ),
                      onClick: async (event?: MouseEvent<HTMLButtonElement>): Promise<void> => {
                          await onAddToCartButtonClick?.(
                              {
                                  productId,
                                  manufacturerSku,
                                  trackingPath,
                                  price,
                                  originalPrice,
                                  title,
                                  productType,
                                  discountBadgeId: discountBadge?.id,
                                  url: product.url,
                                  trackingName,
                                  ...(locations ? { locationCount: locations?.length } : {}),
                                  ...(participants ? { participants } : {}),
                                  locationIsSelected: hasLocationFilter,
                                  closestLocationDistance,
                                  rating,
                              },
                              event as MouseEvent<HTMLButtonElement>, // event can not be undefined
                          );
                      },
                      ...sharedConfig,
                  }
                : undefined;
        }

        return {
            label: intl.formatMessage(messages.learnMore),
            onClick: async (event?: MouseEvent<HTMLButtonElement>): Promise<void> => {
                event?.preventDefault();
                window.location.href = url;
            },
            ...sharedConfig,
        };
    };

    return (
        <ProductTile
            name={title}
            price={renderPrice(price, intl)}
            originalPrice={renderPrice(originalPrice, intl)}
            images={images}
            url={url}
            locale={locale}
            closestLocation={hasClosestLocation ? closestLocation : undefined}
            locations={locations}
            attributes={[
                inPreview ? ProductAttribute.Preview : '',
                isTestProduct ? ProductAttribute.Test : '',
            ].filter(Boolean)}
            rating={rating}
            services={
                services && {
                    caption: intl.formatMessage(messages.servicesCaptionMessage),
                    body: services.body,
                    servicesActionButtonLabel:
                        isShoppingFlow || isDesktop
                            ? intl.formatMessage(messages.servicesActionButtonMessage)
                            : '',
                    onServicesActionButtonClick,
                    a11yCloseServicesLabel: intl.formatMessage(messages.a11YCloseServicesMessage),
                    onToggleServices,
                    onCloseServices,
                }
            }
            lazyLoad={lazyLoad}
            maxParticipants={participants?.max}
            isPortraitTile={isPortraitTile}
            ref={setImpressionTrackedElement}
            locationLabel={getLocationLabel()}
            participantsLabel={getParticipantsLabel()}
            a11yPdpRedirectLabel={intl.formatMessage(messages.a11YPdpRedirection)}
            onProductClick={onProductTileClick}
            addToCartButtonData={getAddToCartButtonData()}
            discountBadge={discountBadge}
            showWishlistButton
            toggleWishlist={onWishlistButtonClick}
            a11yWishlistButton={a11yWishlistButton}
            isOnWishlist={isOnWishlist}
            isListView={isListView}
        />
    );
};

export { ProductTileWrapper };
