import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    Button,
    ButtonColor,
    ButtonVariant,
    ExclamationTriangleIcon,
    InfoOutlineIcon,
    Modal,
    ModalWidthOptions,
} from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import styles from './CartErrorModal.module.scss';

type MixedShoppingCartErrorModalProps = {
    readonly isOpen: boolean;
    readonly onRequestClose: () => void;
    readonly onGoToCart: () => void;
};

const messages = defineMessages({
    headline: {
        defaultMessage: 'Es tut uns leid!',
    },
    a11yCloseText: {
        defaultMessage: 'Modal schließen',
    },
});
const MixedShoppingCartErrorModal = ({
    isOpen,
    onGoToCart,
    onRequestClose,
}: MixedShoppingCartErrorModalProps): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<FragmentContext>();

    return (
        <Modal
            headline={intl.formatMessage(messages.headline)}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            width={ModalWidthOptions.Narrow}
            a11yCloseText={intl.formatMessage(messages.a11yCloseText)}
        >
            <div
                className={classNames(
                    'd-flex',
                    'align-items-center',
                    'mt-3x',
                    'mb-2x',
                    'pb-1x',
                    styles.alertTitle,
                )}
            >
                <ExclamationTriangleIcon
                    className={classNames('mr-1x', 'flex-shrink-0')}
                    dataTestId="errorIcon"
                />
                <FormattedMessage defaultMessage="Hier haben wir eine Baustelle." />
            </div>
            <div className="mb-2x">
                <FormattedMessage defaultMessage="Die Lieferwege der beiden Produkte sind unterschiedlich. Daher kann man sie nicht zusammen kaufen." />
            </div>
            <div className="mb-3x">
                <FormattedMessage defaultMessage="Bitte führe den Kauf einzeln durch." />
            </div>
            <div className={classNames('d-flex', 'justify-content-between', 'flex-wrap', 'mb-3x')}>
                <Button
                    className={classNames(styles.modalButton, 'mb-2x', 'mb-sm-0')}
                    variant={ButtonVariant.Outlined}
                    color={ButtonColor.Complementary}
                    onClick={onRequestClose}
                >
                    <FormattedMessage defaultMessage="Weiter Shoppen" />
                </Button>
                <Button
                    className={styles.modalButton}
                    color={ButtonColor.Complementary}
                    onClick={onGoToCart}
                >
                    <FormattedMessage defaultMessage="Zum Warenkorb" />
                </Button>
            </div>
            <div className={classNames('d-flex')}>
                <InfoOutlineIcon
                    className={classNames('mr-1x', 'flex-shrink-0')}
                    dataTestId="infoIcon"
                />
                <FormattedMessage
                    defaultMessage="Bei Rückfragen kontaktiere unsere Hotline: {lineBreak} {telephoneNumber} {lineBreak}Mo-Fr 8-20 Uhr {lineBreak}Sa 10-16 Uhr"
                    values={{
                        lineBreak: <br />,
                        telephoneNumber: tenantConfig.contactInfo.phone.label,
                    }}
                />
            </div>
        </Modal>
    );
};

export { MixedShoppingCartErrorModal };
