import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { type GA4FilterListType } from '@jsmdg/tracking';
import { type MultiSelectOption } from '@jsmdg/yoshi/dist/types/selectOption';
import { AttributeName, PARTICIPANTS_THRESHOLD } from '../../../../shared/enums/attributeName';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type FacetValue } from '../../../../shared/types/facetValue';
import { type Filter, type RangeFilter } from '../../../../shared/types/search';
import { getAdjustedItemCountByLimit } from '../../../helper/getAdjustedItemCountByLimit';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { MultiSelectDesktop } from '../MultiSelect/MultiSelectDesktop/MultiSelectDesktop';
import { MultiSelectMobile } from '../MultiSelect/MultiSelectMobile/MultiSelectMobile';

const messages = defineMessages({
    label: { defaultMessage: 'Anzahl der Teilnehmer' },
    oneParticipant: { defaultMessage: 'Für eine Person' },
    twoParticipants: { defaultMessage: 'Für zwei Personen' },
    moreThanTwoParticipants: { defaultMessage: 'Für Gruppen' },
});

const valueTranslationMapping: Record<string, { defaultMessage: string }> = {
    '1': messages.oneParticipant,
    '2': messages.twoParticipants,
};

type ParticipantsFilterProps = {
    readonly facet: FacetFilter;
    readonly selectedOptions?: RangeFilter[];
    readonly isMobileFilter?: boolean;
    readonly paginationLimit?: number;
    readonly onSubmit: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly filter: Filter;
    readonly listType?: GA4FilterListType;
};

const ParticipantsFilter = ({
    facet,
    filter,
    isMobileFilter,
    listType,
    onSubmit,
    paginationLimit,
    selectedOptions,
}: ParticipantsFilterProps): JSX.Element => {
    const intl = useIntl();
    const { attribute, facetStats, values } = facet;

    const getRangeValue = useCallback(
        (value: number): string => {
            if (value <= PARTICIPANTS_THRESHOLD || !facetStats) {
                return `${value}-${value}`;
            }

            return `${PARTICIPANTS_THRESHOLD + 1}-${facetStats.max}`;
        },
        [facetStats],
    );

    const transformSelectedOption = useCallback(
        (selectedOption: RangeFilter): string => getRangeValue(selectedOption.min || 0),
        [getRangeValue],
    );
    const transformedSelectedOptions = selectedOptions?.map(transformSelectedOption);

    const options = useMemo(() => {
        const isOptionSelected = (value: string): boolean => {
            return (
                selectedOptions?.some(option => transformSelectedOption(option) === value) || false
            );
        };

        const getSelectedOptionLabel = (selectedOption: RangeFilter): string => {
            if (
                selectedOption.min &&
                selectedOption.min === selectedOption.max &&
                valueTranslationMapping[`${selectedOption.min}`] !== undefined
            ) {
                return intl.formatMessage(valueTranslationMapping[`${selectedOption.min}`]);
            }

            return intl.formatMessage(messages.moreThanTwoParticipants);
        };

        const multiSelectOptions: MultiSelectOption[] = values
            .sort((a, b) => Number(a.name) - Number(b.name))
            .reduce(
                (
                    preparedOptions: MultiSelectOption[],
                    facetValue: FacetValue,
                    currentIndex: number,
                    allValues: FacetValue[],
                ) => {
                    const numericValue = Number.parseInt(facetValue.name, 10);
                    const rangeValue = getRangeValue(numericValue);
                    const isSelected = isOptionSelected(rangeValue);
                    const numProducts = getAdjustedItemCountByLimit(
                        facetValue.count,
                        paginationLimit,
                    );

                    if (numericValue <= PARTICIPANTS_THRESHOLD) {
                        return [
                            ...preparedOptions,
                            {
                                label: intl.formatMessage(valueTranslationMapping[facetValue.name]),
                                secondaryText: numProducts ? numProducts.toString() : undefined,
                                value: rangeValue,
                                isSelected,
                            },
                        ];
                    }

                    const participantsGreaterThanThreshold = preparedOptions.findIndex(
                        option => option.value === rangeValue,
                    );

                    if (participantsGreaterThanThreshold > -1) {
                        return preparedOptions;
                    }

                    const participantCountsGreaterThanThreshold = allValues
                        .slice(currentIndex, allValues.length)
                        .map(value => getAdjustedItemCountByLimit(value.count, paginationLimit));

                    const maximumCountInResults = Math.max(
                        ...participantCountsGreaterThanThreshold,
                    );

                    return [
                        ...preparedOptions,
                        {
                            label: intl.formatMessage(messages.moreThanTwoParticipants),
                            value: rangeValue,
                            secondaryText:
                                participantCountsGreaterThanThreshold.length <= 1 ||
                                paginationLimit === maximumCountInResults
                                    ? maximumCountInResults.toString()
                                    : `${maximumCountInResults}+`,
                            isSelected,
                        },
                    ];
                },
                [],
            );

        selectedOptions?.forEach(selectedOption => {
            const transformedSelectedOption = transformSelectedOption(selectedOption);
            const indexOfSelectedOption = values.findIndex(
                facetValue =>
                    getRangeValue(Number.parseInt(facetValue.name, 10)) ===
                    transformedSelectedOption,
            );

            if (indexOfSelectedOption === -1) {
                multiSelectOptions.push({
                    label: getSelectedOptionLabel(selectedOption),
                    value: transformedSelectedOption,
                    isSelected: true,
                });
            }
        });

        return multiSelectOptions;
    }, [getRangeValue, intl, paginationLimit, selectedOptions, transformSelectedOption, values]);

    const handleOnSubmit = (submittedOptions: string[]): void => {
        const submittedOptionsValues = submittedOptions.map(option => {
            const [min, max] = option.split('-').map(Number);

            return {
                min,
                max: max ?? min,
            };
        });

        onSubmit(
            SearchReducerActionType.Filter,
            submittedOptionsValues.length ? submittedOptionsValues : null,
            attribute,
        );
    };

    if (isMobileFilter) {
        return (
            <MultiSelectMobile
                options={options}
                onSubmit={handleOnSubmit}
                label={intl.formatMessage(messages.label)}
                selectedOptions={transformedSelectedOptions}
                filter={filter}
                attribute={AttributeName.Participants}
                listType={listType}
            />
        );
    }

    return (
        <MultiSelectDesktop
            label={intl.formatMessage(messages.label)}
            options={options}
            onSubmit={handleOnSubmit}
            selectedOptions={transformedSelectedOptions}
            filter={filter}
            attribute={AttributeName.Participants}
            listType={listType}
        />
    );
};

export { ParticipantsFilter };
