import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { CloseIcon, Input, MapMarkerIcon, PureButton } from '@jsmdg/yoshi';
import { type Filter } from '../../../../../../shared/types/search';
import { useLocationFilter } from '../../../../../hooks';
import { type SearchReducerActionType } from '../../../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../../../types/searchReducer';
import { SuggestionList } from '../../../../SuggestionList';
import { CurrentLocationButton } from './CurrentLocationButton';
import styles from './LocationFilter.module.scss';

type LocationFilterProps = {
    readonly filter?: Filter;
    readonly locationName?: string;
    readonly shouldReset?: false;
    readonly err?: GeolocationPositionError;
    readonly onSubmit: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly scrollToElement: () => void;
};

const LocationFilter = ({
    err,
    filter,
    locationName = '',
    onSubmit,
    scrollToElement,
    shouldReset,
}: LocationFilterProps): JSX.Element => {
    const [isErrorTooltipVisible, setIsErrorTooltipVisible] = useState(false);
    const {
        errorMessage,
        filterRef,
        formatMessage,
        handleKeyDown,
        isActive,
        location,
        locationSuggestions,
        messages,
        onClearLocation,
        onLocationChange,
        onSuggestionClick,
        selected,
        setIsActive,
        setSelected,
    } = useLocationFilter({
        filter,
        locationName,
        onSubmit,
        shouldReset,
        err,
    });

    useEffect(() => {
        if (errorMessage) {
            setIsErrorTooltipVisible(true);
        }
    }, [errorMessage]);

    return (
        <form
            // autoComplete with "off" in the form tag is for firefox to stop it from autofill the inputs
            // the input contains another autoComplete attribute for chrome with an other value (see yoshi)
            autoComplete="off"
            className="position-relative"
            onSubmit={() => null}
            ref={filterRef}
        >
            <div
                className={classNames('d-flex flex-sm-column w-100 p-0', styles.wrapper, {
                    [styles.isActive]: isActive || location,
                })}
            >
                <div className="d-flex align-items-start">
                    <MapMarkerIcon className={classNames(styles.icon, 'ml-1x mr-0-5x')} />
                    <p className={classNames(styles.label, 'd-none d-sm-block fw-semibold')}>
                        {formatMessage(messages.placeholderShort)}
                    </p>
                </div>
                <Input
                    className={styles.inputWrapper}
                    inputClasses={classNames(
                        styles.input,
                        'p-0 pl-0-5x pl-sm-2x pr-5x my-0-5x mr-0-5x m-sm-0',
                    )}
                    name="locationSearch"
                    onClick={() => setIsActive(!isActive)}
                    onInput={onLocationChange}
                    onKeyDown={handleKeyDown}
                    onFocus={scrollToElement}
                    placeholder={formatMessage(messages.allLocationsPlaceholder)}
                    hideLabel
                    value={location}
                    rightComponent={
                        <>
                            {location && (
                                <PureButton
                                    aria-label="clear location"
                                    className={styles.clearButton}
                                    onClick={onClearLocation}
                                >
                                    <CloseIcon />
                                </PureButton>
                            )}
                            {!errorMessage && (
                                <CurrentLocationButton location={location} onSubmit={onSubmit} />
                            )}
                        </>
                    }
                />
            </div>

            {isErrorTooltipVisible ? (
                <div className={classNames(styles.locationErrorTooltip, 'position-absolute')}>
                    <PureButton
                        onClick={() => setIsErrorTooltipVisible(false)}
                        className={classNames(styles.tooltipButton, 'position-absolute p-0 m-0')}
                    >
                        <CloseIcon />
                    </PureButton>
                    <p>{errorMessage}</p>
                </div>
            ) : null}
            {locationSuggestions && isActive && (
                <SuggestionList
                    selected={selected}
                    setSelected={setSelected}
                    suggestions={locationSuggestions}
                    suggestionClick={onSuggestionClick}
                    className="w-100"
                />
            )}
        </form>
    );
};

export { LocationFilter };
