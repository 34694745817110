import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import loadable from '@loadable/component';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    GA4EventName,
    GA4FilterListType,
    pushToDataLayer,
    trackFilterInteraction,
} from '@jsmdg/tracking';
import { Badge, Button, ButtonSize, FilterIcon, type GridView } from '@jsmdg/yoshi';
import { type FacetFilter } from '../../../shared/types/facetFilter';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import {
    type Filter as FilterType,
    type Sorting as SortingType,
} from '../../../shared/types/search';
import { getActiveFilters } from '../../hooks/getActiveFilters';
import { SearchReducerActionType } from '../../reducers/searchReducer';
import { type InitialPageFilterType } from '../../types';
import { type SearchReducerValue } from '../../types/searchReducer';
import { MapContainer } from '../MapContainer/MapContainer';
import { LocationFilter } from './Location';
import { PriceFilter } from './Price';
import { ProductFacets } from './ProductFacets';
import { Sorting } from './Sorting';
import { useActiveFiltersCount } from './useActiveFiltersCount';
import { hasInitialLocation, hasInitialPrice } from './utils';
import styles from './Filter.module.scss';

const FilterSidePanel = loadable(async () => import('./FilterSidePanel/FilterSidePanel'));

type FilterProps = {
    readonly filter: FilterType;
    readonly sorting?: SortingType;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly itemCount: number;
    readonly onFilterChange: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly onSortingChange: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly hasFilterChanged: boolean;
    readonly showLocationFilter: boolean;
    readonly locationFilterIsActive: boolean;
    readonly geoLocationError?: GeolocationPositionError;
    readonly onReset: () => void;
    readonly facets?: FacetFilter[];
    readonly paginationLimit?: number;
    readonly gridView: GridView;
};

const Filter = ({
    facets,
    filter,
    geoLocationError,
    gridView,
    hasFilterChanged,
    initialPageFilter,
    itemCount,
    locationFilterIsActive,
    onFilterChange,
    onReset,
    onSortingChange,
    paginationLimit,
    showLocationFilter,
    sorting,
}: FilterProps): JSX.Element => {
    const { locale, tenantConfig } = useFragmentContext<FragmentContext>();
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [shouldReset, setShouldReset] = useState(false);

    const isInitialLocation = hasInitialLocation(filter, initialPageFilter);
    const isInitialPrice = hasInitialPrice(filter, initialPageFilter);

    const activeFiltersCount = useActiveFiltersCount(filter, isInitialPrice, isInitialLocation);
    const listType = GA4FilterListType[gridView];

    useEffect(() => {
        const activeFilters = getActiveFilters(filter);
        pushToDataLayer({ active_filter_types: activeFilters });
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        setShouldReset(false);
    }, [filter]);

    const openFilterPanel = async (): Promise<void> => {
        await window.yieldToMainThread();
        trackFilterInteraction('Open', undefined, {
            eventName: GA4EventName.ClickButton,
            click_element: 'Filterbox open',
            click_text: 'Filter',
            list_type: listType,
        });
        setIsSidePanelOpen(true);
    };

    const closeFilterPanel = (): void => {
        trackFilterInteraction('Close', undefined, {
            eventName: GA4EventName.ClickButton,
            click_element: 'Filterbox close',
            click_text: 'X',
            list_type: listType,
        });
        setIsSidePanelOpen(false);
        onFilterChange(SearchReducerActionType.ClearError);
    };

    const resetFilter = (): void => {
        onReset();
        setShouldReset(true);
        setIsSidePanelOpen(false);
        trackFilterInteraction(
            'Reset',
            undefined,
            {
                eventName: GA4EventName.ResetFilter,
                filter_type: 'Reset all',
                list_type: listType,
            },
            '',
        );
    };

    const activeFiltersCounter = activeFiltersCount > 0 && (
        <Badge className={classNames('ml-1x', styles.filterCounter)}>{activeFiltersCount}</Badge>
    );

    return (
        <div className="mb-sm-3x">
            <div className="d-none d-sm-flex mb-3x position-relative" data-testid="desktop-filter">
                {showLocationFilter && (
                    <div className={classNames(styles.locationFilter, 'd-flex p-1-5x mr-1-5x')}>
                        {showLocationFilter && (
                            <LocationFilter
                                filter={filter}
                                locationName={filter.location?.name}
                                onSubmit={onFilterChange}
                                geoLocationError={geoLocationError}
                                hasResult={itemCount > 0}
                                listType={listType}
                                isDesktop
                            />
                        )}
                    </div>
                )}
                <div className="d-none d-sm-none ml-auto mr-0">
                    <Sorting
                        selectedSorting={sorting}
                        onSubmit={onSortingChange}
                        includeDistanceSorting={locationFilterIsActive}
                        targetGroup={filter.targetGroup?.name}
                        isDesktop
                        listType={listType}
                    />
                </div>

                {showLocationFilter && (
                    <div className={classNames(styles.mapContainer, 'd-none d-sm-block')}>
                        <MapContainer />
                    </div>
                )}
            </div>
            <div className="d-none d-sm-flex flex-wrap">
                <PriceFilter
                    values={[filter.price?.min || null, filter.price?.max || null]}
                    onSubmit={onFilterChange}
                    currencyCode={tenantConfig.currency.code}
                    filter={filter}
                />
                {!!facets?.length && (
                    <ProductFacets
                        onFilterChange={onFilterChange}
                        facets={facets}
                        filter={filter}
                        paginationLimit={paginationLimit}
                    />
                )}
            </div>
            <div data-testid="mobile-filter">
                <Button
                    className={classNames(
                        styles.filterToggleButton,
                        'd-sm-none d-flex align-items-center justify-content-center',
                    )}
                    onClick={openFilterPanel}
                    iconLeft={<FilterIcon className={styles.icon} />}
                    size={ButtonSize.Small}
                >
                    <FormattedMessage defaultMessage="Filter" />
                    {activeFiltersCounter}
                </Button>
                <FilterSidePanel
                    filter={filter}
                    isSidePanelOpen={isSidePanelOpen}
                    closeFilterPanel={closeFilterPanel}
                    onFilterChange={onFilterChange}
                    isInitialLocation={isInitialLocation}
                    isInitialPrice={isInitialPrice}
                    resetFilter={resetFilter}
                    showLocationFilter={showLocationFilter}
                    shouldReset={shouldReset}
                    nbItems={itemCount}
                    locale={locale}
                    sorting={sorting}
                    hasFilterChanged={hasFilterChanged}
                    facets={facets}
                    paginationLimit={paginationLimit}
                    listType={listType}
                />
            </div>
        </div>
    );
};

export { Filter };
