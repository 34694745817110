import { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { CheckboxGroup, DropdownAlignment, FilterDropdownBase } from '@jsmdg/yoshi';
import { type MultiSelectOption } from '@jsmdg/yoshi/dist/types/selectOption';
import { type FragmentContext } from '../../../../../shared/types/fragmentContext';
import { type Filter } from '../../../../../shared/types/search';
import { getGridView } from '../../../../helper/getGridView';
import { getActiveFilters } from '../../../../hooks/getActiveFilters';

const messages = defineMessages({
    submitLabel: {
        defaultMessage: 'Fertig',
    },
    resetLabel: {
        defaultMessage: 'Zurücksetzen',
    },
});

type MultiSelectDesktopProps = {
    readonly label: string;
    readonly options: MultiSelectOption[];
    readonly onSubmit: (value: string[]) => void;
    readonly filter: Filter;
    readonly selectedOptions?: string[];
    readonly attribute: string;
    readonly listType?: GA4FilterListType;
};

const MultiSelectDesktop = ({
    attribute,
    filter,
    label,
    listType,
    onSubmit,
    options,
    selectedOptions,
}: MultiSelectDesktopProps): JSX.Element => {
    const intl = useIntl();
    const { settingCookie } = useFragmentContext<FragmentContext>();
    const [selection, setSelection] = useState<string[]>(selectedOptions || []);
    const selectedOptionsRef = useRef(selectedOptions);
    const gridView = getGridView(settingCookie);

    const trackOptionSelection = (addedSelections: string[], removedSelections: string[]): void => {
        addedSelections.forEach(selectionLabel => {
            trackFilterInteraction(`Select_${label}`, selectionLabel, {
                eventName: GA4EventName.SetFilter,
                filter_type: label,
                filter_value: selectionLabel,
                list_type: listType ?? GA4FilterListType[gridView],
            });
        });

        removedSelections.forEach(selectionLabel => {
            trackFilterInteraction('Deselect', selectionLabel, {
                eventName: GA4EventName.DeselectFilter,
                filter_type: label,
                filter_value: selectionLabel,
                list_type: listType ?? GA4FilterListType[gridView],
            });
        });
    };

    const handleOnSelect = (value: string): void => {
        const isDeselectAction = selection.includes(value);
        const updatedSelection = isDeselectAction
            ? selection.filter((_value: string): boolean => _value !== value)
            : selection.concat([value]);
        setSelection(updatedSelection);
    };

    const handleOnSubmit = (): void => {
        const selectedOptionLabels = options
            .filter(option => selection.includes(option.value))
            .map(option => option.label);

        const removedSelections =
            selectedOptions?.filter(option => !selection.includes(option)) ?? [];

        const removedSelectionLabels = options
            .filter(option => removedSelections.includes(option.value))
            .map(option => option.label);

        onSubmit(selection);

        let activeFilters = getActiveFilters(filter, [attribute]);

        if (selectedOptionLabels.length === 0) {
            activeFilters = activeFilters
                .split(', ')
                .filter(activeFilter => !activeFilter.includes(attribute))
                .join(', ');
        }

        trackOptionSelection(selectedOptionLabels, removedSelectionLabels);

        trackFilterInteraction(
            `Set_${label}`,
            selectedOptionLabels.join('_'),
            {
                eventName: GA4EventName.ClickButton,
                click_element: `Set Filter ${label}`,
                click_text: intl.formatMessage(messages.submitLabel),
                filter_type: label,
                list_type: listType ?? GA4FilterListType[gridView],
            },
            activeFilters,
        );
    };

    const handleOnClickOutside = (): void => {
        if (!isEqual(selection, selectedOptions)) handleOnSubmit();
    };

    const handleOnReset = (): void => {
        const activeFilters = getActiveFilters(filter);
        setSelection([]);

        if (selectedOptions?.length) {
            onSubmit([]);
        }

        if (activeFilters.includes(attribute)) {
            const updatedActiveFilters = activeFilters
                .split(', ')
                .filter(activeFilter => !activeFilter.includes(attribute))
                .join(', ');

            trackFilterInteraction(
                `Reset_${label}`,
                undefined,
                {
                    eventName: GA4EventName.ResetFilter,
                    filter_type: label,
                    list_type: listType ?? GA4FilterListType[gridView],
                },
                updatedActiveFilters,
            );
        }
    };

    const handleOnOpen = (): void => {
        trackFilterInteraction(`Expand_${label}`, undefined, {
            eventName: GA4EventName.ExpandFilter,
            filter_type: label,
            list_type: listType ?? GA4FilterListType[gridView],
        });
    };

    useEffect(() => {
        if (selectedOptionsRef.current) {
            const parentDidUpdate = !isEqual(selectedOptions, selectedOptionsRef.current);
            const selectionMatch = isEqual(selectedOptions, selection);

            if (parentDidUpdate && !selectionMatch) {
                setSelection(selectedOptions || []);
                onSubmit(selectedOptions || []);
            }
        }

        selectedOptionsRef.current = selectedOptions;
    }, [onSubmit, selectedOptions, selectedOptionsRef, selection]);

    return (
        <FilterDropdownBase
            label={label}
            resetLabel={intl.formatMessage(messages.resetLabel)}
            submitLabel={intl.formatMessage(messages.submitLabel)}
            numSelected={selectedOptions?.length}
            showNumSelected
            onSubmit={handleOnSubmit}
            onOpen={handleOnOpen}
            onReset={handleOnReset}
            onClickOutside={handleOnClickOutside}
            hasChanged={!isEqual(selection, selectedOptions)}
            alignment={DropdownAlignment.Auto}
            className="mr-1x mb-1x"
            dataTestId={`attribute_${label}`}
        >
            <CheckboxGroup
                options={options.map(option => ({
                    ...option,
                    isSelected: selection.includes(option.value),
                }))}
                onSelect={handleOnSelect}
            />
        </FilterDropdownBase>
    );
};

export { MultiSelectDesktop };
