import { type PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { InstantSearch, InstantSearchSSRProvider, useInstantSearch } from 'react-instantsearch';
import { type LiteClient } from 'algoliasearch/lite';
import { type InstantSearchStatus } from 'instantsearch.js/es/lib/InstantSearch';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { SearchStatus } from '../../../shared/enums/searchStatus';
import { createAlgoliaSearchContext } from '../../../shared/helpers/createAlgoliaSearchContext';
import { type AlgoliaSearchContext } from '../../../shared/types/algoliaSearchContext';
import { type FragmentContext } from '../../../shared/types/fragmentContext';

type PageWrapperProps = PropsWithChildren<{
    readonly client: LiteClient;
}>;

const formatAlgoliaStatus = (status: InstantSearchStatus): SearchStatus => {
    if (status === 'error') {
        return SearchStatus.Rejected;
    }

    return status === 'loading' || status === 'stalled'
        ? SearchStatus.Pending
        : SearchStatus.Resolved;
};

const WrapContext = ({ children }: PropsWithChildren): JSX.Element => {
    const { error, status: algoliaStatus } = useInstantSearch();
    const [status, setStatus] = useState(SearchStatus.Idle);
    const statusRef = useRef(algoliaStatus);

    useEffect(() => {
        if (statusRef.current !== algoliaStatus) {
            statusRef.current = algoliaStatus;
            setStatus(formatAlgoliaStatus(algoliaStatus));
        }
    }, [algoliaStatus]);

    const contextValue: AlgoliaSearchContext = useMemo(
        () => ({
            error,
            status,
        }),
        [error, status],
    );

    return (
        <createAlgoliaSearchContext.Provider value={contextValue}>
            {children}
        </createAlgoliaSearchContext.Provider>
    );
};

const PageWrapper = ({ children, client }: PageWrapperProps): JSX.Element => {
    const { algoliaConfig } = useFragmentContext<FragmentContext>();

    return (
        <InstantSearchSSRProvider {...algoliaConfig.serverState}>
            <InstantSearch searchClient={client} future={{ preserveSharedStateOnUnmount: true }}>
                <WrapContext>{children}</WrapContext>
            </InstantSearch>
        </InstantSearchSSRProvider>
    );
};

export { PageWrapper };
