import { Image, ResponsiveFrame } from '@jsmdg/yoshi';

type HeroImageProps = {
    readonly alt: string;
    readonly lazyLoad: boolean;
    readonly src: string;
    readonly srcset: string;
    readonly sizes: string;
    readonly height: number;
    readonly width: number;
};

const HeroImage = ({
    alt,
    height,
    lazyLoad,
    sizes,
    src,
    srcset,
    width,
}: HeroImageProps): JSX.Element => {
    return (
        <ResponsiveFrame height={height} width={width}>
            <Image
                alt={alt}
                src={src}
                srcSet={srcset}
                sizes={sizes}
                height={height}
                width={width}
                lazyLoad={lazyLoad}
            />
        </ResponsiveFrame>
    );
};

export { HeroImage };
