import Axios, { type AxiosResponse } from 'axios';

import { type DiscountCodeStates } from '../../shared/enums/discountCodeStates';

const checkDiscountCode = async (
    couponCode: string,
): Promise<AxiosResponse<DiscountCodeStates>> => {
    const path = '/api/discount/check';
    return Axios.post(path, { couponCode });
};

export { checkDiscountCode };
