import { TargetGroup } from './targetGroup';

export enum SortingField {
    SalesRank = 'salesRank',
    TargetGroupMen = 'targetGroupMen',
    TargetGroupWomen = 'targetGroupWomen',
    TargetGroupCouples = 'targetGroupCouples',
    Price = 'price',
    Distance = 'distance',
    Rating = 'rating',
}

export enum SortingOrder {
    Asc = 'asc',
    Desc = 'desc',
}

export enum SortingUrlParameterFieldOption {
    Popular = 'popular',
    Distance = 'distance',
    Price = 'price',
    Rating = 'rating',
}

export const targetGroupSortingMap: Record<TargetGroup, SortingField> = {
    [TargetGroup.Male]: SortingField.TargetGroupMen,
    [TargetGroup.Female]: SortingField.TargetGroupWomen,
    [TargetGroup.Couple]: SortingField.TargetGroupCouples,
};

export const SortingFieldOptionsToUrlParameterMap = {
    [SortingField.SalesRank]: SortingUrlParameterFieldOption.Popular,
    [SortingField.TargetGroupMen]: SortingUrlParameterFieldOption.Popular,
    [SortingField.TargetGroupWomen]: SortingUrlParameterFieldOption.Popular,
    [SortingField.TargetGroupCouples]: SortingUrlParameterFieldOption.Popular,
    [SortingField.Distance]: SortingUrlParameterFieldOption.Distance,
    [SortingField.Price]: SortingUrlParameterFieldOption.Price,
    [SortingField.Rating]: SortingUrlParameterFieldOption.Rating,
};

export const SortingFieldOptionsFromUrlParameterMap = {
    [SortingUrlParameterFieldOption.Popular]: SortingField.SalesRank,
    [SortingUrlParameterFieldOption.Distance]: SortingField.Distance,
    [SortingUrlParameterFieldOption.Price]: SortingField.Price,
    [SortingUrlParameterFieldOption.Rating]: SortingField.Rating,
};

export const MostPopularSortingFields = [
    SortingField.SalesRank,
    SortingField.TargetGroupMen,
    SortingField.TargetGroupWomen,
    SortingField.TargetGroupCouples,
];
