import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Breakpoint, CloseIcon, NavigationIcon, PureButton, useBreakpoint } from '@jsmdg/yoshi';
import { storageAvailable } from '../../../helper/storageHelper';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import styles from './CurrentLocationButton.module.scss';

type CurrentLocationButtonProps = {
    readonly location: string;
    readonly hasResult: boolean;
    readonly isActive: boolean;
    readonly geoLocationError?: GeolocationPositionError;
    readonly onSubmit: (arg: SearchReducerActionType) => void;
    readonly onCloseCurrentLocation: () => void;
};

const CurrentLocationButton = ({
    geoLocationError,
    hasResult,
    isActive,
    location,
    onCloseCurrentLocation,
    onSubmit,
}: CurrentLocationButtonProps): JSX.Element | null => {
    const isDesktop = useBreakpoint(Breakpoint.SM);
    const getCurrentLocation = (): void => {
        if (geoLocationError) {
            return;
        }

        onSubmit(SearchReducerActionType.GeoCoordinates);
    };

    const showLocationPopup = (): boolean => {
        // do not show if user has denied or location is set
        if (
            !hasResult ||
            (typeof window !== 'undefined' &&
                geoLocationError?.code !== window.GeolocationPositionError?.PERMISSION_DENIED &&
                storageAvailable('sessionStorage') &&
                window.sessionStorage.getItem('locationApiAccepted') === 'false') ||
            location
        ) {
            return false;
        }

        return true;
    };

    if (showLocationPopup() && (!isDesktop || isActive)) {
        return (
            <div
                className={classNames(
                    styles.locationButton,
                    {
                        [styles.error]: !!geoLocationError,
                    },
                    'd-flex justify-content-end justify-content-sm-between py-sm-1x px-sm-1-5x',
                )}
            >
                <PureButton onClick={getCurrentLocation} className={styles.button}>
                    <NavigationIcon className="mr-0-5x" />
                    <FormattedMessage defaultMessage="Aktueller Standort" />
                </PureButton>
                <PureButton
                    aria-label="close current location"
                    onClick={onCloseCurrentLocation}
                    className={styles.close}
                >
                    <CloseIcon />
                </PureButton>
            </div>
        );
    }

    return null;
};

export { CurrentLocationButton };
