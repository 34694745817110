import { type LoggerInterface } from '@jsmdg/logger';
import { logToSentry } from '@jsmdg/react-fragment-scripts/fragment';

export const createErrorLogger =
    (logger?: LoggerInterface): ((err: Error, message?: string) => void) =>
    (err: Error, message = 'PLP encountered an error') => {
        if (logger?.withException) {
            logger.withException(err).warn(message);
            return;
        }

        const sentryError = new Error(`${message}: ${err.message}`);
        sentryError.stack = err.stack;

        logToSentry(sentryError);
    };
