export enum DurationOption {
    LessHalfHour = '0_1800',
    LessHour = '1800_3600',
    LessTwoHours = '3600_7200',
    LessThreeHours = '7200_10800',
    LessFourHours = '10800_14400',
    LessFiveHours = '14400_18000',
    LessSixHours = '18000_21600',
    LessSevenHours = '21600_25200',
    MoreSevenHours = '25200_0',
}
