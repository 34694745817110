import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { ExclamationTriangleIcon, Modal, ModalWidthOptions } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import styles from './CartErrorModal.module.scss';

type AddToCartErrorModalProps = {
    readonly isOpen: boolean;
    readonly onRequestClose: () => void;
};

const messages = defineMessages({
    headline: {
        defaultMessage: 'Es tut uns leid!',
    },
    a11yCloseText: {
        defaultMessage: 'Modal schließen',
    },
});

const AddToCartErrorModal = ({ isOpen, onRequestClose }: AddToCartErrorModalProps): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<FragmentContext>();

    return (
        <Modal
            headline={intl.formatMessage(messages.headline)}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            width={ModalWidthOptions.Narrow}
            a11yCloseText={intl.formatMessage(messages.a11yCloseText)}
        >
            <div
                className={classNames(
                    'd-flex',
                    'align-items-center',
                    'mt-3x',
                    'mb-2x',
                    'pb-1x',
                    styles.alertTitle,
                )}
            >
                <ExclamationTriangleIcon
                    className={classNames('mr-1x', 'flex-shrink-0')}
                    dataTestId="errorIcon"
                />
                <FormattedMessage defaultMessage="Ein technischer Fehler ist aufgetreten." />
            </div>

            <div className="mb-2x">
                <FormattedMessage
                    defaultMessage="Das Produkt kann nicht zum Warenkorb hinzugefügt werden. {lineBreak}Bitte versuche es erneut."
                    values={{
                        lineBreak: <br />,
                    }}
                />
            </div>
            <div className="theme-text-body-highlight">
                <FormattedMessage
                    defaultMessage="Oder bestelle über unsere Hotline: {lineBreak}{telephoneNumber} {lineBreak}Mo-Fr 8-20 Uhr {lineBreak}Sa 10-16 Uhr"
                    values={{
                        lineBreak: <br />,
                        telephoneNumber: tenantConfig.contactInfo.phone.label,
                    }}
                />
            </div>
        </Modal>
    );
};

export { AddToCartErrorModal };
