/* eslint-disable complexity*/
import { SortingFieldOptionsToUrlParameterMap } from '../../shared/enums/sorting';
import { type Search } from '../../shared/types/search';
import { type InitialPageFilterType } from '../types';
import { type UrlParameter } from '../types/urlParameterType';
import { isStringArray } from './arrayUtils';

type ToPageUrlParams = {
    search: Search;
    path: string;
    initialPageFilter?: InitialPageFilterType;
    currentPage: number;
    targetPage: number;
    totalPages: number;
    offset?: number;
};

export function blacklistSearchFilteringParams(
    searchParams: URLSearchParams,
    shouldBlacklistLocation: boolean,
): URLSearchParams {
    const filteringParams = [
        'q',
        'priceMin',
        'priceMax',
        'duration',
        'sortBy',
        'page',
        'offset',
        'includeTestProducts',
        'productAttributes',
        'lat1',
        'lng1',
        'lat2',
        'lng2',
        ...(shouldBlacklistLocation ? ['lat', 'long', 'locationName', 'distance'] : []),
    ];

    filteringParams.forEach(filteringParam => {
        searchParams.delete(filteringParam);
    });

    return searchParams;
}

export const toUrlSearchParameters = (
    search: Search,
    initialPageFilter?: InitialPageFilterType,
): string => {
    const { searchTerm, filter, sorting, pagination } = search;
    const filterHasLocation = !!filter?.location;

    const urlParameter: UrlParameter = {
        ...Object.fromEntries(
            blacklistSearchFilteringParams(
                new URLSearchParams(typeof window === 'undefined' ? '' : window.location.search),
                !filterHasLocation,
            ),
        ),
    };

    if (searchTerm) {
        urlParameter.q = searchTerm;
    }

    if (filter?.price?.min) {
        urlParameter.priceMin = filter.price.min;
    }

    if (filter?.price?.max) {
        urlParameter.priceMax = filter.price.max;
    }

    if (filter?.boundary) {
        urlParameter.lat1 = filter.boundary.lat1;
        urlParameter.lng1 = filter.boundary.lng1;
        urlParameter.lat2 = filter.boundary.lat2;
        urlParameter.lng2 = filter.boundary.lng2;
    }

    if (pagination && pagination.currentPage > 1) {
        urlParameter.page = pagination.currentPage;
        if (pagination.offset) {
            urlParameter.offset = pagination.offset;
        }
    }

    if (
        filter?.location &&
        !(
            initialPageFilter?.geoDistanceFilter?.location?.name === filter?.location?.name &&
            initialPageFilter?.geoDistanceFilter?.location?.lat === filter?.location?.lat &&
            initialPageFilter?.geoDistanceFilter?.location?.long === filter?.location?.long &&
            initialPageFilter?.geoDistanceFilter?.distance === filter?.location?.distance
        )
    ) {
        if (filter.location.lat !== undefined) {
            urlParameter.lat = filter.location.lat;
        }

        if (filter.location.long !== undefined) {
            urlParameter.long = filter.location.long;
        }

        // this is the fix for a bug where location name is passed as an array due to a doubled url HIVE-7269
        const locationName =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            Array.isArray(filter.location.name)
                ? (filter.location.name.at(-1) as string)
                : filter.location.name;

        if (locationName && locationName.trim().length > 0) {
            urlParameter.locationName = locationName;
        }

        if (filter.location.distance) {
            urlParameter.distance = filter.location.distance;
        }
    }

    if (sorting) {
        const { field, order } = sorting;
        const urlParameterSortingField = SortingFieldOptionsToUrlParameterMap[field];

        urlParameter.sortBy = `${urlParameterSortingField}:${order}`;
    }

    if (filter?.includeTestProducts) {
        urlParameter.includeTestProducts = filter.includeTestProducts;
    }

    if (filter?.productAttributes && Object.keys(filter.productAttributes).length) {
        urlParameter.productAttributes = Object.entries(filter.productAttributes)
            .filter(([, attributeValue]) => Array.isArray(attributeValue))
            .map(([attributeKey, attributeValue]) => {
                if (!attributeValue.length) return '';

                if (isStringArray(attributeValue))
                    return `${attributeKey}:${attributeValue.join('_')}`;

                const rangeFilterValue = attributeValue
                    .reduce<string[]>((acc, rangeFilter) => {
                        acc.push(
                            `${rangeFilter.min?.toString() || ''}-${
                                rangeFilter.max?.toString() || ''
                            }`,
                        );
                        return acc;
                    }, [])
                    .join('_');

                return `${attributeKey}:${rangeFilterValue}`;
            })
            .join(',');
    }

    return new URLSearchParams(urlParameter as Record<string, string>).toString();
};

export function toPageUrl({
    search,
    path,
    initialPageFilter,
    currentPage,
    targetPage,
    totalPages,
    offset = 0,
}: ToPageUrlParams): string {
    const { pagination } = search;

    if (
        !pagination ||
        !currentPage ||
        !targetPage ||
        !totalPages ||
        targetPage > totalPages ||
        currentPage === targetPage
    ) {
        return '';
    }

    const urlParameters = toUrlSearchParameters(
        {
            ...search,
            pagination: {
                ...pagination,
                ...(offset ? { offset: (targetPage - 1) * offset } : {}),
                currentPage: targetPage,
            },
        },
        initialPageFilter,
    );

    return urlParameters ? `${path}?${urlParameters}` : path;
}
