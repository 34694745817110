import classNames from 'classnames';

import { Skeleton, SkeletonAnimation, SkeletonVariant } from '@jsmdg/yoshi';
import { PageType } from '../../../shared/enums/pageType';
import { type PageSliderData } from '../../../shared/types/pageContent';
import { type TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { useIsLoading } from '../../hooks';
import { CategorySlider } from '../CategorySlider';
import { PageSlider } from '../PageSlider/PageSlider';
import styles from './PageSliderWrapper.module.scss';

type PageSliderWrapperProps = {
    readonly slides: PageSliderData[];
    readonly headline?: string;
    readonly verticalPosition: number;
    readonly hasNavigationCreative: boolean;
    readonly pageType: PageType;
    readonly crossLinkPageTitle?: string;
    readonly crossLinkPageRef?: string;
    readonly trackingPrefix: TrackingCreativePrefix;
    readonly trackingPath?: string[];
};

const PageSliderWrapper = ({
    crossLinkPageRef,
    crossLinkPageTitle,
    hasNavigationCreative,
    headline,
    pageType,
    slides,
    trackingPath,
    trackingPrefix,
    verticalPosition,
}: PageSliderWrapperProps): JSX.Element => {
    const isListingPage = pageType === PageType.ProductListingPage;
    const hideOnMobileCls = isListingPage ? 'd-none d-sm-flex' : ''; // CategorySlider does not need a skeleton on mobile
    const isLoading = useIsLoading();

    return (
        <div
            className={classNames('position-relative', 'contain-content', {
                [styles.pageSliderSkeletonWrapper]: isLoading,
                [styles.isListingPage]: isLoading && isListingPage,
                [styles.hideArrows]: isLoading && slides.length < 5,
            })}
        >
            {isListingPage ? (
                <div className="d-md-none">
                    <CategorySlider
                        slides={slides}
                        verticalPosition={verticalPosition}
                        hasNavigationCreative={hasNavigationCreative}
                        pageType={pageType}
                        trackingPrefix={trackingPrefix}
                        trackingPath={trackingPath}
                    />
                </div>
            ) : null}
            <div className={hideOnMobileCls}>
                <PageSlider
                    slides={slides}
                    headline={headline}
                    verticalPosition={verticalPosition}
                    hasNavigationCreative={hasNavigationCreative}
                    pageType={pageType}
                    crossLinkPageTitle={crossLinkPageTitle}
                    crossLinkPageRef={crossLinkPageRef}
                    trackingPrefix={trackingPrefix}
                    trackingPath={trackingPath}
                />
            </div>
            {isLoading ? ( // the outer-most div (wrapper) gives the height in case nothing is rendered below the skeleton.
                <div className={`position-absolute d-flex ${hideOnMobileCls} ${styles.skeleton}`}>
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                    />
                </div>
            ) : null}
        </div>
    );
};

export { PageSliderWrapper };
