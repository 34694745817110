import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import tippImageUrl from './tipp.svg';

const messages = defineMessages({
    noResultImageAltText: {
        defaultMessage: 'Tipp',
    },
});

const NoListingSearchResult = (): JSX.Element => {
    const intl = useIntl();

    return (
        <div className="d-flex mb-5x mt-3x mt-sm-5x">
            <div className="d-flex pr-1x p-sm-2x flex-shrink-0">
                <img src={tippImageUrl} alt={intl.formatMessage(messages.noResultImageAltText)} />
            </div>
            <div>
                <p className="theme-text-subtitle-text ml-1x">
                    <FormattedMessage defaultMessage="Tipp: Ändere deine Filtereinstellungen um mehr Erlebnisse zu finden" />
                </p>
                <ul className="mt-1x">
                    <li>
                        <FormattedMessage defaultMessage="Erhöhe den Umkreis bei einer Ortssuche" />
                    </li>
                    <li>
                        <FormattedMessage defaultMessage="Stelle eine andere Preisspanne ein" />
                    </li>
                    <li>
                        <FormattedMessage defaultMessage="Stöbere in einer anderen Erlebniskategorie" />
                    </li>
                </ul>
            </div>
        </div>
    );
};

// eslint-disable-next-line import/no-default-export
export default NoListingSearchResult;
