import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { trackEecPromotionClick } from '@jsmdg/tracking';
import {
    HeroStageType,
    Image,
    PureLink,
    type SliderConfig,
    type SliderSlide,
    SlidingDirections,
} from '@jsmdg/yoshi';
import { PageType } from '../../../shared/enums/pageType';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type ImageSliderImage } from '../../../shared/types/pageContent';
import { trackingTypeByPage } from '../../enums/trackingPageTypes';
import { CreativeName, PromotionType } from '../../enums/trackingPromotions';
import { trackSlideNavigationClick } from '../../helper/trackSlide';
import { useImageSliderImpressionTracking } from '../../hooks/useImageSliderImpressionTracking';
import styles from './ImageSlider.module.scss';

interface UseImageSliderProps {
    galleryImages: ImageSliderImage[];
    verticalPosition: number;
    hasNavigationCreative: boolean;
    pageType: PageType;
    autoSlide: boolean;
}

interface AutoplayOptions {
    delay: number;
    disableOnInteraction: boolean;
    pauseOnMouseEnter?: boolean;
}

type UseImageSliderReturn = {
    setImageSliderElement: (element: HTMLAnchorElement | HTMLDivElement | null) => void;
    slides: SliderSlide[];
    classes: {
        sliderWrapper: string;
        slider: {
            swiperWrapper: string;
            controlsWrapper: string;
        };
    };
    sliderConfig: SliderConfig;
    handleSlideNext: ({ realIndex }: { realIndex: number }) => void;
    handleSlidePrevious: ({ realIndex }: { realIndex: number }) => void;
    handleSlideClickNext: () => void;
    handleSlideClickPrevious: () => void;
    heroStageType: HeroStageType;
    autoPlayOptions: AutoplayOptions | boolean;
};

export const useImageSlider = ({
    autoSlide,
    galleryImages,
    hasNavigationCreative,
    pageType,
    verticalPosition,
}: UseImageSliderProps): UseImageSliderReturn => {
    const { isMydays } = useFragmentContext<FragmentContext>();
    const [setImageSliderElement, setCurrentImageIndex] = useImageSliderImpressionTracking(
        galleryImages,
        verticalPosition,
        trackingTypeByPage[pageType],
    );

    // @TODO : revert to use tenantConfig again when A/B test is done
    const heroStageType = isMydays ? HeroStageType.BoxHero : HeroStageType.DiagonalHero;

    const isHome = pageType === PageType.Home;

    const classes = {
        sliderWrapper: 'position-relative',
        slider: {
            swiperWrapper: classNames(
                styles.imageGallery,
                { [styles.heroWrapper]: isHome },
                'contain-content',
            ),
            controlsWrapper: classNames(
                styles.controlsWrapper,
                'align-items-center w-100 h-100 m-0 p-0',
            ),
        },
    };

    const slides: SliderSlide[] = galleryImages.map((image, index) => {
        const name = image.link?.title || 'no name';
        const id = image.link?.trackingName || image.src;
        const position = `vertical${verticalPosition}_horizontal${index + 1}`;

        const promotionTrackingEventData = {
            creativeName: CreativeName.ImageSlider,
            creativeSlot: position,
            promotionName: name,
            promotionId: id,
            locationId: image.link?.url || '',
            positionHorizontal: `${index + 1}`,
            positionVertical: `${hasNavigationCreative ? verticalPosition - 1 : verticalPosition}`,
            promotionType: PromotionType.Static,
        };

        const onImageClick = (e: React.MouseEvent): void => {
            if (process.env.NODE_ENV !== 'test' && !e.isTrusted) return;

            setTimeout(() => {
                trackEecPromotionClick(
                    {
                        id,
                        name,
                        creative: `ImageSlider_${trackingTypeByPage[pageType]}`,
                        position,
                    },
                    promotionTrackingEventData,
                );
            }, 0);
        };

        const isFirst = index === 0;
        const img = (
            <Image
                className={classNames('flex-1', styles.sliderImage)}
                src={image.src}
                srcSet={image.srcset}
                sizes={image.sizes}
                alt={image.alt}
                lazyLoad={!isFirst}
                fetchpriority={isFirst ? 'high' : 'low'}
                onClick={e => onImageClick(e)}
            />
        );

        return {
            key: `${image.src}-${index}`,
            content: image.link?.url ? (
                <PureLink className="d-flex h-auto w-100 overflow-hidden" href={image.link?.url}>
                    {img}
                </PureLink>
            ) : (
                img
            ),
            ...(image.headline ? { headline: image.headline } : {}),
            ...(image.subline ? { subline: image.subline } : {}),
            ...(image.buttonText ? { buttonText: image.buttonText } : {}),
            ...(image.link?.url ? { link: image.link.url } : {}),
        };
    });

    const handleSlideNavigation = (realIndex: number): void => {
        setCurrentImageIndex(realIndex - 1 >= 0 ? realIndex - 1 : galleryImages.length - 1);
    };

    const handleSlideClickNext = (): void => {
        trackSlideNavigationClick(
            SlidingDirections.Next,
            verticalPosition.toString(),
            CreativeName.ImageSlider,
        );
    };

    const handleSlideClickPrevious = (): void => {
        trackSlideNavigationClick(
            SlidingDirections.Previous,
            verticalPosition.toString(),
            CreativeName.ImageSlider,
        );
    };

    const handleSlideNext = ({ realIndex }: { realIndex: number }): void => {
        handleSlideNavigation(realIndex);
    };

    const handleSlidePrevious = ({ realIndex }: { realIndex: number }): void => {
        handleSlideNavigation(realIndex);
    };

    const showPagination = slides.length > 1;

    return {
        setImageSliderElement,
        slides,
        classes,
        sliderConfig: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            showPagination,
            showNavigation: showPagination,
            touchRatio: 0.1,
        },
        handleSlideNext,
        handleSlidePrevious,
        handleSlideClickNext,
        handleSlideClickPrevious,
        heroStageType,
        autoPlayOptions: autoSlide
            ? {
                  delay: 6_000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
              }
            : false,
    };
};
