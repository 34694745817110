import { defineMessages } from 'react-intl';

type ProductTileMessagesType = {
    servicesActionButtonMessage: {
        defaultMessage: string;
    };
    servicesCaptionMessage: {
        defaultMessage: string;
    };
    a11YCloseServicesMessage: {
        defaultMessage: string;
    };
    a11YPdpRedirection: {
        defaultMessage: string;
    };
    onlineExperienceLocation: {
        defaultMessage: string;
    };
    multipleLocations: {
        defaultMessage: string;
    };
    participantsLabel: {
        defaultMessage: string;
    };
    addToCartButtonMessage: {
        defaultMessage: string;
    };
    bookAppointment: {
        defaultMessage: string;
    };
    learnMore: {
        defaultMessage: string;
    };
    screenReaderTextForWishlistRemove: {
        defaultMessage: string;
    };
    screenReaderTextForWishlistAdd: {
        defaultMessage: string;
    };
    tooManyWishlistArticlesError: {
        defaultMessage: string;
    };
    a11yCloseErrorMessage: {
        defaultMessage: string;
    };
    closePopup: { defaultMessage: string };
};

const messages = defineMessages({
    servicesActionButtonMessage: { defaultMessage: 'Mehr erfahren' },
    servicesCaptionMessage: { defaultMessage: 'Details' },
    a11YCloseServicesMessage: { defaultMessage: 'Leistungsbeschreibung schließen' },
    a11YPdpRedirection: { defaultMessage: 'Weiterleitung zur Produkt Detail Seite' },
    onlineExperienceLocation: { defaultMessage: 'Online-Erlebnis' },
    multipleLocations: { defaultMessage: 'an {locationCount} Orten' },
    participantsLabel: {
        defaultMessage: '{participants} {maxCount, plural, one {Person} other {Personen}}',
    },
    addToCartButtonMessage: { defaultMessage: 'In den Warenkorb' },
    bookAppointment: { defaultMessage: 'Termin buchen' },
    learnMore: { defaultMessage: 'Mehr erfahren' },
    screenReaderTextForWishlistRemove: { defaultMessage: 'Von der Wunschliste entfernen' },
    screenReaderTextForWishlistAdd: { defaultMessage: 'Zur Wunschliste hinzufügen' },
    tooManyWishlistArticlesError: {
        defaultMessage:
            'Die maximale Anzahl an Erlebnissen wurde erreicht – bitte entferne Artikel, um Platz für neue zu schaffen.',
    },
    a11yCloseErrorMessage: { defaultMessage: 'Schließen' },
    closePopup: { defaultMessage: 'Popup Schließen' },
});

const getProductTileMessages = (): ProductTileMessagesType => messages;

export { getProductTileMessages, type ProductTileMessagesType };
