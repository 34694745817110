import { type CSSProperties, useRef } from 'react';
import classnames from 'classnames';

import { PureLink, Wysiwyg } from '@jsmdg/yoshi';
import { type Card } from '../../../shared/types/pageContent';
import { CardImage } from './CardImage';
import { useHeadlineStyles } from './useHeadlineStyles';
import styles from './CardGroup.module.scss';

type CardGroupProps = {
    readonly cards: Card[];
    readonly showOutline: boolean;
    readonly lazyLoad: boolean;
};

const CardGroup = ({ cards, lazyLoad, showOutline }: CardGroupProps): JSX.Element => {
    const headlineRefs = useRef(Array.from({ length: cards.length }));
    const headlineStyles = useHeadlineStyles(headlineRefs.current);

    const setHeadlineRef = (ref: HTMLHeadingElement | null, index: number): void => {
        headlineRefs.current[index] = ref;
    };

    const getClassNames = (index: number): string => {
        const layoutClass =
            {
                2: 'g-col-6',
                3: index === 0 ? 'g-col-12 g-col-sm-4' : 'g-col-12 g-col-xs-6 g-col-sm-4',
                4: 'g-col-6 g-col-sm-3',
                5: index === 0 ? 'g-col-12 g-col-sm-2' : 'g-col-12 g-col-xs-6 g-col-sm-2',
                6: 'g-col-6 g-col-sm-4',
            }[cards.length] || 'g-col-12';

        return `mb-3x mb-sm-4x ${layoutClass}`;
    };

    return (
        <div className={classnames('grid', styles.cardGroupWrapper)}>
            {cards.map(({ body, caption, image, link }, index) => {
                const content = (
                    <>
                        {caption && (
                            <h2
                                className={classnames(styles.title, 'mb-2x pb-0 text-break')}
                                ref={el => setHeadlineRef(el, index)}
                                style={headlineStyles[index] as CSSProperties}
                            >
                                {caption}
                            </h2>
                        )}
                        {image && (
                            <CardImage
                                className={classnames({
                                    'mb-1-5x': !showOutline,
                                })}
                                imageClassName={styles.imageFit}
                                image={image}
                                lazyLoad={lazyLoad}
                            />
                        )}
                        {body && (
                            <Wysiwyg
                                className={classnames(styles.body, {
                                    'pt-0': caption && !image,
                                })}
                                content={body}
                            />
                        )}
                    </>
                );

                const classes = classnames('h-100', styles.link, {
                    [styles.outlined]: showOutline,
                });

                return (
                    <div className={getClassNames(index)} key={caption || image?.src || body}>
                        {!!link?.url && (
                            <PureLink href={link.url} className={classes}>
                                {content}
                            </PureLink>
                        )}
                        {!link?.url && <div className={classes}>{content}</div>}
                    </div>
                );
            })}
        </div>
    );
};

export { CardGroup };
