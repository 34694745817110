/* eslint-disable no-underscore-dangle */
import { type InstantSearchServerState } from 'react-instantsearch';
import { type PlainSearchParameters, SearchParameters, SearchResults } from 'algoliasearch-helper';
import { type ScopedResult } from 'instantsearch.js';

import { getAdjustedItemCountByLimit } from '../../client/helper/getAdjustedItemCountByLimit';
import { AttributeName } from '../enums/attributeName';
import { type Brand } from '../enums/brand';
import { ProductIndexField } from '../enums/indexField';
import { type ProductType } from '../enums/productType';
import { ScoringFactorMap } from '../enums/scoringFactor';
import { SortingField } from '../enums/sorting';
import { type Attribute } from '../types/attribute';
import { type FacetFilter } from '../types/facetFilter';
import { type FacetStats } from '../types/facetStats';
import { type FacetValue } from '../types/facetValue';
import { type InstantSearchServerStateWithRedirect } from '../types/InstantSearchServerStateWithRedirect';
import { type AlgoliaConfig, type AlgoliaTimeoutConfig } from '../types/pageRoot';
import {
    type Boundary,
    type ClassificationFilter,
    type Filter,
    type LocationFilter,
    type Pagination,
    type RangeFilter,
    type Search,
    type Sorting,
    type TargetGroupFilter,
} from '../types/search';
import { type AlgoliaProduct, type ClosestLocation } from '../types/searchResponse';
import { AlgoliaCustomDataKey, getAlgoliaCustomData } from './getAlgoliaCustomData';
import { getOffset } from './getOffset';

enum Conjunction {
    And = 'AND',
    Or = 'OR',
}

// Rule context id that triggers matching pages that do not have a defined per page facet configuration
const DefaultPerPageFacetIdentifier = 'default';

// Modify this configuration to adjust timeout behaviour of Algolia internal client
const DefaultAlgoliaTimeoutConfig: AlgoliaTimeoutConfig = {
    // timeout in milliseconds to establish a connection with the server
    connect: 1_000,
    // timeout in milliseconds to receive the response on write requests.
    write: 30_000,
    // timeout in milliseconds to receive the response on read requests.
    read: 2_000,
};

// NB: this implementation is replicated from index-search backend since we do call Algolia directly in the UI
const getClosestLocation = (product: AlgoliaProduct): ClosestLocation | undefined => {
    try {
        if (!product?._rankingInfo?.matchedGeoLocation || !product.locations?.length) {
            return undefined;
        }

        const matchedLocation = product.locations?.find(
            location =>
                location.position.lat.toFixed(2) ===
                    product._rankingInfo?.matchedGeoLocation?.lat.toFixed(2) &&
                location.position.lon.toFixed(2) ===
                    product._rankingInfo?.matchedGeoLocation?.lng.toFixed(2),
        );

        if (!matchedLocation) return undefined;

        return {
            distance: {
                unit: 'km',
                // distance is in meters
                value: Math.ceil(product?._rankingInfo?.matchedGeoLocation?.distance / 1_000),
            },
            lat: matchedLocation?.position.lat,
            long: matchedLocation?.position.lon,
            name: matchedLocation?.city,
        };
    } catch {
        return undefined;
    }
};

const getRadius = (currSort?: Sorting, distance?: number): number | 'all' | undefined => {
    if (distance) {
        return distance * 1_000;
    }

    if (currSort?.field !== SortingField.Distance) {
        return undefined;
    }

    return 'all';
};

const getLatLng = (location?: LocationFilter | null): string | undefined => {
    if (!location || !Object.keys(location).length) {
        return undefined;
    }

    const validLatLngRegexp = /^-?\d+(?:\.\d+)?,\s*-?\d+(?:\.\d+)?$/;
    const latLng = `${location.lat}, ${location.long}`;

    if (!validLatLngRegexp.test(latLng)) {
        return undefined;
    }

    return latLng;
};

const isCorrectCoordinate = (coordinate: number, interval: number): boolean => {
    return Number.isFinite(coordinate) && Math.abs(coordinate) <= interval;
};

const getBoundary = (boundary?: Boundary | null): number[][] | undefined => {
    if (!boundary || !Object.keys(boundary).length) {
        return undefined;
    }

    if (
        !isCorrectCoordinate(boundary.lat1, 90) ||
        !isCorrectCoordinate(boundary.lat2, 90) ||
        !isCorrectCoordinate(boundary.lng1, 180) ||
        !isCorrectCoordinate(boundary.lng2, 180)
    ) {
        return undefined;
    }

    return [[boundary.lat1, boundary.lng1, boundary.lat2, boundary.lng2]];
};

const joinFilterQuery = (
    filterQuery: Array<string | undefined>,
    conjunction: Conjunction,
): string => {
    if (filterQuery?.length < 2) {
        return filterQuery.join(` ${conjunction} `);
    }

    return filterQuery
        ?.filter(Boolean)
        .map(filter => {
            if (/\(|\)/.test(filter ?? '') || !/AND|OR/.test(filter ?? '')) {
                return filter;
            }

            return `(${filter})`;
        })
        .join(` ${conjunction} `);
};

const mapAlgoliaClassificationFilter = (
    classification?: ClassificationFilter,
    conjunction: Conjunction = Conjunction.And,
): string | undefined => {
    if (!classification) {
        return undefined;
    }

    const matchAtLeastOnceFilter = classification.matchAtLeastOnce
        ?.map(match => `classifications:${match}`)
        .join(' OR ');

    const excludeFilter = classification.exclude
        ?.map(exclude => `NOT classifications:${exclude}`)
        .join(' AND ');

    const matchAllFilter = joinFilterQuery(
        classification.matchAll?.map(match => `classifications:${match}`) || [],
        conjunction,
    );

    const filters = [matchAtLeastOnceFilter, excludeFilter, matchAllFilter].filter(Boolean);

    return joinFilterQuery(filters, conjunction);
};

const mapAlgoliaPriceFilter = (price?: RangeFilter): string | undefined => {
    if (!price) {
        return undefined;
    }

    if (price?.min && price?.max) {
        return `price.grossAmount:${price.min} TO ${price.max}`;
    }

    if (price?.min) {
        return `price.grossAmount >= ${price.min}`;
    }

    if (price?.max) {
        return `price.grossAmount <= ${price.max}`;
    }

    return undefined;
};

const mapAlgoliaTopCountriesFilter = (topCountryCodes?: string[]): string | undefined => {
    if (!topCountryCodes?.length) {
        return undefined;
    }

    return topCountryCodes.map(countryCode => `locations.countryCode:${countryCode}`).join(' OR ');
};

const joinRangeFilters = (
    fieldName: string,
    rangeFilters: RangeFilter[],
    isNumericArrayField = false,
): string => {
    const cleanedRangeFilters: RangeFilter[] = rangeFilters.filter(
        (rangeFilter: RangeFilter) => typeof rangeFilter !== 'undefined',
    );

    const hasValidRangeValue = cleanedRangeFilters.some(
        rangeFilter =>
            rangeFilter.max !== undefined && rangeFilter.max - (rangeFilter.min || 0) > 0,
    );

    return joinFilterQuery(
        cleanedRangeFilters
            .filter(rangeFilter => rangeFilter.min !== undefined || rangeFilter.max !== undefined)
            .sort(({ min: range1Min = 0 }, { min: range2Min = 0 }) => range1Min - range2Min)
            .reduceRight((allRangeFilters: RangeFilter[], rangeFilter: RangeFilter) => {
                const previousRange = allRangeFilters[allRangeFilters.length - 1];
                if (
                    allRangeFilters.length &&
                    (previousRange.min || 0) - (rangeFilter.min || 0) <= 1
                ) {
                    return [
                        {
                            min: rangeFilter.min || 0,
                            max: previousRange.max ?? previousRange.min,
                        },
                        ...allRangeFilters.slice(0, allRangeFilters.length - 1),
                    ];
                }

                return [{ min: rangeFilter.min || 0, max: rangeFilter.max }, ...allRangeFilters];
            }, [])
            .map((rangeFilter: RangeFilter): string => {
                const minValue = rangeFilter.min || 0;
                if (!rangeFilter.max) {
                    // Algolia does not accept sending ranges with non ranges in an OR combination
                    if (hasValidRangeValue) {
                        return `${fieldName}:${minValue} TO ${minValue}`;
                    }

                    return `${fieldName}${isNumericArrayField ? ':' : '='}${minValue}`;
                }

                return `${fieldName}:${minValue} TO ${rangeFilter.max}`;
            }),
        Conjunction.Or,
    );
};

const mapAlgoliaTravelNightsFilter = (
    rangeFilter?: RangeFilter | RangeFilter[],
): string | undefined => {
    if (!rangeFilter) {
        return undefined;
    }

    return joinRangeFilters(
        `attributes.${AttributeName.TravelNights}`,
        Array.isArray(rangeFilter) ? rangeFilter : [rangeFilter],
    );
};

const mapAlgoliaProductTypeFilter = (productTypes?: ProductType[]): string | undefined => {
    if (!productTypes?.length) {
        return undefined;
    }

    return productTypes.map(productType => `productType:${productType}`).join(' OR ');
};

const mapAlgoliaProductIdFilter = (productIds?: string[]): string | undefined => {
    if (!productIds?.length) {
        return undefined;
    }

    return productIds.map(productId => `productId:${productId}`).join(' OR ');
};

const mapAlgoliaTargetGroupFilter = (targetGroup?: TargetGroupFilter): string | undefined => {
    if (!targetGroup || !targetGroup.name || !ScoringFactorMap[targetGroup.name]) {
        return undefined;
    }

    const comparisonSign = targetGroup.minimumScoringLimit === 0 ? '>' : '>=';

    return `scoringFactors.${ScoringFactorMap[targetGroup.name]} ${comparisonSign} ${
        targetGroup.minimumScoringLimit
    }`;
};

const quoteString = (filterString: string): string => {
    return /\s+/.test(filterString) ? `'${filterString}'` : filterString;
};

const mapAlgoliaBrandsFilter = (brands?: Brand[]): string | undefined => {
    if (!brands?.length) {
        return undefined;
    }

    return brands.map(brand => `brand:${quoteString(brand)}`).join(' OR ');
};

const mapAlgoliaParticipantsFilter = (filter?: RangeFilter): string | undefined => {
    if (!filter) {
        return undefined;
    }

    return joinRangeFilters(`attributes.${AttributeName.Participants}`, [filter], true);
};

const mapAlgoliaProductAttributesFilter = (
    productAttributes?: Record<string, string[] | RangeFilter[]>,
): string | undefined => {
    if (!productAttributes) return undefined;

    return joinFilterQuery(
        Object.keys(productAttributes)
            .filter(productAttribute => Array.isArray(productAttributes[productAttribute]))
            .map(productAttribute => {
                const filters = productAttributes[productAttribute];

                if (filters?.length && typeof filters[0] === 'string') {
                    return joinFilterQuery(
                        filters.map(
                            value =>
                                `attributes.${productAttribute}:${quoteString(value as string)}`,
                        ),
                        Conjunction.Or,
                    );
                }

                return joinRangeFilters(
                    `attributes.${productAttribute}`,
                    filters as RangeFilter[],
                    productAttribute === AttributeName.Participants,
                );
            }),
        Conjunction.And,
    );
};

const getFilters = (filter?: Filter): string | undefined => {
    if (!filter) {
        return undefined;
    }

    const priceFilter = mapAlgoliaPriceFilter(filter?.price);
    const classificationFilter = mapAlgoliaClassificationFilter(filter?.classification);
    const countriesCodeFilter = mapAlgoliaTopCountriesFilter(filter?.topCountryCodes);
    const travelNightsFilter = mapAlgoliaTravelNightsFilter(filter?.travelNights);
    const productTypeFilter = mapAlgoliaProductTypeFilter(filter?.productTypes);
    const productIdFilter = mapAlgoliaProductIdFilter(filter?.productId);
    const participantsFilter = mapAlgoliaParticipantsFilter(filter?.participants);
    const targetGroupFilter = mapAlgoliaTargetGroupFilter(filter?.targetGroup);
    const brandsFilter = mapAlgoliaBrandsFilter(filter?.brands);
    const productAttributesFilter = mapAlgoliaProductAttributesFilter(filter?.productAttributes);
    const inPreviewFilter = filter.includePreviewProducts ? undefined : 'inPreview:false';
    const isTestProductFilter = filter.includeTestProducts ? undefined : 'isTestProduct:false';
    const giftboxIdFilter = filter.giftBoxId ? `giftboxList: ${filter.giftBoxId}` : undefined;
    const isSellableProductFilter = filter.isShoppingFlow ? 'isSellable:true' : undefined;

    return joinFilterQuery(
        [
            priceFilter,
            classificationFilter,
            countriesCodeFilter,
            travelNightsFilter,
            productTypeFilter,
            productIdFilter,
            participantsFilter,
            targetGroupFilter,
            brandsFilter,
            productAttributesFilter,
            inPreviewFilter,
            isTestProductFilter,
            giftboxIdFilter,
            isSellableProductFilter,
        ],
        Conjunction.And,
    );
};

const calculateSizeWithLimit = ({
    size,
    limit,
    offset,
}: Omit<Pagination, 'currentPage'>): number => {
    if (!limit || offset + size < limit) {
        return size;
    }

    return Math.max(0, limit - offset);
};

const getAlgoliaPaginationSearchParameters = (
    pagination?: Pagination,
): Pick<PlainSearchParameters, 'page' | 'hitsPerPage' | 'offset' | 'length'> => {
    if (pagination?.limit) {
        const offset = getOffset(pagination);
        const length = calculateSizeWithLimit({ ...pagination, offset });

        if (length) {
            return {
                offset,
                length,
                hitsPerPage: 0,
            };
        }

        return {
            page: pagination?.currentPage ? pagination?.currentPage - 1 : undefined,
            hitsPerPage: 0,
        };
    }

    return {
        page: pagination?.currentPage ? pagination?.currentPage - 1 : undefined,
        hitsPerPage: pagination?.size,
    };
};

const getAlgoliaSearchParameters = ({
    search,
    pageId,
    facets,
    userToken,
}: {
    search?: Search;
    pageId?: string;
    facets?: string[];
    userToken?: string;
}): Omit<PlainSearchParameters, 'index'> => {
    const includeFacets = facets || ['attributes.*'];
    const ruleContexts = [DefaultPerPageFacetIdentifier];

    if (pageId) ruleContexts.unshift(pageId);

    return {
        query: search?.searchTerm,
        aroundRadius: getRadius(search?.sorting, search?.filter?.location?.distance),
        aroundLatLng: getLatLng(search?.filter?.location),
        insideBoundingBox: getBoundary(search?.filter?.boundary),
        filters: getFilters(search?.filter),
        ...getAlgoliaPaginationSearchParameters(search?.pagination),
        ruleContexts,
        disjunctiveFacets: ['dynamicAttributes'],
        facets: includeFacets,
        attributesToRetrieve: Object.values(ProductIndexField),
        attributesToSnippet: [],
        attributesToHighlight: [],
        getRankingInfo: true,
        userToken,
        clickAnalytics: !!userToken,
    };
};

const prefixFacetName = (facetFilter: string): string =>
    facetFilter.startsWith('attributes.') ? facetFilter : `attributes.${facetFilter}`;

/**
 * getPerPageFacetNames returns a list of facets to show on a page
 * based on configured per page facet rules on Algolia
 * @param results
 */
const getPerPageFacetNames = (results: SearchResults): string[] => {
    if (results.disjunctiveFacets) {
        const facetValues = results.getFacetValues('dynamicAttributes', {});

        if (Array.isArray(facetValues) && facetValues?.length) {
            const orderedFacets = results.renderingContent?.facetOrdering?.facets?.order;
            const facetNames = facetValues.map(facet => prefixFacetName(facet.name));

            if (orderedFacets?.length) {
                return orderedFacets
                    .map(prefixFacetName)
                    .filter(facetFilter => facetNames.includes(facetFilter));
            }

            const customDefinedFacets =
                getAlgoliaCustomData(AlgoliaCustomDataKey.PerPageFacets, results.userData) ||
                getAlgoliaCustomData(AlgoliaCustomDataKey.DefaultPageFacets, results.userData);

            const perPageFacets = customDefinedFacets?.map(prefixFacetName);

            if (perPageFacets?.length) {
                const facets = perPageFacets.includes('attributes.*')
                    ? facetNames
                    : facetNames.filter(facetName => perPageFacets.includes(facetName));

                return facets.sort(
                    (a: string, b: string) => perPageFacets.indexOf(a) - perPageFacets.indexOf(b),
                );
            }
        }
    }

    return [];
};

const getFacetValues = (
    facetName: string,
    facets?: Record<string, Record<string, number>>,
    limit?: number,
): FacetValue[] => {
    const facetKey = prefixFacetName(facetName);
    if (!facets || !facets[facetKey]) {
        return [];
    }

    const facetValues = facets[facetKey];

    return Object.keys(facetValues).map(facetValue => ({
        name: facetValue,
        count: getAdjustedItemCountByLimit(facetValues[facetValue], limit),
    }));
};

const getFacetFilter = (
    attribute: Attribute,
    results?: SearchResults,
    limit?: number,
): FacetFilter | undefined => {
    if (!results) {
        return undefined;
    }

    const facetKey = prefixFacetName(attribute.objectID);
    const facetValues = results.getFacetValues(facetKey, {});
    const facetStats = results.getFacetStats(facetKey) as FacetStats | undefined;

    return {
        attribute: attribute.objectID,
        name: attribute.title,
        type: attribute.type,
        values: Array.isArray(facetValues)
            ? facetValues.map(
                  facetValue =>
                      ({
                          name: facetValue.name,
                          count: getAdjustedItemCountByLimit(facetValue.count, limit),
                      }) as SearchResults.FacetValue,
              )
            : [],
        facetStats,
    };
};

const getFacetFilters = (
    attributes?: Attribute[],
    results?: SearchResults,
    limit?: number,
): FacetFilter[] | undefined => {
    if (!results || !attributes) {
        return undefined;
    }

    const perPageFacetNames = getPerPageFacetNames(results);

    const productResults = results?._rawResults;

    const facets = productResults?.length
        ? productResults[productResults.length - 1].facets
        : undefined;

    const facetStats = productResults?.length
        ? productResults[productResults.length - 1].facets_stats
        : undefined;

    return perPageFacetNames
        .map(facetName =>
            attributes.find(attribute => prefixFacetName(attribute.objectID) === facetName),
        )
        .filter(Boolean)
        .map((attribute?: Attribute): FacetFilter => {
            const validAttribute = attribute as Attribute;

            return {
                attribute: validAttribute.objectID,
                name: validAttribute.title,
                type: validAttribute.type,
                values: getFacetValues(validAttribute.objectID, facets, limit),
                facetStats: facetStats?.[prefixFacetName(validAttribute.objectID)],
            };
        });
};

const getScopedResultFromServerState = (
    scopedResults: ScopedResult[],
    serverState?: InstantSearchServerState,
): ScopedResult[] => {
    if (!serverState?.initialResults) {
        return scopedResults;
    }

    const helper = scopedResults?.at(0)?.helper as ScopedResult['helper'];

    return Object.keys(serverState.initialResults).reduce(
        (scopedResultsAccumulator: ScopedResult[], indexId: string): ScopedResult[] => {
            const initialResult = serverState.initialResults[indexId];
            const indexName = initialResult.state?.index;

            if (indexName) {
                const results = new SearchResults(
                    new SearchParameters(initialResult.state),
                    initialResult.results || [],
                );

                scopedResultsAccumulator.push({
                    indexId,
                    results,
                    helper,
                });
            }

            return scopedResultsAccumulator;
        },
        [],
    );
};

const getRedirectFromServerState = (
    serverState: InstantSearchServerStateWithRedirect,
): string | undefined => {
    return serverState.initialResults?.['search-page']?.results?.[0].renderingContent?.redirect
        ?.url;
};

const getAlgoliaConfig = (
    userToken?: string | null,
    timeouts: AlgoliaTimeoutConfig = DefaultAlgoliaTimeoutConfig,
): AlgoliaConfig => ({
    environment: process.env.ENVIRONMENT as string,
    appId: process.env.ALGOLIA_APP_ID as string,
    searchKey: process.env.ALGOLIA_SEARCH_KEY as string,
    serverState: { initialResults: {} },
    userToken: userToken ?? undefined,
    timeouts,
});

export {
    calculateSizeWithLimit,
    DefaultPerPageFacetIdentifier,
    getAlgoliaConfig,
    getAlgoliaPaginationSearchParameters,
    getAlgoliaSearchParameters,
    getClosestLocation,
    getFacetFilter,
    getFacetFilters,
    getFacetValues,
    getFilters,
    getLatLng,
    getPerPageFacetNames,
    getRadius,
    getRedirectFromServerState,
    getScopedResultFromServerState,
    joinRangeFilters,
};
