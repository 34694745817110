import { useEffect, useState } from 'react';

export const useIsLoading = (): boolean => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(false);
    }, [setIsLoading]);

    return isLoading;
};
