export enum ProductIndexField {
    Title = 'title',
    Url = 'url',
    ProductId = 'productId',
    ProductType = 'productType',
    ManufacturerSku = 'manufacturerSku',
    Price = 'price',
    TrackingName = 'trackingName',
    TrackingPath = 'trackingPath',
    Images = 'images',
    InPreview = 'inPreview',
    IsTestProduct = 'isTestProduct',
    IsSellable = 'isSellable',
    SellOption = 'sellOption',
    ProductInformationServices = 'productInformation.services',
    Participants = 'participants',
    OriginalPrice = 'originalPrice',
    Classifications = 'classifications',
    Locations = 'locations',
    Rating = 'rating',
    DiscountBadge = 'discountBadge',
}

export enum AttributeIndexField {
    Title = 'title',
    Type = 'type',
}
