import { useMemo } from 'react';
import { type PlainSearchParameters } from 'algoliasearch-helper';

import { getAlgoliaSearchParameters } from '../../shared/helpers/algoliaHelpers';
import { type SearchReducerState } from '../reducers/searchReducer';

type UseAlgoliaSearchStateProps = {
    indexName: string;
    search: SearchReducerState;
    pageId?: string;
    facets?: string[];
    userToken?: string;
};

const useAlgoliaProductSearchState = ({
    indexName,
    search,
    pageId,
    facets,
    userToken,
}: UseAlgoliaSearchStateProps): PlainSearchParameters => {
    return useMemo(
        () => ({
            ...getAlgoliaSearchParameters({ search, pageId, facets, userToken }),
            index: indexName,
        }),
        [facets, indexName, pageId, search, userToken],
    );
};

export { useAlgoliaProductSearchState };
