import { type MutableRefObject, useEffect, useRef, useState } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Filter as FilterType } from '../../shared/types/search';
import { formatTrackingPriceRange } from '../helper/formatPrice';
import { getGridView } from '../helper/getGridView';
import { SearchReducerActionType } from '../reducers/searchReducer';
import { type Nullable } from '../types';
import { getActiveFilters } from './getActiveFilters';

const PRICE_FILTER_MIN = 0;
const PRICE_FILTER_MAX = 500;

const PRICE_FILTER_STEP = 10;

type UsePriceFilterProps = {
    currencyCode: string;
    onSubmit: (
        label: SearchReducerActionType,
        value: { min: Nullable<number>; max: Nullable<number> } | Nullable<number>,
    ) => void;
    shouldReset?: boolean;
    options: {
        min: number;
        max: number;
    };
    values: Array<Nullable<number>>;
    filter: FilterType;
};

type UsePriceFilterReturn = {
    sliderDidUpdate: boolean;
    priceData: {
        price: Array<Nullable<number>>;
        isDefaultMin: boolean;
        isDefaultMax: boolean;
    };
    rangeValues: number[];
    internalData: {
        values: Array<Nullable<number>>;
        isDefaultMin: boolean;
        isDefaultMax: boolean;
    };
    filterRef: MutableRefObject<null>;
    onRangeChange: (values: number[]) => void;
    onPriceChange: (values: number[], explicitReset?: boolean) => void;
    handleReset: () => void;
    handleOnClickOutside: () => void;
};

export function usePriceFilter({
    currencyCode,
    onSubmit,
    options,
    shouldReset,
    values,
    filter,
}: UsePriceFilterProps): UsePriceFilterReturn {
    const { locale, settingCookie } = useFragmentContext<FragmentContext>();
    const [price, setPrice] = useState(values);
    const [internalValue, setInternalValue] = useState(values);
    const [sliderDidUpdate, setSliderDidUpdate] = useState(false);
    const filterRef = useRef(null);
    const previousPriceSliderRef = useRef([options.min, options.max]);
    const gridView = getGridView(settingCookie);

    const isDefaultMax = (maxValue: Nullable<number>): boolean => {
        return maxValue === null || maxValue === options.max;
    };

    const isDefaultMin = (minValue: Nullable<number>): boolean => {
        return minValue === null || minValue === options.min || minValue === 0;
    };

    const isDefaultValues = (valueArray: Array<Nullable<number>>): boolean => {
        return isDefaultMin(valueArray[0]) && isDefaultMax(valueArray[1]);
    };

    const trackPriceFilterInteraction = async (
        min: Nullable<number>,
        max: Nullable<number>,
    ): Promise<void> => {
        await window.yieldToMainThread();
        const minTrackingValue = isDefaultMin(min) ? PRICE_FILTER_MIN : min;
        const maxTrackingValue = isDefaultMax(max) ? null : max;
        const priceRangeLabel = formatTrackingPriceRange({
            locale,
            currencyCode,
            min: minTrackingValue,
            max: maxTrackingValue,
        });

        const activePriceFilters = getActiveFilters(filter, ['Price']);

        trackFilterInteraction(
            'SetPrice',
            priceRangeLabel,
            {
                eventName: GA4EventName.SetFilter,
                filter_type: 'Price via slider',
                filter_value: priceRangeLabel,
                list_type: GA4FilterListType[gridView],
            },
            activePriceFilters,
        );
    };

    const onPriceChange = (sliderValues: number[], explicitReset = false): void => {
        const [sliderMin, sliderMax] = sliderValues;
        const [previousMin, previousMax] = previousPriceSliderRef.current;
        const [priceMin, priceMax] = price;
        if (isDefaultValues(sliderValues)) {
            // if default values are set, no pricefilter should be used
            onSubmit(SearchReducerActionType.Price, { min: null, max: null });
            setPrice([null, null]);
            previousPriceSliderRef.current = sliderValues;
        } else if (sliderMin !== previousMin && sliderMax !== previousMax) {
            // both values changed
            onSubmit(SearchReducerActionType.Price, {
                min: sliderMin,
                max: sliderMax,
            });
            setPrice(sliderValues);
            previousPriceSliderRef.current = sliderValues;
        } else if (sliderMin !== previousMin && !isDefaultMin(sliderMin)) {
            // only price min changed
            onSubmit(SearchReducerActionType.PriceMin, sliderMin);
            setPrice([sliderMin, priceMax]);
            previousPriceSliderRef.current = sliderValues;
        } else if (sliderMax !== previousMax && !isDefaultMax(sliderMax)) {
            // only price max changed
            onSubmit(SearchReducerActionType.PriceMax, sliderMax);
            setPrice([priceMin, sliderMax]);
            previousPriceSliderRef.current = sliderValues;
        } else if (isDefaultMin(sliderMin)) {
            // min changed to default value
            onSubmit(SearchReducerActionType.PriceMin, null);
            setPrice([null, priceMax]);
            previousPriceSliderRef.current = sliderValues;
        } else if (isDefaultMax(sliderMax)) {
            // max changed to default value
            onSubmit(SearchReducerActionType.PriceMax, null);
            setPrice([priceMin, null]);
            previousPriceSliderRef.current = sliderValues;
        } else {
            // nothing changed
            onSubmit(SearchReducerActionType.Price, { min: priceMin, max: priceMax });
            setPrice(price);
        }

        // it should only be tracked if user did not click reset but changed values manually
        if (!explicitReset) {
            // eslint-disable-next-line no-void
            void trackPriceFilterInteraction(sliderMin, sliderMax);
        }

        setSliderDidUpdate(false);
    };

    const handleReset = (): void => {
        // price just got removed, so we should not track it in active filters
        const { ...remainingFilters } = filter;
        const resetFilters = getActiveFilters(remainingFilters);

        trackFilterInteraction(
            'SetPrice',
            'ResetPrice',
            {
                eventName: GA4EventName.ResetFilter,
                filter_type: 'Price',
                list_type: GA4FilterListType[gridView],
            },
            resetFilters,
        );
        onPriceChange([PRICE_FILTER_MIN, PRICE_FILTER_MAX], true);
        setInternalValue([options.min, options.max]);
    };

    const onRangeChange = (sliderValues: Array<Nullable<number>>): void => {
        setInternalValue(sliderValues);
        setSliderDidUpdate(true);
    };

    const handleOnClickOutside = (): void => {
        let min = internalValue[0] || options.min;
        let max = internalValue[1] || options.max;

        const [prevMin, prevMax] = price;

        if (min !== prevMin) {
            min = min < max - PRICE_FILTER_STEP ? min : max - PRICE_FILTER_STEP;
        }

        if (max !== prevMax) {
            max = max > min + PRICE_FILTER_STEP ? max : min + PRICE_FILTER_STEP || max;
        }

        if (sliderDidUpdate) {
            onRangeChange([min, max]);
            onPriceChange([min, max]);
            setSliderDidUpdate(false);
        }
    };

    useEffect(() => {
        if (shouldReset) {
            setPrice([null, null]);
            setInternalValue([null, null]);
        }
    }, [shouldReset]);

    return {
        priceData: {
            price,
            isDefaultMin: isDefaultMin(price[0]),
            isDefaultMax: isDefaultMax(price[1]),
        },
        internalData: {
            values: internalValue,
            isDefaultMin: isDefaultMin(internalValue[0]),
            isDefaultMax: isDefaultMax(internalValue[1]),
        },
        filterRef,
        sliderDidUpdate,
        onPriceChange,
        onRangeChange,
        handleReset,
        handleOnClickOutside,
        rangeValues: [internalValue[0] || options.min, internalValue[1] || options.max],
    };
}
