import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Breakpoint, useBreakpoint } from '@jsmdg/yoshi';
import { CreativePlaceholderId } from '../../shared/enums/creativePlaceholderId';
import { type CampaignCreative } from '../../shared/types/campaignCreative';
import { type FragmentContext } from '../../shared/types/fragmentContext';

const productTileBannerIds = [
    CreativePlaceholderId.ProductTileDesktopLarge,
    CreativePlaceholderId.ProductTileDesktopSmall,
    CreativePlaceholderId.ProductTileTablet,
    CreativePlaceholderId.ProductTileMobile,
];

const heroStageBannerIds = [
    CreativePlaceholderId.HeroStageDesktop,
    CreativePlaceholderId.HeroStageMobile,
];

type UseCreatives = {
    productTileCreatives: CampaignCreative[];
    productListCreatives: CampaignCreative[];
    selectionBlockCreatives: CampaignCreative[];
    navigationCreatives: CampaignCreative[];
    heroStageCreatives: CampaignCreative[];
};

export const useCreatives = (): UseCreatives => {
    const { campaignCreatives } = useFragmentContext<FragmentContext>();
    const isDesktop = useBreakpoint(Breakpoint.MD);
    const hasCampaigns = !!campaignCreatives?.length;

    if (!hasCampaigns) {
        return {
            productTileCreatives: [],
            productListCreatives: [],
            selectionBlockCreatives: [],
            navigationCreatives: [],
            heroStageCreatives: [],
        };
    }

    const navigationCreatives = isDesktop
        ? campaignCreatives.filter(
              campaignCreative =>
                  campaignCreative.placeholderId === CreativePlaceholderId.NavigationBannerDesktop,
          )
        : [];

    const productTileCreatives: CampaignCreative[] = productTileBannerIds
        .map(placeholderId =>
            campaignCreatives.find(creative => creative.placeholderId === placeholderId),
        )
        .filter(creative => !!creative) as CampaignCreative[];

    const productListCreatives = campaignCreatives.filter(
        campaignCreative =>
            campaignCreative.placeholderId === CreativePlaceholderId.ProductListDesktop,
    );

    const selectionBlockCreative = isDesktop
        ? campaignCreatives.find(
              creative => creative.placeholderId === CreativePlaceholderId.SelectionBlockDesktop,
          )
        : undefined;
    const selectionBlockCreatives = selectionBlockCreative ? [selectionBlockCreative] : [];

    const heroStageCreatives: CampaignCreative[] = heroStageBannerIds
        .map(placeholderId =>
            campaignCreatives.find(creative => creative.placeholderId === placeholderId),
        )
        .filter(creative => !!creative) as CampaignCreative[];

    return {
        productTileCreatives,
        productListCreatives,
        selectionBlockCreatives,
        navigationCreatives,
        heroStageCreatives,
    };
};
