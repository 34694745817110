import { type InstantSearchServerState } from 'react-instantsearch';
import { type SearchResults } from 'algoliasearch-helper';
import { type ScopedResult } from 'instantsearch.js';

import { getScopedResultFromServerState } from '../../shared/helpers/algoliaHelpers';

type GetAlgoliaScopedResultsProps = {
    readonly algoliaScopedResults: ScopedResult[];
    readonly indexId: string;
    readonly indexName: string;
    readonly serverState?: InstantSearchServerState;
};

export function getAlgoliaScopedResults<T>({
    algoliaScopedResults,
    indexId,
    indexName,
    serverState,
}: GetAlgoliaScopedResultsProps): SearchResults<T> | undefined {
    const algoliaResults = algoliaScopedResults?.find(
        ({ indexId: scopedIndexId, results }) =>
            scopedIndexId === indexId && results?.index === indexName,
    )?.results;

    if (algoliaResults) {
        return algoliaResults;
    }

    const scopedResults = getScopedResultFromServerState(algoliaScopedResults, serverState);

    return scopedResults.find(
        ({ indexId: scopedIndexId, results }) =>
            scopedIndexId === indexId && results?.index === indexName,
    )?.results;
}
