import { type MouseEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import { Pagination } from '@jsmdg/yoshi';
import { type Search } from '../../../shared/types/search';
import { toPageUrl } from '../../helper/mapUrlParameters';
import { type SearchReducerAction, SearchReducerActionType } from '../../reducers/searchReducer';
import { type InitialPageFilterType } from '../../types';
import styles from './ProductListPagination.module.scss';

const messages = defineMessages({
    page: {
        defaultMessage: 'Seite {targetPage}',
    },
});

type ProductListPaginationProps = {
    readonly path: string;
    readonly totalPages: number;
    readonly offset: number;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly search: Search;
    readonly isMapView?: boolean;
    readonly dispatchSearch?: React.Dispatch<SearchReducerAction>;
};

type PageUrl = { link?: string; a11yText: string };

const ProductListPagination = ({
    dispatchSearch,
    initialPageFilter,
    isMapView,
    offset,
    path,
    search,
    totalPages,
}: ProductListPaginationProps): JSX.Element | null => {
    const intl = useIntl();
    const currentPage = search.pagination?.currentPage;
    if (!currentPage) {
        return null;
    }

    const getPageUrl = (targetPage: number, hasLink = true): PageUrl => ({
        link: hasLink
            ? toPageUrl({
                  search,
                  path,
                  initialPageFilter,
                  targetPage,
                  currentPage,
                  totalPages,
                  offset,
              })
            : undefined,
        a11yText: intl.formatMessage(messages.page, { targetPage }),
    });

    const onPaginationClick = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        const target = event.target as HTMLButtonElement;
        const type = SearchReducerActionType.Pagination;
        let value = +target?.innerText;
        let clickElemnt;

        if (!value) {
            const classList = target.classList.toString().toLowerCase();

            if (classList.includes('left')) {
                value = currentPage - 1;
                clickElemnt = 'Pagination previous';
            } else if (classList.includes('right')) {
                value = currentPage + 1;
                clickElemnt = 'Pagination next';
            }
        }

        const newPageLink = getPageUrl(value).link;

        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.ClickPagination,
                feature_category: GA4FeatureCategory.ProductList,
                click_element: clickElemnt || 'Pagination selector',
                click_value: value,
                click_url: window.location.origin + (newPageLink || ''),
            },
        });

        if (!isMapView) {
            if (newPageLink) window.location.href = newPageLink;
            return;
        }

        if (dispatchSearch) dispatchSearch({ type, value });
    };

    return (
        <div
            className={classNames('d-flex align-items-center justify-content-center mt-2x', {
                [styles.paginationWrapper]: !isMapView,
                'mb-2x': isMapView,
            })}
            data-testid="product-list-pagination"
        >
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                getPageUrl={getPageUrl}
                onPaginationClick={onPaginationClick}
            />
        </div>
    );
};

export { ProductListPagination };
