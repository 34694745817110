import { type GA4FilterListType } from '@jsmdg/tracking';
import { AttributeName } from '../../../../shared/enums/attributeName';
import { FacetType } from '../../../../shared/enums/facetType';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type Filter, type RangeFilter } from '../../../../shared/types/search';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { DurationFilter } from '../Duration/DurationFilter';
import { MultiSelect } from '../MultiSelect/MultiSelect';
import { ParticipantsFilter } from '../Participants/ParticipantsFilter';
import { Toggle } from '../Toggle/Toggle';
import { TravelNightsFilter } from '../TravelNights/TravelNightsFilter';

type ProductFacetProps = {
    readonly facet: FacetFilter;
    readonly onFilterChange: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly filter: Filter;
    readonly listType?: GA4FilterListType;
    readonly selectedOptions?: string[] | RangeFilter[];
    readonly isMobileFilter?: boolean;
    readonly paginationLimit?: number;
};

const ProductFacet = ({
    facet,
    filter,
    isMobileFilter,
    listType,
    onFilterChange,
    paginationLimit,
    selectedOptions,
}: ProductFacetProps): JSX.Element => {
    switch (facet.type) {
        case FacetType.MultiSelect:
        case FacetType.String:
        case FacetType.Quantity: {
            if (facet.attribute === AttributeName.Participants) {
                return (
                    <ParticipantsFilter
                        facet={facet}
                        selectedOptions={(selectedOptions || []) as RangeFilter[]}
                        onSubmit={onFilterChange}
                        isMobileFilter={isMobileFilter}
                        paginationLimit={paginationLimit}
                        filter={filter}
                        listType={listType}
                    />
                );
            }

            return (
                <MultiSelect
                    key={facet.name}
                    facet={facet}
                    onFilterChange={onFilterChange}
                    selectedOptions={(selectedOptions || []) as string[]}
                    isMobileFilter={isMobileFilter}
                    filter={filter}
                    listType={listType}
                />
            );
        }

        case FacetType.Numeric: {
            if (facet.attribute === AttributeName.TravelNights) {
                return (
                    <TravelNightsFilter
                        facet={facet}
                        selectedOptions={(selectedOptions || []) as RangeFilter[]}
                        onSubmit={onFilterChange}
                        isMobileFilter={isMobileFilter}
                        paginationLimit={paginationLimit}
                        filter={filter}
                        listType={listType}
                    />
                );
            }

            return (
                <MultiSelect
                    key={facet.name}
                    facet={facet}
                    onFilterChange={onFilterChange}
                    selectedOptions={(selectedOptions || []) as string[]}
                    isMobileFilter={isMobileFilter}
                    filter={filter}
                    listType={listType}
                />
            );
        }

        case FacetType.Boolean: {
            return (
                <div key={facet.name}>
                    <Toggle
                        label={facet.name}
                        isActive={!!selectedOptions}
                        isMobileFilter={isMobileFilter}
                        onSubmit={(value: boolean) =>
                            onFilterChange(
                                SearchReducerActionType.Filter,
                                [value ? 'true' : undefined],
                                facet.attribute,
                            )
                        }
                    />
                </div>
            );
        }

        case FacetType.Duration: {
            return (
                <div key={facet.name}>
                    <DurationFilter
                        attribute={facet.attribute}
                        values={facet.values}
                        selectedOptions={(selectedOptions || []) as RangeFilter[]}
                        onSubmit={onFilterChange}
                        isMobileFilter={isMobileFilter}
                        paginationLimit={paginationLimit}
                        filter={filter}
                        listType={listType}
                    />
                </div>
            );
        }

        default:
            return <div key={facet.name}>{`${facet.name} ${facet.type}`}</div>;
    }
};

export { ProductFacet };
