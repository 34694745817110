import classnames from 'classnames';

import { Button, ButtonColor, ButtonSize, ButtonVariant, RenderType } from '@jsmdg/yoshi';
import { ButtonBarAlignment } from '../../../shared/enums/buttonBarAlignment';
import { type ButtonBarButton } from '../../../shared/types/pageContent';
import styles from './LinkButtonBar.module.scss';

type LinkButtonBarProps = {
    readonly alignment?: ButtonBarAlignment;
    readonly size?: ButtonSize;
    readonly variant?: ButtonVariant;
    readonly buttons: ButtonBarButton[];
};

enum OldButtonVariant {
    Primary = 'primary',
    Secondary = 'secondary',
}

type ButtonMixVariant = OldButtonVariant | ButtonVariant;

/**
 * @TODO remove this func after making a proper fix in pimcore
 * this is a temp solution because in pimcore we still have the old
 * button variants
 */
const convertButtomVariant = (variant: ButtonMixVariant): ButtonVariant => {
    switch (variant) {
        case OldButtonVariant.Primary:
            return ButtonVariant.Contained;
        case OldButtonVariant.Secondary:
            return ButtonVariant.Outlined;
        default:
            return variant as ButtonVariant;
    }
};

const LinkButtonBar = ({
    alignment = ButtonBarAlignment.Right,
    buttons,
    size = ButtonSize.Large,
    variant = ButtonVariant.Contained,
}: LinkButtonBarProps): JSX.Element => (
    <div
        className={classnames(
            'd-flex flex-column flex-sm-row',
            {
                [ButtonBarAlignment.Left]: 'justify-content-start',
                [ButtonBarAlignment.Center]: 'justify-content-center',
                [ButtonBarAlignment.Right]: 'justify-content-end',
            }[alignment],
        )}
    >
        {buttons.map(button => (
            <Button
                as={RenderType.Link}
                key={`link-${button.label}`}
                className={styles.linkButton}
                href={button?.link?.url}
                variant={convertButtomVariant(variant)}
                size={size}
                target={button.target}
                color={ButtonColor.Complementary}
            >
                {button.label}
            </Button>
        ))}
    </div>
);

export { LinkButtonBar };
