import { useEffect, useReducer } from 'react';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Product } from '../../shared/types/searchResponse';

enum ActionType {
    Loading = 'loading',
    Success = 'success',
    Error = 'error',
}

type Action =
    | { type: ActionType.Loading }
    | { type: ActionType.Success; payload: Product[] }
    | { type: ActionType.Error };

type State = {
    products: Product[];
    loading: boolean;
};

const initialState: State = {
    products: [],
    loading: true,
};

const sortProducts = (productIdArray: string[], fetchedProductItems: Product[]): Product[] => {
    return productIdArray
        .map((id: string): Product | undefined =>
            fetchedProductItems.find((product: Product): boolean => product.productId === id),
        )
        .filter((productItem?: Product): boolean => productItem !== undefined) as Product[];
};

function productsReducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.Loading:
            return { ...state, loading: true };
        case ActionType.Success:
            return { products: action.payload, loading: false };
        case ActionType.Error:
            return { ...state, loading: false };
        default:
            throw new Error('Unhandled action type');
    }
}

export const useLastSeenProducts = (productIds: string[]) => {
    const { fetchProducts } = useFragmentContext<FragmentContext>();
    const [state, dispatch] = useReducer(productsReducer, initialState);

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: ActionType.Loading });

            try {
                if (productIds.length) {
                    const fetchedProducts = await fetchProducts({
                        filter: {
                            productId: productIds,
                        },
                    });

                    const sortedProducts = sortProducts(productIds, fetchedProducts.items);
                    dispatch({ type: ActionType.Success, payload: sortedProducts });
                } else {
                    dispatch({ type: ActionType.Success, payload: [] });
                }
            } catch {
                dispatch({ type: ActionType.Error });
            }
        };

        fetchData();
    }, [fetchProducts, productIds]);

    return state;
};
