import { useEffect } from 'react';

import { SearchStatus } from '../../shared/enums/searchStatus';
import { type Filter, type Pagination, type Sorting } from '../../shared/types/search';
import { toUrlSearchParameters } from '../helper/mapUrlParameters';
import { type InitialPageFilterType } from '../types';

type UseUrlQueryUpdateProps = {
    filter: Filter;
    status: SearchStatus;
    pagination?: Pagination;
    searchTerm?: string;
    initialPageFilter?: InitialPageFilterType;
    sorting?: Sorting;
};

export const useUrlQueryUpdate = ({
    filter,
    status,
    pagination,
    searchTerm,
    initialPageFilter,
    sorting,
}: UseUrlQueryUpdateProps): void => {
    useEffect(() => {
        if (status === SearchStatus.Resolved) {
            const queryString = toUrlSearchParameters(
                { searchTerm, filter, sorting, pagination },
                initialPageFilter,
            );
            window.history.replaceState({}, '', `?${queryString}`);
        }
    }, [filter, initialPageFilter, pagination, searchTerm, sorting, status]);
};
