import { PageType } from '../../../shared/enums/pageType';
import { type Search } from '../../../shared/types/search';
import { NoSearchTermResult } from '../NoResult/NoSearchTermResult';
import { ProductList } from '../ProductList/ProductList';

type SearchPageProps = {
    readonly initialSearch?: Search;
    readonly indexId: string;
};

const SearchPage = ({ indexId, initialSearch }: SearchPageProps): JSX.Element => {
    const searchTerm = initialSearch?.searchTerm;

    return (
        <ProductList
            key="product_list"
            initialSearch={initialSearch}
            lazyLoad={false}
            pageType={PageType.SearchPage}
            indexId={indexId}
            noResultFallback={<NoSearchTermResult searchTerm={searchTerm} />}
        />
    );
};

export { SearchPage };
