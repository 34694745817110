import { SortingField, SortingOrder, targetGroupSortingMap } from '../enums/sorting';
import { type TargetGroup } from '../enums/targetGroup';
import { type Sorting } from '../types/search';

export const getSortingByTargetGroup = (
    sorting?: Sorting,
    targetGroup?: TargetGroup,
): Sorting | undefined => {
    if (!sorting && !targetGroup) {
        return {
            field: SortingField.SalesRank,
            order: SortingOrder.Desc,
        };
    }

    return targetGroup && (!sorting || sorting.field === SortingField.SalesRank)
        ? {
              field: targetGroupSortingMap[targetGroup],
              order: SortingOrder.Desc,
          }
        : sorting;
};
