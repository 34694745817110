import { type SearchResponses } from 'algoliasearch/lite';
import axios from 'axios';
import { type SearchOptions } from 'instantsearch.js';

import { isSSR } from '@jsmdg/yoshi';

const baseURL = isSSR ? 'http://localhost:3001' : undefined;

async function searchHits<T>(
    requests: Array<{ indexName: string; params: SearchOptions }>,
): Promise<SearchResponses<T>> {
    const result = await axios.post<SearchResponses<T>>('/api/search', requests, { baseURL });
    return result.data;
}

export { searchHits };
