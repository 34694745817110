import { type MultiSelectOption } from '@jsmdg/yoshi/dist/types/selectOption';
import { AttributeName } from '../../../../shared/enums/attributeName';
import { type FacetFilter } from '../../../../shared/types/facetFilter';

const getSortedOptions = (facet: FacetFilter, selectedOptions?: string[]): MultiSelectOption[] => {
    const isNumeric =
        facet.attribute === AttributeName.Height ||
        facet.attribute === AttributeName.Length ||
        facet.attribute === AttributeName.MinimumAge ||
        facet.attribute === AttributeName.Rating ||
        facet.attribute === AttributeName.Depth;

    const options: MultiSelectOption[] = facet.values?.map(value => {
        return {
            label: value.name,
            value: value.name,
            secondaryText: value.count ? value.count.toString() : undefined,
            isSelected: !!selectedOptions?.includes(value.name),
        };
    });

    selectedOptions?.forEach(selectedOption => {
        if (facet.values.findIndex(value => value.name === selectedOption) === -1) {
            options.push({
                label: selectedOption,
                value: selectedOption,
                isSelected: true,
            });
        }
    });

    if (isNumeric) {
        return options.sort(
            (a, b) =>
                Number(a.label.replaceAll(/\D+/g, '')) - Number(b.label.replaceAll(/\D+/g, '')),
        );
    }

    return options.sort(
        (
            { secondaryText: option1NumResults = '0' },
            { secondaryText: option2NumResults = '0' },
        ): number => Number(option2NumResults) - Number(option1NumResults),
    );
};

export { getSortedOptions };
