import { type Filter } from '../../../shared/types/search';
import { type InitialPageFilterType } from '../../types';

const hasInitialLocation = (filter: Filter, initialPageFilter?: InitialPageFilterType): boolean =>
    filter?.location?.name === initialPageFilter?.geoDistanceFilter?.location?.name &&
    filter?.location?.lat === initialPageFilter?.geoDistanceFilter?.location?.lat &&
    filter?.location?.long === initialPageFilter?.geoDistanceFilter?.location?.long &&
    filter?.location?.distance === initialPageFilter?.geoDistanceFilter?.distance;

const hasInitialPrice = (filter: Filter, initialPageFilter?: InitialPageFilterType): boolean =>
    !filter.price ||
    (!('min' in filter.price) && !('max' in filter.price)) ||
    (filter.price.min === initialPageFilter?.priceFilter?.min &&
        filter.price.max === initialPageFilter?.priceFilter?.min);

export { hasInitialLocation, hasInitialPrice };
