import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import { type GA4ClickEvent } from '@jsmdg/tracking/dist/events';
import { type SlidingDirections } from '@jsmdg/yoshi';
import { type CreativeName } from '../enums/trackingPromotions';

type TrackSlideProps = {
    direction: SlidingDirections;
    category: string;
    eventData?: GA4ClickEvent;
};

export function trackSlide({ direction, category, eventData }: TrackSlideProps): void {
    trackAnalyticsEvent({
        category,
        action: 'Slide',
        label: direction,
        eventData,
    });
}

export async function trackSlideNavigationClick(
    direction: SlidingDirections,
    verticalPosition: string,
    promotionType: CreativeName,
): Promise<void> {
    await window.yieldToMainThread();
    trackAnalyticsEvent({
        category: 'InternalPromotion',
        action: 'Slide',
        label: direction,
        eventData: {
            eventName: GA4EventName.SlidePromotionItem,
            feature_category: GA4FeatureCategory.PromotionStatic,
            PromotionType: promotionType,
            position_vertical: verticalPosition?.toString(),
            click_type: `Slide ${direction.toLocaleLowerCase()}`,
        },
    });
}
