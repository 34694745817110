const enhanceUrlWithSearchParams = (url: string): string => {
    const currentUrlSearchParams = new URLSearchParams(window.location.search);
    const location = {
        lat: currentUrlSearchParams.get('lat'),
        long: currentUrlSearchParams.get('long'),
        locationName: currentUrlSearchParams.get('locationName'),
        distance: currentUrlSearchParams.get('distance'),
    };

    const urlSearchParams = new URLSearchParams({
        ...(Object.values(location).every(Boolean)
            ? { ...(location as Record<string, string>) }
            : {}),
    }).toString();

    return urlSearchParams.length && !url.includes('locationName=')
        ? `${url}?${urlSearchParams}`
        : url;
};

export { enhanceUrlWithSearchParams };
