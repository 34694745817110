import { useMemo } from 'react';
import { createFallbackableCache } from '@algolia/cache-common';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { type LiteClient, liteClient } from 'algoliasearch/lite';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { getAlgoliaIndex } from '../../shared/helpers/getAlgoliaIndex';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Sorting } from '../../shared/types/search';
import { createBrowserLocalStorageCache } from '../helper/createBrowserLocalStorageCache';

type UseAlgoliaReturn = {
    client: LiteClient;
    indexName: string;
};

const responsesCache = createFallbackableCache({
    caches: [
        createBrowserLocalStorageCache({
            key: 'algolia-responses',
            timeToLive: 900, // 15min
        }),
        createInMemoryCache(),
    ],
});

const requestsCache = createFallbackableCache({
    caches: [
        createBrowserLocalStorageCache({
            key: `algolia-requests`,
            timeToLive: 900, // 15min
        }),
        createInMemoryCache(),
    ],
});

export const useAlgolia = (sorting?: Sorting): UseAlgoliaReturn => {
    const { algoliaConfig, locale, tenant, env, searchHits } =
        useFragmentContext<FragmentContext>();
    const client: LiteClient = useMemo(
        () =>
            liteClient(algoliaConfig.appId, algoliaConfig.searchKey, {
                timeouts: algoliaConfig.timeouts,
                hosts: [
                    {
                        url: `${algoliaConfig.appId}-dsn.algolia.net`,
                        accept: 'read',
                        protocol: 'https',
                    },
                    {
                        url: `${algoliaConfig.appId}-1.algolia.net`,
                        accept: 'read',
                        protocol: 'https',
                    },
                    {
                        url: `${algoliaConfig.appId}-2.algolia.net`,
                        accept: 'read',
                        protocol: 'https',
                    },
                    {
                        url: `${algoliaConfig.appId}-3.algolia.net`,
                        accept: 'read',
                        protocol: 'https',
                    },
                ],
                responsesCache,
                // Caches Promises with the same request payload
                requestsCache,
            }),
        [algoliaConfig.appId, algoliaConfig.searchKey, algoliaConfig.timeouts, env],
    );

    const customSearchClient: LiteClient = useMemo(
        () => ({
            ...client,
            search: searchHits,
        }),
        [],
    );

    const indexName = useMemo(
        () =>
            getAlgoliaIndex({
                locale,
                tenant,
                environment: algoliaConfig.environment,
                sorting,
            }),
        [locale, tenant, algoliaConfig.environment, sorting],
    );

    return { client: customSearchClient, indexName };
};
