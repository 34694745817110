import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    GA4EventName,
    type GA4FilterListType,
    type GA4SortingType,
    trackFilterInteraction,
} from '@jsmdg/tracking';
import { Dropdown, SortingIcon } from '@jsmdg/yoshi';
import {
    MostPopularSortingFields,
    SortingField,
    SortingOrder,
} from '../../../../shared/enums/sorting';
import { type TargetGroup } from '../../../../shared/enums/targetGroup';
import { getSortingByTargetGroup } from '../../../../shared/helpers/getSortingByTargetGroup';
import { type Sorting as TSorting } from '../../../../shared/types/search';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import { MobileSorting } from './MobileSorting';
import styles from '../shared/SelectFilter.module.scss';

const messages = defineMessages({
    sortingLabel: {
        defaultMessage: 'Sortieren nach',
    },
    mostPopular: {
        defaultMessage: 'Beliebteste',
    },
    price_desc: {
        defaultMessage: 'Höchster Preis',
    },
    price_asc: {
        defaultMessage: 'Niedrigster Preis',
    },
    distance_asc: {
        defaultMessage: 'Entfernung',
    },
    rating_desc: {
        defaultMessage: 'Bewertung',
    },
});

type SortingOption = TSorting & { label: string };

type SortingProps = {
    readonly onSubmit: (type: SearchReducerActionType, value?: TSorting) => void;
    readonly includeDistanceSorting: boolean;
    readonly selectedSorting?: TSorting;
    readonly targetGroup?: TargetGroup;
    readonly isDesktop?: boolean;
    readonly listType: GA4FilterListType;
};

const Sorting = ({
    includeDistanceSorting,
    isDesktop,
    listType,
    onSubmit,
    selectedSorting,
    targetGroup,
}: SortingProps): JSX.Element => {
    const intl = useIntl();

    const defaultSorting = getSortingByTargetGroup(undefined, targetGroup);
    const availableSortings = [
        defaultSorting,
        includeDistanceSorting
            ? { field: SortingField.Distance, order: SortingOrder.Asc }
            : undefined,
        { field: SortingField.Price, order: SortingOrder.Asc },
        { field: SortingField.Price, order: SortingOrder.Desc },
        { field: SortingField.Rating, order: SortingOrder.Desc },
    ].filter(Boolean);

    const mapToSortingOption = (sorting?: TSorting): SortingOption => {
        if (!sorting) {
            return mapToSortingOption(defaultSorting);
        }

        return {
            ...sorting,
            label: intl.formatMessage(
                MostPopularSortingFields.includes(sorting.field)
                    ? messages.mostPopular
                    : messages[`${sorting.field}_${sorting.order}` as keyof typeof messages],
            ),
        };
    };

    const availableSortingOptions = availableSortings.map(sorting => mapToSortingOption(sorting));

    const selectedOptionValue = selectedSorting
        ? `${selectedSorting?.field}_${selectedSorting?.order}`
        : `${defaultSorting?.field || ''}_${defaultSorting?.order || ''}`;

    const options = availableSortingOptions.map(sorting => {
        return {
            label: sorting.label,
            value: `${sorting?.field}_${sorting?.order}`,
        };
    });

    const onSortingClick = async (sortingOption: React.Key): Promise<void> => {
        const [field, order] = sortingOption.toString().split('_') as [SortingField, SortingOrder];

        await window.yieldToMainThread();
        onSubmit(SearchReducerActionType.Sorting, {
            field,
            order,
        });

        const sortingType = sortingOption.toString().toLowerCase() as GA4SortingType;

        trackFilterInteraction('ButtonClick', sortingType, {
            eventName: GA4EventName.SetSorting,
            sorting_type: sortingType,
            list_type: listType,
        });
    };

    const onExpandDesktopSorting = (): void => {
        trackFilterInteraction('Expand_sorting', undefined, {
            eventName: GA4EventName.ExpandFilter,
            filter_type: 'sorting',
            list_type: listType,
        });
    };

    if (isDesktop) {
        return (
            <div
                className={classNames(
                    styles.sortingBlock,
                    'd-flex align-items-center ml-auto mr-0',
                )}
            >
                <Dropdown
                    onClick={onExpandDesktopSorting}
                    onChange={onSortingClick}
                    label=""
                    options={options}
                    value={selectedOptionValue}
                    leftComponent={<SortingIcon />}
                    dataTestId="product-sorting-button"
                    className={classNames(styles.sortingDropdown, 'ml-1-5x mr-0')}
                />
            </div>
        );
    }

    return (
        <div role="group" data-testid="sorting-map-view">
            <MobileSorting
                options={options}
                selected={selectedOptionValue}
                includeDistanceSorting={includeDistanceSorting}
                onSortingClick={onSortingClick}
            />
        </div>
    );
};

export { Sorting };
