import { ProductView, SettingStorageProperties } from '@jsmdg/browser-storage';
import { GridView } from '@jsmdg/yoshi';

export const getGridView = (settingCookie: Record<string, string>): GridView => {
    if (settingCookie?.[SettingStorageProperties.View]) {
        return settingCookie[SettingStorageProperties.View] === ProductView.List
            ? GridView.ListView
            : GridView.TileView;
    }

    return GridView.ListView;
};
