import { useContext } from 'react';

import { createAlgoliaSearchContext } from '../../shared/helpers/createAlgoliaSearchContext';
import { type AlgoliaSearchContext } from '../../shared/types/algoliaSearchContext';

const useAlgoliaContext = (): AlgoliaSearchContext => {
    return useContext(createAlgoliaSearchContext);
};

export { useAlgoliaContext };
