import axios from 'axios';

import { GooglePlacesApiStatus } from '../../shared/enums/googlePlaces';
import {
    type PlaceAutocompleteResponse,
    type PlaceDetailsResponse,
} from '../../shared/types/googlePlaces';
import { type Locale } from '../enums/locale';

const getLanguageFromLocale = (locale: string): Locale => {
    return locale.split('-')[0] as Locale;
};

type FetchPlacesSuggestionsParams = {
    input: string;
    sessionToken: string;
    locale: string;
};

const fetchPlacesSuggestions = async ({
    input,
    sessionToken,
    locale,
}: FetchPlacesSuggestionsParams): Promise<PlaceAutocompleteResponse> => {
    let result;
    try {
        result = await axios.get<PlaceAutocompleteResponse>('/api/places/suggestion', {
            params: {
                input,
                sessionToken,
                language: getLanguageFromLocale(locale),
            },
        });
    } catch {
        throw new Error(GooglePlacesApiStatus.TechError);
    }

    if (result.data.status === GooglePlacesApiStatus.ZeroResults) {
        throw new Error(GooglePlacesApiStatus.ZeroResults);
    }

    if (result.data.status !== GooglePlacesApiStatus.Ok) {
        throw new Error(GooglePlacesApiStatus.TechError);
    }

    return result.data;
};

type FetchPlacesDetailParams = {
    placeId: string;
    sessionToken: string;
    locale: string;
};

const fetchPlacesDetail = async ({
    placeId,
    sessionToken,
    locale,
}: FetchPlacesDetailParams): Promise<PlaceDetailsResponse> => {
    const result = await axios.get<PlaceDetailsResponse>('/api/places/detail', {
        params: {
            placeId,
            sessionToken,
            language: getLanguageFromLocale(locale),
            fields: 'formatted_address,geometry/location',
        },
    });

    return result.data;
};

export { fetchPlacesDetail, fetchPlacesSuggestions };
