import classNames from 'classnames';

import { GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import { ScrollSlider, type SlidingDirections } from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { type PageSliderData } from '../../../shared/types/pageContent';
import { TrackingCategory } from '../../enums/trackingCategory';
import { type TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { trackSlide } from '../../helper/trackSlide';
import { CategorySlide } from './CategorySlide';
import styles from './CategorySlider.module.scss';

type CategorySliderProps = {
    readonly slides: PageSliderData[];
    readonly verticalPosition: number;
    readonly hasNavigationCreative: boolean;
    readonly pageType: PageType;
    readonly trackingPrefix: TrackingCreativePrefix;
    readonly trackingPath?: string[];
};

const CategorySlider = ({
    hasNavigationCreative,
    pageType,
    slides,
    trackingPath,
    trackingPrefix,
    verticalPosition,
}: CategorySliderProps): JSX.Element | null => {
    const handleOnSlide = (direction: SlidingDirections): void =>
        trackSlide({
            direction,
            category: TrackingCategory.InternalPromotion,
            eventData: {
                eventName: GA4EventName.SlideNavigationItem,
                feature_category: GA4FeatureCategory.PageSliderNavigation,
                click_type: `Slide ${direction.toLowerCase()}`,
                position_vertical: verticalPosition.toString(),
            },
        });

    if (!slides.length) {
        return null;
    }

    return (
        <ScrollSlider
            shouldShowProgressBar={false}
            className={classNames('d-sm-none', styles.categorySliderWrapper)}
            onSlide={handleOnSlide}
        >
            {slides.map(({ linkUrl, title, trackingName }, index) => {
                return (
                    <CategorySlide
                        key={title}
                        linkUrl={linkUrl}
                        title={title}
                        position={`vertical${verticalPosition}_horizontal${index + 1}`}
                        positionHorizontal={`${index + 1}`}
                        positionVertical={`${
                            hasNavigationCreative ? verticalPosition - 1 : verticalPosition
                        }`}
                        pageType={pageType}
                        trackingName={trackingName}
                        trackingPrefix={trackingPrefix}
                        trackingPath={trackingPath}
                    />
                );
            })}
        </ScrollSlider>
    );
};

export { CategorySlider };
