import { useEffect, useMemo, useState } from 'react';
import { Configure, Index } from 'react-instantsearch';
import { defineMessages, useIntl } from 'react-intl';
import loadable from '@loadable/component';

import { getSettingStorage, ProductView, SettingStorageProperties } from '@jsmdg/browser-storage';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventLabel, trackPLPViewChange } from '@jsmdg/tracking';
import { GridView, Notification } from '@jsmdg/yoshi';
import { NotificationVariant } from '@jsmdg/yoshi/dist/enums/notification';
import { AttributeIndexField } from '../../../shared/enums/indexField';
import { ObjectType } from '../../../shared/enums/objectType';
import { type PageType } from '../../../shared/enums/pageType';
import { SortingField, SortingOrder } from '../../../shared/enums/sorting';
import { getAlgoliaIndex } from '../../../shared/helpers/getAlgoliaIndex';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Filter, type Search, type Sorting } from '../../../shared/types/search';
import { AlgoliaIndexId } from '../../enums/algoliaIndexId';
import { getGridView } from '../../helper/getGridView';
import { HoverProvider } from '../../helper/mapViewHoverProvider';
import { useAlgoliaProductSearchState } from '../../hooks/useAlgoliaProductSearchState';
import { useSearchState } from '../../hooks/useSearchState';
import { eventTypes, type InitialPageFilterType } from '../../types';
import { ProductListContent } from './ProductListContent';
import { ProductListFilters } from './ProductListFilters';
import { ProductListSearchHeadline } from './ProductListSearchHeadline';

const LoadableMapViewModal = loadable(async () => import('../MapView/MapViewModal'));

type ProductListProps = {
    readonly indexId: string;
    readonly initialSearch?: Search;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly lazyLoad: boolean;
    readonly hasUiFilters?: boolean;
    readonly showLocationFilter?: boolean;
    readonly verticalPosition?: number;
    readonly pageType: PageType;
    readonly pageId?: string;
    readonly pageTitle?: string;
    readonly isLastList?: boolean;
    readonly noResultFallback?: JSX.Element;
    readonly trackingName?: string;
};

const messages = defineMessages({
    tileViewText: {
        defaultMessage: 'Kachel',
    },
    listViewText: {
        defaultMessage: 'Liste',
    },
    isFailedAlgoliaRequest: {
        defaultMessage:
            'Da wir gerade unser Produktportfolio überarbeiten, siehst du eine kategorieübergreifende Auswahl unserer Erlebnisse. Schaue in wenigen Minuten wieder vorbei, um die volle Produktvielfalt zu entdecken.',
    },
    closeNotification: {
        defaultMessage: 'Schließen',
    },
});

const ProductList = ({
    hasUiFilters = true,
    indexId,
    initialPageFilter,
    initialSearch,
    isLastList = true,
    lazyLoad,
    noResultFallback,
    pageId = '',
    pageTitle = '',
    pageType,
    showLocationFilter = true,
    trackingName,
    verticalPosition = 0,
}: ProductListProps): JSX.Element => {
    const { algoliaConfig, locale, settingCookie, tenant } = useFragmentContext<FragmentContext>();
    const [isMapViewModalOpen, setIsMapViewModalOpen] = useState(false);
    const defaultGridView = getGridView(settingCookie);
    const [gridView, setGridView] = useState(defaultGridView);
    const { dispatchSearch, geoLocationError, searchState } = useSearchState(
        gridView,
        isMapViewModalOpen,
        initialSearch,
    );

    const isFailedAlgoliaRequest = algoliaConfig.serverState?.isFailedRequest;
    const [showNotification, setShowNotification] = useState(isFailedAlgoliaRequest);

    const initialFilter = useMemo(
        () => ({
            ...initialSearch?.filter,
            travelNights: initialPageFilter?.travelNightsFilter,
            price: initialPageFilter?.priceFilter,
            location: initialPageFilter?.geoDistanceFilter?.location
                ? {
                      ...initialPageFilter?.geoDistanceFilter?.location,
                      distance: initialPageFilter?.geoDistanceFilter?.distance,
                  }
                : null,
            participants: initialPageFilter?.participants,
            topCountryCodes: initialPageFilter?.topCountryCodes,
            productAttributes: initialPageFilter?.productAttributes,
        }),
        [initialPageFilter, initialSearch?.filter],
    );

    const getIndex = (objectType: ObjectType, sorting?: Sorting): string =>
        getAlgoliaIndex({
            environment: algoliaConfig.environment,
            locale,
            tenant,
            objectType,
            sorting,
        });

    const productIndex = getIndex(
        ObjectType.Product,
        !isFailedAlgoliaRequest
            ? searchState.sorting
            : { field: SortingField.SalesRank, order: SortingOrder.Desc },
    );
    const attributeIndex = getIndex(ObjectType.Attribute);

    const intl = useIntl();

    const gridViewText = {
        tileViewText: intl.formatMessage(messages.tileViewText),
        listViewText: intl.formatMessage(messages.listViewText),
    };

    const onGridSwitch = (view: GridView): void => {
        setGridView(view);
        getSettingStorage().setItem(
            SettingStorageProperties.View,
            view === GridView.ListView ? ProductView.List : ProductView.Tile,
        );
        trackPLPViewChange(
            view === GridView.TileView ? gridViewText.tileViewText : gridViewText.listViewText,
            view === GridView.TileView ? GA4EventLabel.TileView : GA4EventLabel.ListView,
        );
    };

    const algoliaSearchState = useAlgoliaProductSearchState({
        indexName: productIndex,
        search: searchState,
        pageId,
        userToken: algoliaConfig.userToken,
    });

    useEffect(() => {
        const handleCustomEvent = (): void => {
            setIsMapViewModalOpen(true);
        };

        document.addEventListener(eventTypes.SHOW_MAP_VIEW, handleCustomEvent);

        return () => {
            document.removeEventListener(eventTypes.SHOW_MAP_VIEW, handleCustomEvent);
        };
    }, []);

    return (
        <>
            <ProductListSearchHeadline
                pageType={pageType}
                searchState={searchState}
                initialSearch={initialSearch}
                productIndexId={indexId}
                productIndexName={productIndex}
                showLocationFilter={showLocationFilter && !isFailedAlgoliaRequest}
            />
            {showNotification && (
                <Notification
                    variant={NotificationVariant.Warning}
                    message={intl.formatMessage(messages.isFailedAlgoliaRequest)}
                    a11yText={intl.formatMessage(messages.closeNotification)}
                    onRequestClose={() => setShowNotification(false)}
                />
            )}
            <Index indexName={attributeIndex} indexId={AlgoliaIndexId.Attributes}>
                <Configure
                    hitsPerPage={1_000}
                    attributesToRetrieve={Object.values(AttributeIndexField)}
                    attributesToHighlight={[]}
                    attributesToSnippet={[]}
                />

                <ProductListFilters
                    searchState={searchState}
                    dispatchSearch={dispatchSearch}
                    initialSearch={initialSearch}
                    initialPageFilter={initialPageFilter}
                    initialFilter={initialFilter as Filter}
                    hasUiFilters={hasUiFilters}
                    showLocationFilter={showLocationFilter}
                    geoLocationError={geoLocationError}
                    onGridSwitch={onGridSwitch}
                    gridView={gridView}
                    isFailedAlgoliaRequest={isFailedAlgoliaRequest}
                    productIndexId={indexId}
                    productIndexName={productIndex}
                />
            </Index>
            <Index indexName={productIndex} indexId={indexId}>
                <Configure {...algoliaSearchState} />
                <ProductListContent
                    hasUiFilters={hasUiFilters && !isFailedAlgoliaRequest}
                    showLocationFilter={showLocationFilter && !isFailedAlgoliaRequest}
                    isLastList={isLastList}
                    pageType={pageType}
                    initialPageFilter={initialPageFilter}
                    noResultFallback={noResultFallback}
                    lazyLoad={lazyLoad}
                    verticalPosition={verticalPosition}
                    pageId={pageId}
                    pageTitle={pageTitle}
                    searchState={searchState}
                    indexName={productIndex}
                    gridView={gridView}
                    trackingName={trackingName}
                />
                {isMapViewModalOpen && !isFailedAlgoliaRequest && (
                    <HoverProvider>
                        <LoadableMapViewModal
                            initialSearch={initialSearch}
                            showLocationFilter={showLocationFilter}
                            pageType={pageType}
                            initialPageFilter={initialPageFilter}
                            initialFilter={{ ...initialFilter, boundary: null } as Filter}
                            lazyLoad={lazyLoad}
                            pageId={pageId}
                            indexName={productIndex}
                            pageTitle={pageTitle}
                            searchState={searchState}
                            dispatchSearch={dispatchSearch}
                            geoLocationError={geoLocationError}
                            isMapViewModalOpen={isMapViewModalOpen}
                            setModalOpen={setIsMapViewModalOpen}
                        />
                    </HoverProvider>
                )}
            </Index>
        </>
    );
};

export { ProductList };
