import { TenantEnum } from '@jsmdg/tenancy';
import { CuratedPlpSliderLocation } from '../../shared/enums/curatedPlpSliderLocation';
import { ProductListItemType } from '../enums/productListItemType';
import { type ProductListItem } from '../types/productListItem';

export const useCuratedPlpSlider = (
    tenant?: TenantEnum,
    pageId?: string,
): ProductListItem | undefined => {
    if (!tenant || !pageId || tenant !== TenantEnum.MD_DE) return undefined;

    if (pageId === CuratedPlpSliderLocation.Kurzurlaub)
        return {
            key: 'curated-plp-slider',
            type: ProductListItemType.CuratedPlpSlider,
            headline: 'Beliebte Ziele für einen Kurzurlaub',
            content: [
                {
                    pageId: 'g6ukx',
                    title: 'Hamburg',
                    image: {
                        alt: 'Hamburg',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-Hamburg_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-Hamburg_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-Hamburg_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/kurzurlaub/l/g6ukx?lat=53.5488282&long=9.987170299999999&locationName=Hamburg%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
                {
                    pageId: 'g6ukx',
                    title: 'Berlin',
                    image: {
                        alt: 'Berlin',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-Berlin_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-Berlin_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-Berlin_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/kurzurlaub/l/g6ukx?lat=52.52000659999999&long=13.404954&locationName=Berlin%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
                {
                    pageId: 'g6ukx',
                    title: 'Köln',
                    image: {
                        alt: 'Köln',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-K%C3%B6ln_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-K%C3%B6ln_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Geschenke-K%C3%B6ln_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/kurzurlaub/l/g6ukx?lat=50.937531&long=6.9602786&locationName=K%C3%B6ln%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
                {
                    pageId: 'g6ukx',
                    title: 'München',
                    image: {
                        alt: 'München',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Erlebnisse-M%C3%BCnchen_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Erlebnisse-M%C3%BCnchen_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Erlebnisse-M%C3%BCnchen_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/kurzurlaub/l/g6ukx?lat=48.1351253&long=11.5819806&locationName=M%C3%BCnchen%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
            ],
        };

    if (pageId === CuratedPlpSliderLocation.Wellness)
        return {
            key: 'curated-plp-slider',
            type: ProductListItemType.CuratedPlpSlider,
            headline: 'Beliebte Ziele für einen Wellnesstrip',
            content: [
                {
                    pageId: 's4mj8',
                    title: 'Hamburg',
                    image: {
                        alt: 'Hamburg',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Wellness_Frauen_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Wellness_Frauen_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Wellness_Frauen_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/wellness/l/s4mj8?lat=53.5488282&long=9.987170299999999&locationName=Hamburg%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
                {
                    pageId: 's4mj8',
                    title: 'Berlin',
                    image: {
                        alt: 'Berlin',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/After_Work_Relaxing_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/After_Work_Relaxing_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/After_Work_Relaxing_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/wellness/l/s4mj8?lat=52.52000659999999&long=13.404954&locationName=Berlin%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
                {
                    pageId: 's4mj8',
                    title: 'Köln',
                    image: {
                        alt: 'Köln',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Floating-fu%CC%88r-zwei_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Floating-fu%CC%88r-zwei_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Floating-fu%CC%88r-zwei_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/wellness/l/s4mj8?lat=50.937531&long=6.9602786&locationName=K%C3%B6ln%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
                {
                    pageId: 's4mj8',
                    title: 'München',
                    image: {
                        alt: 'München',
                        url: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Spa_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Spa_700x600.jpg',
                        src: 'https://main.static.jsmd-group.com/assets/new_default_upload_bucket/Spa_700x600.jpg',
                        srcset: '',
                        sizes: '',
                    },
                    url: '/wellness/l/s4mj8?lat=48.1351253&long=11.5819806&locationName=M%C3%BCnchen%2C+Deutschland&distance=25&sortBy=distance%3Aasc',
                },
            ],
        };

    if (pageId === CuratedPlpSliderLocation.ForCouples)
        return {
            key: 'curated-plp-slider',
            type: ProductListItemType.CuratedPlpSlider,
            headline: 'Beliebte Erlebnisse für Paare',
            content: [
                {
                    pageId: 'chbyz',
                    title: 'Städtereise ab 160 €',
                    image: {
                        alt: 'Städtereise',
                        url: '/assets/new_default_upload_bucket/städtereisen_700x600px_1.jpg',
                        width: 480,
                        height: 480,
                        originalSrc: '/assets/new_default_upload_bucket/städtereisen_700x600_2.jpg',
                        src: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2Fst%25C3%25A4dtereisen_700x600px_1.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0',
                        srcset: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2Fst%25C3%25A4dtereisen_700x600px_1.jpg?w=198&h=156&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=a0efbbfce1155af499cea3586120313f 1w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2Fst%25C3%25A4dtereisen_700x600px_1.jpg?w=276&h=190&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=581eb49705ba9809c618a771d31201a9 2w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2Fst%25C3%25A4dtereisen_700x600px_1.jpg?w=198&h=156&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=35&s=89fbb98a13828627661a89e4eeef82b1 15w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2Fst%25C3%25A4dtereisen_700x600px_1.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0 16w,',
                        sizes: '(max-width:480px) and (-webkit-device-pixel-ratio:1) 1px,(min-width:481px) and (-webkit-device-pixel-ratio:1) 2px,(max-width:480px) and (-webkit-min-device-pixel-ratio:2) 3px,(min-width:481px) and (-webkit-min-device-pixel-ratio:2) 16px',
                    },
                    url: '/kurzurlaub/staedtereisen/l/chbyz?priceMin=160&priceMax=190&sortBy=price%3Aasc',
                },
                {
                    pageId: 'gb6tm',
                    title: 'Wellnesshotel ab 170 €',
                    image: {
                        alt: 'Wellnesshotel',
                        url: '/assets/new_default_upload_bucket/Wellnesshotel-NRW_700x600_1.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            '/assets/new_default_upload_bucket/Wellnesshotel-NRW_700x600_1.jpg',
                        src: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FWellnesshotel-NRW_700x600_1.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0',
                        srcset: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FWellnesshotel-NRW_700x600_1.jpg?w=198&h=156&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=a0efbbfce1155af499cea3586120313f 1w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FWellnesshotel-NRW_700x600_1.jpg?w=276&h=190&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=581eb49705ba9809c618a771d31201a9 2w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FWellnesshotel-NRW_700x600_1.jpg?w=198&h=156&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=35&s=89fbb98a13828627661a89e4eeef82b1 15w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FWellnesshotel-NRW_700x600_1.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0 16w,',
                        sizes: '(max-width:480px) and (-webkit-device-pixel-ratio:1) 1px,(min-width:481px) and (-webkit-device-pixel-ratio:1) 2px,(max-width:480px) and (-webkit-min-device-pixel-ratio:2) 3px,(min-width:481px) and (-webkit-min-device-pixel-ratio:2) 16px',
                    },
                    url: '/wellnesshotel/l/gb6tm?priceMin=170&priceMax=200&sortBy=price%3Aasc',
                },
                {
                    pageId: 'gj5c4',
                    title: 'Romantisches Wochenende ab 200 €',
                    image: {
                        alt: 'Romantisches Wochenende',
                        url: '/assets/new_default_upload_bucket/Romantikwochenende_Winter_2_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            '/assets/new_default_upload_bucket/Romantikwochenende_Winter_2_700x600.jpg',
                        src: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FRomantikwochenende_Winter_2_700x600.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0',
                        srcset: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FRomantikwochenende_Winter_2_700x600.jpg?w=198&h=156&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=a0efbbfce1155af499cea3586120313f 1w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FRomantikwochenende_Winter_2_700x600.jpg?w=276&h=190&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=581eb49705ba9809c618a771d31201a9 2w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FRomantikwochenende_Winter_2_700x600.jpg?w=198&h=156&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=35&s=89fbb98a13828627661a89e4eeef82b1 15w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FRomantikwochenende_Winter_2_700x600.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0 16w,',
                        sizes: '(max-width:480px) and (-webkit-device-pixel-ratio:1) 1px,(min-width:481px) and (-webkit-device-pixel-ratio:1) 2px,(max-width:480px) and (-webkit-min-device-pixel-ratio:2) 3px,(min-width:481px) and (-webkit-min-device-pixel-ratio:2) 16px',
                    },
                    url: '/kurzurlaub/romantisches-wochenende/l/gj5c4?priceMin=200&priceMax=250&sortBy=price%3Aasc',
                },
                {
                    pageId: 'nmb4c',
                    title: 'Außergewöhnliche Hotels ab 90 €',
                    image: {
                        alt: 'Außergewöhnliche Hotels',
                        url: '/assets/new_default_upload_bucket/Aussergewoehnliche-Hotels_700x600.jpg',
                        width: 480,
                        height: 480,
                        originalSrc:
                            '/assets/new_default_upload_bucket/Aussergewoehnliche-Hotels_700x600.jpg',
                        src: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FAussergewoehnliche-Hotels_700x600.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0',
                        srcset: '/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FAussergewoehnliche-Hotels_700x600.jpg?w=198&h=156&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=a0efbbfce1155af499cea3586120313f 1w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FAussergewoehnliche-Hotels_700x600.jpg?w=276&h=190&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&q=75&s=581eb49705ba9809c618a771d31201a9 2w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FAussergewoehnliche-Hotels_700x600.jpg?w=198&h=156&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=35&s=89fbb98a13828627661a89e4eeef82b1 15w,/images/https%3A%2F%2Fmain.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FAussergewoehnliche-Hotels_700x600.jpg?w=276&h=190&fit=max&auto=format%2Ccompress&cs=srgb&dpr=2&q=50&s=0508098ee51147f5d8eb1458fcefc9a0 16w,',
                        sizes: '(max-width:480px) and (-webkit-device-pixel-ratio:1) 1px,(min-width:481px) and (-webkit-device-pixel-ratio:1) 2px,(max-width:480px) and (-webkit-min-device-pixel-ratio:2) 3px,(min-width:481px) and (-webkit-min-device-pixel-ratio:2) 16px',
                    },
                    url: '/kurzurlaub/aussergewoehnliche-hotels/l/nmb4c?priceMin=90&priceMax=160&sortBy=price%3Aasc',
                },
            ],
        };

    return undefined;
};
