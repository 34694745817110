import { type ReactElement } from 'react';
import { defineMessages, type IntlShape, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
    trackEecPromotionClick,
    trackEecPromotionImpression,
} from '@jsmdg/tracking';
import { Banner, Wysiwyg } from '@jsmdg/yoshi';
import { CreativePlaceholderId } from '../../../../shared/enums/creativePlaceholderId';
import { type PageType as Type } from '../../../../shared/enums/pageType';
import { type CampaignCreative } from '../../../../shared/types/campaignCreative';
import { trackingTypeByPage } from '../../../enums/trackingPageTypes';
import { CreativeName, PromotionType } from '../../../enums/trackingPromotions';
import { isGifImageUrl } from '../../../helper/isGifImageUrl';
import { useImpressionTracking } from '../../../hooks';

type BannerProps = {
    readonly creative: CampaignCreative;
    readonly index: number;
    readonly verticalPosition: number;
    readonly listCreativeIndex: number;
    readonly className: string;
    readonly pageType: Type;
    readonly isListView: boolean;
};

const messages = defineMessages({
    day: {
        defaultMessage: 'Tag',
    },
    days: {
        defaultMessage: 'Tagen',
    },
    hour: {
        defaultMessage: 'Stunde',
    },
    hours: {
        defaultMessage: 'Stunden',
    },
    minute: {
        defaultMessage: 'Minute',
    },
    minutes: {
        defaultMessage: 'Minuten',
    },
    seconds: {
        defaultMessage: 'Sekunden',
    },
});

const isFullWidthBanner = (placeholderId: CreativePlaceholderId): boolean => {
    return [
        CreativePlaceholderId.SelectionBlockDesktop,
        CreativePlaceholderId.ProductTileMobile,
    ].includes(placeholderId);
};

const getPromotionTrackingPayload = ({
    creative,
    pageType,
    position,
}: {
    creative: CampaignCreative;
    position: string;
    pageType: Type;
}) => {
    const name = creative.campaignName || 'no name';
    const id = creative.trackingName || 'no id';

    return {
        id,
        name,
        creative: `${creative.placeholderId}_${trackingTypeByPage[pageType]}`,
        position,
    };
};

const getPromotionTrackingEventData = ({
    creative,
    id,
    name,
    position,
}: {
    creative: CampaignCreative;
    position: string;
    name: string;
    id: string;
}) => ({
    creativeName: CreativeName.ProductTile,
    creativeSlot: position,
    promotionName: name,
    promotionId: id,
    locationId: creative.url || '',
    promotionCountdown: creative.countdown ? 'true' : 'false',
    promotionPlaceholder: creative.placeholderId || '',
    promotionType: PromotionType.Dynamic,
});

const renderCountdownUnits = (int: IntlShape) => ({
    days: {
        single: int.formatMessage(messages.day),
        plural: int.formatMessage(messages.days),
    },
    hours: {
        single: int.formatMessage(messages.hour),
        plural: int.formatMessage(messages.hours),
    },
    minutes: {
        single: int.formatMessage(messages.minute),
        plural: int.formatMessage(messages.minutes),
    },
    seconds: {
        plural: int.formatMessage(messages.seconds),
    },
});

const ProductBanner = ({
    className,
    creative,
    index,
    isListView,
    listCreativeIndex,
    pageType,
    verticalPosition,
}: BannerProps): ReactElement | null => {
    const int = useIntl();
    const position = isListView
        ? `vertical${listCreativeIndex}_horizontal1`
        : `vertical${verticalPosition}_horizontal${index + 1}`;

    const promotionTrackingPayload = getPromotionTrackingPayload({ creative, position, pageType });

    const promotionTrackingEventData = getPromotionTrackingEventData({
        creative,
        position,
        name: promotionTrackingPayload.name,
        id: promotionTrackingPayload.id,
    });

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecPromotionImpression([promotionTrackingPayload], promotionTrackingEventData),
    );

    const isMobileCreative = creative.placeholderId === CreativePlaceholderId.ProductTileMobile;
    const isGifImage = isGifImageUrl(creative.image.url);
    const imageUrl = isGifImage
        ? creative.image.url
        : creative.imgixImage?.src || creative.image.url;
    const { body, iconColor, title } = creative?.asterisk || {};

    const isSelectionBlock = creative.placeholderId === CreativePlaceholderId.SelectionBlockDesktop;

    return (
        <div
            className={classNames(
                className,
                'justify-content-center',
                {
                    'd-none d-xs-flex d-md-none':
                        creative.placeholderId === CreativePlaceholderId.ProductTileTablet,
                    'd-flex d-xs-none': isMobileCreative,
                    'd-none d-md-flex': isSelectionBlock,
                },
                isListView && {
                    'd-none d-md-flex':
                        creative.placeholderId === CreativePlaceholderId.ProductListDesktop,
                    'd-none':
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopLarge ||
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopSmall,
                },
                !isListView && {
                    'd-none d-lg-flex':
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopLarge,
                    'd-none d-md-flex d-lg-none':
                        creative.placeholderId === CreativePlaceholderId.ProductTileDesktopSmall,
                },
            )}
            key={`banner-${creative.placeholderId}-${index}`}
            data-testid="campaignBanner"
        >
            <Banner
                imageUrl={imageUrl}
                alt={creative.image.alt}
                link={creative.url}
                text="Endet in"
                isWide={creative.placeholderId === CreativePlaceholderId.ProductListDesktop}
                msCountdown={
                    creative.countdown
                        ? new Date(creative.countdown.endDateUtc).getTime() - Date.now()
                        : undefined
                }
                countdownUnits={renderCountdownUnits(int)}
                textColor={creative.countdown?.color || ''}
                modalInfos={
                    body
                        ? {
                              headline: <Wysiwyg content={title || ''} />,
                              infoContent: <Wysiwyg content={body || ''} />,
                              infoIconColor: iconColor || '',
                              a11yCloseText: title || '',
                          }
                        : undefined
                }
                lazyLoad
                ref={setImpressionTrackedElement}
                onClick={() => {
                    trackEecPromotionClick(promotionTrackingPayload, promotionTrackingEventData);
                }}
                onInfoClick={() =>
                    trackAnalyticsEvent({
                        eventData: {
                            eventName: GA4EventName.ClickButton,
                            feature_category: GA4FeatureCategory.PromotionDynamic,
                            creative_name: isSelectionBlock ? 'Selection-block' : 'Product-tile',
                            click_element: 'Info icon',
                        },
                    })
                }
                fullWidth={isFullWidthBanner(creative.placeholderId)}
                skeletonClasses=""
                srcSet={isGifImage ? '' : creative.imgixImage?.srcset}
                sizes={isGifImage ? '' : creative.imgixImage?.sizes}
                width={isGifImage ? undefined : creative.imgixImage?.width}
                height={isGifImage ? undefined : creative.imgixImage?.height}
                hasAspectRatio={isMobileCreative}
            />
        </div>
    );
};

export { ProductBanner };
