import { useEffect, useRef } from 'react';
import type * as SearchInsights from 'search-insights';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type FragmentContext } from '../../shared/types/fragmentContext';

type InsightsClient = Promise<typeof SearchInsights>;

const useInsightsClient = (): React.MutableRefObject<InsightsClient | undefined> => {
    const insightsClient = useRef<InsightsClient>();
    const { algoliaConfig } = useFragmentContext<FragmentContext>();

    useEffect(() => {
        insightsClient.current = import('search-insights');
        const importSearchInsights = async (): Promise<void> => {
            const client = (await insightsClient.current)?.default;
            if (client && algoliaConfig?.userToken) {
                client('init', {
                    appId: algoliaConfig.appId,
                    apiKey: algoliaConfig.searchKey,
                });
                client('setUserToken', algoliaConfig.userToken);
            }
        };

        importSearchInsights();
    }, [algoliaConfig]);

    return insightsClient;
};

export { useInsightsClient };
