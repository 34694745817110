import axios from 'axios';

import { type MailContent } from '../../shared/types/contactForm';

async function submitContactForm(data: MailContent): Promise<void> {
    const path = '/api/contact';
    return axios.post(path, data);
}

export { submitContactForm };
