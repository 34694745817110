import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';

import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { CheckboxGroup } from '@jsmdg/yoshi';
import { type MultiSelectOption } from '@jsmdg/yoshi/dist/types/selectOption';
import { type Filter } from '../../../../../shared/types/search';
import { getActiveFilters } from '../../../../hooks/getActiveFilters';

type MultiSelectMobileProps = {
    readonly options: MultiSelectOption[];
    readonly onSubmit: (value: string[]) => void;
    readonly filter: Filter;
    readonly selectedOptions?: string[];
    readonly label: string;
    readonly attribute: string;
    readonly listType?: GA4FilterListType;
};

const MultiSelectMobile = ({
    attribute,
    filter,
    label,
    listType,
    onSubmit,
    options,
    selectedOptions,
}: MultiSelectMobileProps): JSX.Element => {
    const [selection, setSelection] = useState<string[]>(selectedOptions || []);
    const selectedOptionsRef = useRef(selectedOptions);

    const handleOnSelect = async (value: string): Promise<void> => {
        await window.yieldToMainThread();
        const isDeselectAction = selection.includes(value);
        const updatedSelection = isDeselectAction
            ? selection.filter((_value: string): boolean => _value !== value)
            : selection.concat([value]);

        setSelection(updatedSelection);
        onSubmit(updatedSelection);

        const selectedOption = options.find(option => option.value === value);
        const trackingLabel = `${isDeselectAction ? 'Deselect' : 'Set'}_${label}`;
        let activeFilters = getActiveFilters(filter, [attribute]);
        if (isDeselectAction && updatedSelection.length === 0) {
            activeFilters = activeFilters
                .split(', ')
                .filter(activeFilter => !activeFilter.includes(attribute))
                .join(', ');
        }

        trackFilterInteraction(
            trackingLabel,
            selectedOption?.label,
            {
                eventName: isDeselectAction ? GA4EventName.DeselectFilter : GA4EventName.SetFilter,
                filter_type: label,
                filter_value: selectedOption?.label,
                list_type: listType ?? GA4FilterListType.ListView,
            },
            activeFilters,
        );
    };

    useEffect(() => {
        if (selectedOptionsRef.current) {
            const parentDidUpdate = !isEqual(selectedOptions, selectedOptionsRef.current);
            const selectionMatch = isEqual(selectedOptions, selection);

            if (parentDidUpdate && !selectionMatch) {
                setSelection(selectedOptions || []);
                onSubmit(selectedOptions || []);
            }
        }

        selectedOptionsRef.current = selectedOptions;
    }, [onSubmit, selectedOptions, selectedOptionsRef, selection]);

    return (
        <div className="mt-1x mb-0-5x mr-1x">
            <CheckboxGroup
                className="px-0"
                options={options.map(option => ({
                    ...option,
                    isSelected: selection.includes(option.value),
                }))}
                onSelect={handleOnSelect}
            />
        </div>
    );
};

export { MultiSelectMobile };
