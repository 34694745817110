import axios from 'axios';

import { type GoogleGeocoderResponse } from '../../shared/types/searchResponse';

const getLanguageFromLocale = (locale: string): string => {
    return locale.split('-')[0];
};

export const performReverseGeocode = async ({
    locale,
    lat,
    long,
    useResultType = true,
}: {
    locale: string;
    lat: number;
    long: number;
    useResultType?: boolean;
}): Promise<GoogleGeocoderResponse> => {
    const result = await axios.get<GoogleGeocoderResponse>('/api/geocode/reverse', {
        params: {
            lat,
            long,
            language: getLanguageFromLocale(locale),
            useResultType,
        },
    });

    return result.data;
};
