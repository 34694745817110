import { type GA4FilterListType } from '@jsmdg/tracking';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type Filter } from '../../../../shared/types/search';
import { SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { getSortedOptions } from './getSortedOptions';
import { MultiSelectDesktop } from './MultiSelectDesktop/MultiSelectDesktop';
import { MultiSelectMobile } from './MultiSelectMobile/MultiSelectMobile';

type MultiSelectProps = {
    readonly facet: FacetFilter;
    readonly filter: Filter;
    readonly selectedOptions?: string[];
    readonly isMobileFilter?: boolean;
    readonly onFilterChange: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly listType?: GA4FilterListType;
};

const MultiSelect = ({
    facet,
    filter,
    isMobileFilter = false,
    listType,
    onFilterChange,
    selectedOptions,
}: MultiSelectProps): JSX.Element => {
    const options = getSortedOptions(facet, selectedOptions);

    const handleOnSubmit = (submittedOptions: string[], name: string): void => {
        onFilterChange(SearchReducerActionType.Filter, submittedOptions, name);
    };

    if (isMobileFilter) {
        return (
            <MultiSelectMobile
                options={options}
                onSubmit={values => handleOnSubmit(values, facet.attribute)}
                label={facet.name}
                selectedOptions={selectedOptions}
                key={facet.attribute}
                filter={filter}
                attribute={facet.attribute}
                listType={listType}
            />
        );
    }

    return (
        <MultiSelectDesktop
            key={facet.name}
            label={facet.name}
            options={options}
            onSubmit={values => handleOnSubmit(values, facet.attribute)}
            selectedOptions={selectedOptions}
            filter={filter}
            attribute={facet.attribute}
            listType={listType}
        />
    );
};

export { MultiSelect };
