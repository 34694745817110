import { GA4EventName, type GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';

async function trackLocationTypeInClicked(
    label: string,
    location: string,
    activeValues: string,
    listType?: GA4FilterListType,
): Promise<void> {
    await window.yieldToMainThread();
    trackFilterInteraction(
        'SetLocation',
        label,
        {
            eventName: GA4EventName.SetFilter,
            filter_type: 'Location via type-in',
            filter_value: location,
            last_location: location,
            ...(listType ? { list_type: listType } : {}),
        },
        activeValues,
    );
}

async function trackLocationTypeInError(
    label: string,
    message: string,
    listType?: GA4FilterListType,
): Promise<void> {
    await window.yieldToMainThread();
    trackFilterInteraction('Error', label, {
        eventName: GA4EventName.ShowNotification,
        notification_type: 'Error - Invalid location',
        notification_message: message,
        ...(listType ? { list_type: listType } : {}),
    });
}

async function trackResetLocationClicked(
    label: string,
    activeValues: string,
    listType?: GA4FilterListType,
): Promise<void> {
    await window.yieldToMainThread();
    trackFilterInteraction(
        'SetLocation',
        label,
        {
            eventName: GA4EventName.ResetFilter,
            filter_type: 'Location',
            ...(listType ? { list_type: listType } : {}),
        },
        activeValues,
    );
}

async function trackLocateMeClicked(
    label: string,
    location: string,
    activeValues: string,
    listType?: GA4FilterListType,
): Promise<void> {
    await window.yieldToMainThread();
    trackFilterInteraction(
        'SetLocation',
        label,
        {
            eventName: GA4EventName.SetFilter,
            filter_type: 'Location via locate-me',
            filter_value: location,
            last_location: location,
            ...(listType ? { list_type: listType } : {}),
        },
        activeValues,
    );
}

async function trackLocateMeError(
    label: string,
    notification: string,
    message: string,
    listType?: GA4FilterListType,
): Promise<void> {
    await window.yieldToMainThread();
    trackFilterInteraction('SetLocation', label, {
        eventName: GA4EventName.ShowNotification,
        notification_type: notification,
        notification_message: message,
        ...(listType ? { list_type: listType } : {}),
    });
}

export {
    trackLocateMeClicked,
    trackLocateMeError,
    trackLocationTypeInClicked,
    trackLocationTypeInError,
    trackResetLocationClicked,
};
