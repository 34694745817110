import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import {
    Breakpoint,
    Skeleton,
    SkeletonAnimation,
    SkeletonVariant,
    Slider,
    SlidingDirections,
} from '@jsmdg/yoshi';
import { type PageType } from '../../../shared/enums/pageType';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { TrackingCategory } from '../../enums/trackingCategory';
import { trackSlide } from '../../helper/trackSlide';
import { useHighestMatchingBreakpoint, useIsLoading } from '../../hooks';
import { Category } from './Category';
import styles from './Categories.module.scss';

const categoriesSliderConfig = {
    [Breakpoint.XL]: {
        spaceBetween: 24,
        slidesPerView: 6,
        slidesPerGroup: 6,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.LG]: {
        spaceBetween: 24,
        slidesPerView: 6,
        slidesPerGroup: 6,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.MD]: {
        spaceBetween: 24,
        slidesPerView: 4,
        slidesPerGroup: 4,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.SM]: {
        spaceBetween: 12,
        slidesPerView: 4,
        slidesPerGroup: 4,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.XS]: {
        spaceBetween: 12,
        slidesPerView: 0,
        slidesPerGroup: 0,
        showPagination: false,
        showNavigation: false,
    },
    [Breakpoint['2XS']]: {
        spaceBetween: 12,
        slidesPerView: 0,
        slidesPerGroup: 0,
        showPagination: false,
        showNavigation: false,
    },
    [Breakpoint['3XS']]: {
        spaceBetween: 12,
        slidesPerView: 0,
        slidesPerGroup: 0,
        showPagination: false,
        showNavigation: false,
    },
};

type CategoriesProps = {
    readonly pageType: PageType;
    readonly verticalPosition: number;
    readonly hasNavigationCreative: boolean;
    readonly withBlockMargin?: boolean;
};

const Categories = ({
    hasNavigationCreative,
    pageType,
    verticalPosition,
    withBlockMargin,
}: CategoriesProps): JSX.Element | null => {
    const {
        tenantConfig: { categoryMap },
    } = useFragmentContext<FragmentContext>();

    const highestMatchingBreakpoint = useHighestMatchingBreakpoint();
    const sliderConfig = categoriesSliderConfig[highestMatchingBreakpoint];
    const isLoading = useIsLoading();

    const handleOnSlide = (direction: SlidingDirections): void =>
        trackSlide({
            direction,
            category: TrackingCategory.InternalPromotion,
            eventData: {
                eventName: GA4EventName.SlidePromotionItem,
                feature_category: GA4FeatureCategory.PromotionStatic,
                promotion_type: 'Image_Slider',
                position_vertical: verticalPosition?.toString(),
                click_type: `Slide ${direction.toLowerCase()}`,
            },
        });

    if (!categoryMap?.length) {
        return null;
    }

    const slides = categoryMap.map(({ categoryId, label, url }, index) => {
        return {
            key: categoryId,
            content: (
                <Category
                    pageType={pageType}
                    categoryId={categoryId}
                    url={url}
                    label={label}
                    verticalPosition={verticalPosition}
                    hasNavigationCreative={hasNavigationCreative}
                    index={index}
                />
            ),
        };
    });

    return (
        <div
            className={classNames('position-relative w-100', styles.categories, {
                [styles.withBlockMargin]: withBlockMargin,
            })}
        >
            <Slider
                slides={slides}
                config={sliderConfig}
                handleShowNext={() => handleOnSlide(SlidingDirections.Next)}
                handleShowPrevious={() => handleOnSlide(SlidingDirections.Previous)}
            />

            {isLoading && (
                <div className={`d-flex position-absolute contain-content ${styles.skeleton}`}>
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                        className="ml-2x"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                        className="ml-2x"
                    />
                    <Skeleton
                        animation={SkeletonAnimation.Wave}
                        variant={SkeletonVariant.Rect}
                        width="25%"
                        height="100%"
                        className="ml-2x"
                    />
                </div>
            )}
        </div>
    );
};

export { Categories };
