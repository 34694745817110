export enum CreativeName {
    ImageSlider = 'Image_Slider',
    PageTile = 'Page_Tile',
    PageSlider = 'Page_Slider',
    ProductTile = 'Product_Tile',
    IconSlider = 'Icon_Slider',
    HeroBanner = 'HeroBanner',
}

export enum PromotionType {
    Dynamic = 'dynamic',
    Static = 'static',
    Trbo = 'trbo',
}
